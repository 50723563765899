<div [ngClass]="{'front':(mediaFrames[0].state==='visible'||mediaFrames[0].state==='fadein')&&mediaFrames[1].state!=='fadein', 'show':mediaFrames[0].state==='fadein', 'visible':mediaFrames[0].state==='visible', 'hidden':mediaFrames[0].state==='hidden'}"
     class="layout-frame presenter-frame fade f0">

  <div class="layout layout-{{mediaFrames[0]?.mediaLayout?.GridSize}}"
       [ngClass]="{'layout-pane3ontop':mediaFrames[0]?.mediaLayout?.Pane3OnTop}">

    <div [ngClass]="{'inactive':mediaFrames[0].devices.length<=1}" class="virtualdevices">

      <div *ngIf="mediaFrames[0].devices.length>=1" class="virtualdevice virtualdevice-0">
        <app-layout [DeviceID]="mediaFrames[0].devices[0].deviceID"
                    [Layer]="Layer+1"
                    [Frame]="0"
                    [Index]="0"
                    [Muted]="Muted"
                    [Settings]="Settings"
                    [Fullscreen]="Fullscreen"
                    (OnReady)="OnReadyChildHandler($event)"></app-layout>
      </div>
      <div *ngIf="mediaFrames[0].devices.length>=2" class="virtualdevice virtualdevice-1">
        <app-layout [DeviceID]="mediaFrames[0].devices[1].deviceID"
                    [Layer]="Layer+1"
                    [Frame]="0"
                    [Index]="1"
                    [Muted]="Muted"
                    [Settings]="Settings"
                    [Fullscreen]="Fullscreen"
                    (OnReady)="OnReadyChildHandler($event)"></app-layout>
      </div>
      <div *ngIf="mediaFrames[0].devices.length>=3" class="virtualdevice virtualdevice-2">
        <app-layout [DeviceID]="mediaFrames[0].devices[2].deviceID"
                    [Layer]="Layer+1"
                    [Frame]="0"
                    [Index]="2"
                    [Muted]="Muted"
                    [Settings]="Settings"
                    [Fullscreen]="Fullscreen"
                    (OnReady)="OnReadyChildHandler($event)"></app-layout>
      </div>
      <div *ngIf="mediaFrames[0].devices.length===4" class="virtualdevice virtualdevice-3">
        <app-layout [DeviceID]="mediaFrames[0].devices[3].deviceID"
                    [Layer]="Layer+1"
                    [Frame]="0"
                    [Index]="3"
                    [Muted]="Muted"
                    [Settings]="Settings"
                    [Fullscreen]="Fullscreen"
                    (OnReady)="OnReadyChildHandler($event)"></app-layout>
      </div>

    </div>
    <div class="virtualdevices">
      <div *ngIf="mediaFrames[0].devices.length===1" class="virtualdevice">
        <app-webpresenter [DeviceID]="mediaFrames[0]?.devices[0]?.deviceID"
                          [MediaActivity]="mediaFrames[0].mediaActivity"
                          [Muted]="Muted"
                          [Settings]="Settings"
                          [Fullscreen]="Fullscreen"></app-webpresenter>
      </div>
    </div>

  </div>
</div>

<div [ngClass]="{'front':(mediaFrames[1].state==='visible'||mediaFrames[1].state==='fadein')&&mediaFrames[0].state!=='fadein', 'show':mediaFrames[1].state==='fadein', 'visible':mediaFrames[1].state==='visible', 'hidden':mediaFrames[1].state==='hidden'}"
     class="layout-frame presenter-frame fade f1">

  <div class="layout layout-{{mediaFrames[1]?.mediaLayout?.GridSize}}"
       [ngClass]="{'layout-pane3ontop':mediaFrames[1]?.mediaLayout?.Pane3OnTop}">

    <div *ngIf="mediaFrames[1].devices.length>1" class="virtualdevices">

      <div *ngIf="mediaFrames[1].devices.length>=1" class="virtualdevice virtualdevice-0">
        <app-layout [DeviceID]="mediaFrames[1].devices[0].deviceID"
                    [Layer]="Layer+1"
                    [Frame]="1"
                    [Index]="0"
                    [Muted]="Muted"
                    [Settings]="Settings"
                    [Fullscreen]="Fullscreen"
                    (OnReady)="OnReadyChildHandler($event)"></app-layout>
      </div>
      <div *ngIf="mediaFrames[1].devices.length>=2" class="virtualdevice virtualdevice-1">
        <app-layout [DeviceID]="mediaFrames[1].devices[1].deviceID"
                    [Layer]="Layer+1"
                    [Frame]="1"
                    [Index]="1"
                    [Muted]="Muted"
                    [Settings]="Settings"
                    [Fullscreen]="Fullscreen"
                    (OnReady)="OnReadyChildHandler($event)"></app-layout>
      </div>
      <div *ngIf="mediaFrames[1].devices.length>=3" class="virtualdevice virtualdevice-2">
        <app-layout [DeviceID]="mediaFrames[1].devices[2].deviceID"
                    [Layer]="Layer+1"
                    [Frame]="1"
                    [Index]="2"
                    [Muted]="Muted"
                    [Settings]="Settings"
                    [Fullscreen]="Fullscreen"
                    (OnReady)="OnReadyChildHandler($event)"></app-layout>
      </div>
      <div *ngIf="mediaFrames[1].devices.length>=4" class="virtualdevice virtualdevice-3">
        <app-layout [DeviceID]="mediaFrames[1].devices[3].deviceID"
                    [Layer]="Layer+1"
                    [Frame]="1"
                    [Index]="3"
                    [Muted]="Muted"
                    [Settings]="Settings"
                    [Fullscreen]="Fullscreen"
                    (OnReady)="OnReadyChildHandler($event)"></app-layout>
      </div>

    </div>
    <div class="virtualdevices">
      <div *ngIf="mediaFrames[1].devices.length===1" class="virtualdevice">
        <app-webpresenter [DeviceID]="mediaFrames[1]?.devices[0]?.deviceID"
                          [MediaActivity]="mediaFrames[1].mediaActivity"
                          [Muted]="Muted"
                          [Settings]="Settings"
                          [Fullscreen]="Fullscreen"></app-webpresenter>
      </div>
    </div>
  </div>

</div>
