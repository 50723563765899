<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="mediaactivity.rule.header.edit"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button
            localize="title:common.save.title"
            (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.cancel.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container">


      <div class="b-form-container">
        <mat-checkbox [(ngModel)]="mediaActivityRuleInUse">
          <span localize="mediaactivity.rule.form.activerule.title"></span>
        </mat-checkbox>
      </div>


      <mat-form-field>
        <mat-select [placeholder]="textModule" [(ngModel)]="mediaActivityRule.moduleID">
          <mat-option *ngFor="let module of modules" [value]="module.moduleID">{{module.moduleTitle}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="b-form-container">
        <mat-checkbox [(ngModel)]="mediaActivityRule.wholeDay">
          <span localize="mediaactivity.rule.form.wholeday.title"></span>
        </mat-checkbox>
      </div>

      <div class="b-form-container">
        <mat-checkbox [(ngModel)]="mediaActivityRule.yearlyRecurrent">
          <span localize="mediaactivity.rule.form.yearlyrecurrent.title"></span>
        </mat-checkbox>
      </div>

      <mat-form-field>
        <input matInput [placeholder]="textRuleDuration" [(ngModel)]="mediaActivityRule.ruleDuration">
      </mat-form-field>
    </div>
</div>
