import { IMediaLayout } from '../interfaces/IMediaLayout';

export default class MediaLayout implements IMediaLayout
{
  name: string;
  mediaLayoutID: string;
  gridSize: number = 1;
  column1Width: number = 0;
  column2Width: number = 0;
  row1Height: number = 0;
  row2Height: number = 0;
  cellPadding: number = 0;
  pane3OnTop: boolean = false;
  transitionMode: string = 'Fade';
  transitionDuration: number = 2000;
  browserZoom: number = 1;
  mediaDeviceId01: string;
  mediaDeviceId02: string;
  mediaDeviceId03: string;
  mediaDeviceId04: string;

  mediaDeviceId01Name: string;
  mediaDeviceId02Name: string;
  mediaDeviceId03Name: string;
  mediaDeviceId04Name: string;
}
