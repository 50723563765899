import { Component, OnInit, Input, Inject, EventEmitter, Output } from '@angular/core';
import { WidgetsService } from '../core/services/data/widgets.service';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IWidgetDefinition } from '../core/interfaces/IWidgetDefinition';
import { evision5 } from '../core/globals';

@Component({
  selector: 'app-widgets-summary',
  templateUrl: './widgets-summary.component.html',
  styleUrls: ['./widgets-summary.component.css']
})
export class WidgetsSummaryComponent implements OnInit {

  public showApplyButton: boolean = false;

  @Input()
  public set ShowApplyButton(value: boolean) { this.showApplyButton = value; }

  private path: string = '';

  @Input()
  public set Path(value: string) { this.path = value; this.getWidgets(); }

  @Output()
  public onSelected = new EventEmitter();

  public widgets: IWidgetDefinition[] = [];

  public displayedFilesColumns: string[] = ['icon', 'name', 'edit'];

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private widgetsService: WidgetsService) { }

  ngOnInit(): void {

    this.getWidgets();
  }

  private getWidgets() {
    this.widgetsService.GetWidgets(this.path).subscribe((data: IReturnState) => {

      if (data.success) {
        this.widgets = data.data as IWidgetDefinition[];
      }

    });
  }

  public getIconUrl(_widgetDefinition: IWidgetDefinition) {
    var retval = evision5.buildApi(this.baseUrl, 'files/thumbnail?mediaurl=' + _widgetDefinition.path + '/' + _widgetDefinition.image);
    return retval;
  };

  public applyWidget(element: IWidgetDefinition) {
    this.onSelected.emit(element);
  }

}
