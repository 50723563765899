import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { HttpWorkerFactoryService, HttpWorker } from './../http/httpworkerfactory.service';
import { ICanBeBusy, IsBusy } from './../busyindicator/busyindicatorfactory.service';
import { GlobalSettingsService } from './../data/globalsettings.service';
import { ApiKeysService } from './../data/apikeys.service';
import { CrudService, CrudServiceFactory } from './../data/common/crud.service';
import { IGlobal } from '../../interfaces/IGlobal';
import { IGlobalSettings } from '../../interfaces/IGlobalSettings';
import { IHtmlText } from '../../interfaces/IHtmlText';
import { IReturnState } from '../../interfaces/IReturnState';
import { application } from '../../globals';
import { IArticle } from '../../interfaces/IArticle';

@Injectable({
  providedIn: 'root',
})

export class ArticlesService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();

  private globalSettings: IGlobalSettings = null;

  private http: HttpWorker;
  private crudService: CrudService<IArticle, number>;

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private globalSettingsService: GlobalSettingsService,
    private apiKeyService: ApiKeysService,
    private crudServiceFactory: CrudServiceFactory)
  {
    this.crudService = this.crudServiceFactory.Get<IArticle, number>('articles', 'article', application);
    this.http = this.httpWorkerFactory.GetWorker();

    this.IsBusy.PassThrough(this.crudService);
    this.IsBusy.PassThrough(this.http);
    this.IsBusy.PassThrough(this.globalSettingsService);
    this.IsBusy.PassThrough(this.apiKeyService);

    //this.globalSettingsService.GetSettings().subscribe(_state =>
    //{
    //  this.globalSettings = _state;
    //});
  };

  /**
   * Gets all articles.
   */
  public GetArticles(_moduleID: number): EventEmitter<IArticle[]>
  {
    let onDone = new EventEmitter<IArticle[]>();

    var url = application.buildApi(this.baseUrl, 'articles' + ((_moduleID > 0) ? '/module/' + _moduleID : ''));
    this.http.get<IReturnState>(url)
      .subscribe(result =>
      {
        var data = result.data as IArticle[];
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Gets an article.
  */
  public GetArticle(_articleID: number): EventEmitter<IArticle>
  {
    return this.crudService.Get(_articleID);
  };

  /**
   * Deletes an article.
   * @param _articleID
   */
  public DeleteArticle(_articleID: number): EventEmitter<IReturnState>
  {
    return this.crudService.Delete(_articleID);
  };

  /**
   * Saves an article.
   * @param _article
   */
  public SaveArticle(_article: IArticle): EventEmitter<IReturnState>
  {
    return this.crudService.Save(_article, _article.id > 0 ? _article.id : null);
  };
}
