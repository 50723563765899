import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { application, evision5 } from '../../globals';
import { Observable } from 'rxjs';
import { IGlobal } from '../../interfaces/IGlobal';
import { IMediaDeviceForWebPresenter } from '../../interfaces/IMediaDeviceForWebPresenter';
import { IMediaSettingsWebPresenterCollection } from '../../interfaces/IMediaSettingsWebPresenterCollection';
import { IMediaActivityContent } from '../../interfaces/IMediaActivityContent';
import { IState } from '../../interfaces/IState';
import { IReturnState } from '../../interfaces/IReturnState';

@Injectable({
  providedIn: 'root',
})
export class GetMediaService
{
  private application: IGlobal = application;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private router: Router,
    private http: HttpClient) { }

  /**
   *  Returns a new worker to get mediaActivity.
   **/
  public Get(): GetMediaWorker
  {
    var worker = new GetMediaWorker(this.http, this.baseUrl);
    return worker;
  }
};

export class GetMediaWorker
{
  private readonly EMPTY_GUID: string = '00000000-0000-0000-0000-000000000000';

  private apiKey: string = '';

  private deviceID: string;
  private fullscreen: boolean;

  private device: IMediaDeviceForWebPresenter = null;
  private settings: IMediaSettingsWebPresenterCollection = null;
  private mediaActivity: IMediaActivityContent = null;

  constructor(private http: HttpClient,
    private baseUrl: string)
  {
  }

  /**
   * Performs login by using pwp-api-key.
   */
  public Login(_pwpApiKey: string)
  {
    var o = new Observable<IState>(_observer =>
    {
      var data = { apikey: _pwpApiKey };//'D01C4118-ADB0-4AD1-B07A-171F8FE22EA5' };

      this.http
        .post<IState>(application.buildApi(this.baseUrl, 'security/login/apikey'), data)
        .subscribe((result: IState) =>
        {
          _observer.next(result);
          _observer.complete();

        }, error => console.error(error));
    });
    return o;
  };

  /**
   * Performs login by using pwp-api-key.
   */
  public Activate(_activationCode: string)
  {
    var o = new Observable<IReturnState>(_observer =>
    {
      this.http
        .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevice/activate/' + _activationCode), null)
        .subscribe((result: IReturnState) =>
        {
          _observer.next(result);
          _observer.complete();

        }, error => console.error(error));
    });
    return o;
  };

  /**
   * Sets the deviceID.
   * @param _deviceID
   */
  public SetDevice(_deviceID: string): GetMediaWorker
  {
    this.deviceID = _deviceID;
    return this;
  };

  /**
   * Sets fullscreen.
   * @param _fullscreen
   */
  public SetFullscreen(_fullscreen: boolean): GetMediaWorker
  {
    this.fullscreen = _fullscreen;
    return this;
  };

  /**
   * Gets settings.
   */
  public GetSettings(): Observable<IMediaSettingsWebPresenterCollection>
  {
    var o = new Observable<IMediaSettingsWebPresenterCollection>(_observer =>
    {
      var header = {
        headers: new HttpHeaders({
          'accept': 'application/json'
        })
      };

      this.http
        .get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediasettings/webpresenter'), header)
        .subscribe(result =>
        {
          this.settings = result.data as IMediaSettingsWebPresenterCollection;

          _observer.next(this.settings);
          _observer.complete();

        }, error => console.error(error));
    });
    return o;
  };

  /**
   * Gets the device-object.
   */
  public GetDevice(): Observable<IMediaDeviceForWebPresenter>
  {
    var o = new Observable<IMediaDeviceForWebPresenter>(_observer =>
    {
      var url = this.deviceID != null
        ? 'mediadevice/id/' + this.deviceID
        : 'mediadevice/current';

      var header = {
        headers: new HttpHeaders({
          'accept': 'application/json'
        })
      };

      this.http
        .get<IReturnState>(evision5.buildApi(this.baseUrl, url), header)
        .subscribe(result =>
        {
          this.device = result.data as IMediaDeviceForWebPresenter;

          _observer.next(this.device);
          _observer.complete();

        }, error => console.error(error));

    });
    return o;
  };

  /**
   * Gets whether the device is muted.
   */
  public IsMuted(): boolean
  {
    if (this.device !== null && this.settings !== null)
    {
      var dvc = this.device;
      var set = this.settings;

      var m = dvc.silentVideoPlayback === -1
        ? set.silentVideoPlaybackValue
        : dvc.silentVideoPlayback === 1
          ? true
          : false;
      return m;
    }

    return true;
  };

  /**
   * Gets mediaActivity.
   */
  public GetMedia(): Observable<IMediaActivityContent>
  {
    var o = new Observable<IMediaActivityContent>(_observer =>
    {
      var header = {
        headers: new HttpHeaders({
          'fullscreen': this.fullscreen.toString(),
          'accept': 'application/json',
          'x-version': '2'
        })
      };

      var url = this.deviceID != null
        ? 'mediaactivity/content/mediadevice/' + this.deviceID
        : 'mediaactivity/content';

      this.http
        .get<IMediaActivityContent>(evision5.buildApi(this.baseUrl, url), header)
        .subscribe(result =>
        {
          this.mediaActivity = result;
          //this.mediaActivity.value = this.mediaActivity.value; //.replace('http://localhost/evision5media/', this.baseUrl + 'media/')

          _observer.next(this.mediaActivity);
          _observer.complete();

        }, error => console.error(error));

    });
    return o;
  };

  /**
   * Refreshs mediaActivity.
   */
  public RefreshMedia(): Observable<boolean>
  {
    var o = new Observable<boolean>(_observer =>
    {
      var deviceID = this.deviceID != null
        ? this.deviceID
        : '00000000-0000-0000-0000-000000000000';

      this.http
        .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevice/' + deviceID + '/reloadmedia'), null)
        .subscribe(result =>
        {
          _observer.next(true);
          _observer.complete();

        }, error => console.error(error));

    });
    return o;
  };
};
