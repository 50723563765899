<div class="component component-container"
     fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="pwp.users.summary.header"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button
            localize="title:pwp.users.summary.toolbar.new.title"
            (click)="editUser(null)">
      <icon icon="Plus"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <table mat-table [dataSource]="usersDataSource">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef localize="pwp.users.summary.grid.col.name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef localize="pwp.users.summary.grid.col.email.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons two-buttons">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button (click)="deleteUser(element)" localize="title:pwp.users.summary.grid.col.edit.delete.title">
                <icon icon="Trash"></icon>
              </button>
              <button mat-button (click)="editUser(element)" localize="title:pwp.users.summary.grid.col.edit.edit.title">
                <icon icon="Pen"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected':userID===row.userID}"></tr>

    </table>
  </div>

</div>
