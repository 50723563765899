<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="pwp.slack.handler.pleasewait.header"></h1>
  <h1 *ngIf="inProcess===false&&success===false" localize="pwp.slack.handler.error.header"></h1>
  <h1 *ngIf="inProcess===false&&success===true" localize="pwp.slack.handler.done.header"></h1>

  <div class="component-content-container">

    <p *ngIf="inProcess===false&&success===false"><span localize="pwp.slack.handler.error.title"></span>&nbsp;{{errorMsg}}</p>

    <p></p>
    <h2 *ngIf="inProcess===false" localize="$:pwp.slack.handler.canclose.title"></h2>

  </div>
</div>
