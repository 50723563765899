<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="exceptionID" localize="mediadevicestartupexception.single.header.edit"></h1>
  <h1 *ngIf="!exceptionID" localize="mediadevicestartupexception.single.header.new"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.back.msg" (click)="triggerOnClose()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!exception"><em localize="common.loadingdata.msg"></em></p>

    <div fxFlex="500px" class="b-form-container wrapper">

      <mat-form-field>
        <input matInput [placeholder]="textName" [(ngModel)]="exception.caption">
      </mat-form-field>

      <mat-form-field>
        <input matInput [matDatepicker]="startDatePicker" [placeholder]="textStartDate" [(ngModel)]="exception.startDate">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <input matInput [matDatepicker]="endDatePicker" [placeholder]="textEndDate" [(ngModel)]="exception.endDate">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>

    </div>

  </div>

</div>
