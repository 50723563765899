import { IResetPassword } from '../interfaces/IResetPassword';

export default class ResetPassword implements IResetPassword
{
  userName: string = '';
  oldPassword: string = '';
  newPassword: string = '';
  retypedPassword: string = '';
  code: string = '';
}
