import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { HttpWorkerFactoryService, HttpWorker } from './../http/httpworkerfactory.service';
import { ICanBeBusy, IsBusy } from './../busyindicator/busyindicatorfactory.service';
import { GlobalSettingsService } from './../data/globalsettings.service';
import { IGlobal } from '../../interfaces/IGlobal';
import { IRole } from '../../interfaces/IRole';
import { IGlobalSettings } from '../../interfaces/IGlobalSettings';
import { IReturnState } from '../../interfaces/IReturnState';
import { IRoleSelectable } from '../../interfaces/IRoleSelectable';
import { application } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class RolesService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();

  private http: HttpWorker;

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private globalSettingsService: GlobalSettingsService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
    this.IsBusy.PassThrough(this.globalSettingsService);
  }

  /**
   * Gets the roles for selection.
   */
  public GetRolesForSelection(): EventEmitter<IRole[]>
  {
    let onDone = new EventEmitter<IRole[]>();

    if (!application.isAdmin)
    {
      onDone.emit([]);
      return onDone;
    }

    var roles: IRole[] = null;
    var settings: IGlobalSettings = null;

    // Get roles
    this.GetRoles().subscribe((_roles: IRole[]) =>
    {
      roles = _roles;
      process();
    });

    // Get settings
    this.globalSettingsService.GetSettings().subscribe((_settings: IGlobalSettings) =>
    {
      settings = _settings;
      process();
    });

    // Filter roles
    var process = function ()
    {
      if (roles === null || settings === null)
      {
        return;
      }

      var tmp: IRole[] = [];
      for (var i = 0; i < roles.length; i++)
      {
        if (roles[i].roleName.toLowerCase() !== settings.adminRole.toLowerCase())
        {
          tmp.push(roles[i]);
        }
      }

      onDone.emit(tmp);
    };

    return onDone;
  }

  /**
   * Gets all available roles of a user.
   */
  public GetRolesOfUser(_userID: number): EventEmitter<IRole[]>
  {
    let onDone = new EventEmitter<IRole[]>();

    this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'roles/user/' + _userID))
      .subscribe(result =>
      {
        var data = result.data as IRole[];
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Gets all available roles.
   */
  public GetRoles(): EventEmitter<IRole[]>
  {
    let onDone = new EventEmitter<IRole[]>();

    this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'roles'))
      .subscribe(result =>
      {
        var data = result.data as IRole[];
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Gets a role.
   * @param _roleID
   */
  public GetRole(_roleID: number): EventEmitter<IRole>
  {
    let onDone = new EventEmitter<IRole>();

    this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'role/' + _roleID.toString()))
      .subscribe(result =>
      {
        var data = result.data as IRole;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Saves a role.
   * @param _roleID
   */
  public SaveRole(_role: IRole): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var url = _role.roleID > 0
      ? application.buildApi(this.baseUrl, 'role/' + _role.roleID)
      : application.buildApi(this.baseUrl, 'role');

    this.http.post<IReturnState>(url, _role)
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
 * Deletes a role.
 * @param _roleID
 */
  public DeleteRole(_roleID: number): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    this.http.delete<IReturnState>(application.buildApi(this.baseUrl, 'role/' + _roleID.toString()), {})
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Updates the roles of a user.
   * @param _roles
   */
  public UpdateRoles(_userID: number, _roles: IRoleSelectable[]): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    this.http.post<IReturnState>(application.buildApi(this.baseUrl, 'roles/user/' + _userID), _roles)
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

}
