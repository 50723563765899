import { IHtmlTextExtended } from '../interfaces/IHtmlTextExtended';

export default class HtmlTextExtended implements IHtmlTextExtended
{
  moduleID: number;
  desktopHtml: string;
  mobileSummary: string;
  mobileDetails: string;
  pic: string;
  align: string;
  changedTime: string;
  deleted: boolean;
  moduleDisplayName: string;
}
