import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { SecurityService } from './../core/services/common/security.service';
import { IGlobal } from './../core/interfaces/IGlobal';
import { application } from '../core/globals';

/*
 * HOW TO USE:
 *  $:mein.text,title:mein.title.text
 *  mein.text,title:mein.title.text
 * 
 **/
@Directive({
  selector: '[localize]'
})
export class LocalizeDirective
{
  constructor(private el: ElementRef,
    private security: SecurityService)
  {
  }

  // ----

  _localize: string;

  get localize(): string { return this._localize; }

  @Input('localize')
  set localize(_value: string)
  {
    var details = _value;
    if (details)
    {
      var splitted = details.split(',');
      for (var i = 0; i < splitted.length; i++)
      {
        var assignment = splitted[i].split(':');
        if (assignment.length === 2)
        {
          if (assignment[0] === '$')
          {
            this.el.nativeElement.innerHTML = application.getRawText(assignment[1]);
          }
          else
          {
            this.el.nativeElement.setAttribute(assignment[0], application.getRawText(assignment[1]));
          }
        }
        else if (assignment.length === 1)
        {
          this.el.nativeElement.innerHTML = application.getRawText(assignment[0]);
        }
      }
    }
  }
}

@NgModule({
  declarations: [
    LocalizeDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LocalizeDirective
  ]
})
export class LocalizeModule { }
