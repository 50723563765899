import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ICanBeBusy, IsBusy } from '../../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { IMediaSettingsWebPresenterCollection } from '../../interfaces/IMediaSettingsWebPresenterCollection';
import { IReturnState } from '../../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IMediaSettingsCollection } from '../../interfaces/IMediaSettingsCollection';
import { ISettings } from '../../interfaces/ISettings';
import { evision5 } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class SettingsService implements ICanBeBusy
{
  private http: HttpWorker;

  public IsBusy: IsBusy = new IsBusy();

  constructor(private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private httpWorkerFactory: HttpWorkerFactoryService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
  }

  public GetWebPresenterSettings(): EventEmitter<IMediaSettingsWebPresenterCollection>
  {
    let onDone = new EventEmitter<IMediaSettingsWebPresenterCollection>();

    this.http
      .get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediasettings/webpresenter'))
      .subscribe(result =>
      {
        var settings = result.data as IMediaSettingsWebPresenterCollection;
        onDone.emit(settings);
      }, error => console.error(error));

    return onDone;
  };

  public GetSettings(): EventEmitter<IMediaSettingsCollection>
  {
    let onDone = new EventEmitter<IMediaSettingsCollection>();

    this.http
      .get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediasettings'))
      .subscribe(result =>
      {
        var settings = result.data as IMediaSettingsCollection;
        onDone.emit(settings);
      }, error => console.error(error));

    return onDone;
  };

  public SaveSettings(_settings: ISettings): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediasettings'), _settings)
      .subscribe(
        _result =>
        {
          onDone.emit(_result);
        },
        error => console.error(error));

    return onDone;
  };

}
