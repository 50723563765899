import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { application } from './../core/globals';
import { ActivatedRoute, Router } from '@angular/router';

import { UiKeyHandleService, KeyHandler } from './../core/services/common/uikeyhandle.service';
import { SecurityService } from './../core/services/common/security.service';
import { HttpWorkerFactoryService, HttpWorker } from './../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { RolesService } from './../core/services/data/roles.service';
import Role from './../core/models/Role';
import { IRole } from '../core/interfaces/IRole';

@Component({
  selector: 'app-role-single',
  templateUrl: './role-single.component.html',
  styleUrls: ['./role-single.component.css']
})

export class RoleSingleComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public roleID: number = null;
  public role: IRole = new Role();

  private initiated: boolean = false;

  public textName: string = application.getRawText('pwp.role.single.form.name.title');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  public keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private roleService: RolesService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
  };

  private ngOnInit() {
    if (this.initiated) {
      return;
    }

    var tmpID = parseInt(this.route.snapshot.paramMap.get('id'));
    this.roleID = tmpID > 0 ? tmpID : null;
    this.initRole();
  };

  public initRole() {
    if (this.initiated) {
      return;
    }

    if (!(this.roleID > 0)) {
      this.roleID = null;
      this.role = new Role();
    }
    else {
      this.loadRole();
    }

    this.initiated = true;
  };

  private loadRole() {
    this.roleService.GetRole(this.roleID).subscribe(_role => {
      this.role = _role as IRole;
    });
  };

  public save() {
    this.roleService.SaveRole(this.role).subscribe(_returnState => {
      if (_returnState.success) {
        this.role = _returnState.data as IRole;
        this.roleID = this.role.roleID;

        this.close();
      }
      else {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  public close() {
    this.onClose.emit();
  };

}
