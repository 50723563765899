<div class="component component-container"
     fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="medialayouts.summary.header"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:medialayouts.summary.toolbar.new.title" (click)="edit(null)">
      <icon icon="Plus"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!medialayouts"><em localize="common.loadingdata.msg"></em></p>

    <table mat-table [dataSource]="medialayouts">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef localize="medialayouts.summary.grid.jobname.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="gridSize">
        <th mat-header-cell *matHeaderCellDef localize="medialayouts.summary.grid.gridsize.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.gridSize }}</td>
      </ng-container>

      <ng-container matColumnDef="pane3OnTop">
        <th mat-header-cell *matHeaderCellDef localize="medialayouts.summary.grid.pane3ontop.title"></th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox [(ngModel)]="element.pane3OnTop" disabled="disabled"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="transitionMode">
        <th mat-header-cell *matHeaderCellDef localize="medialayouts.summary.grid.transitionmode.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.transitionMode }}</td>
      </ng-container>

      <ng-container matColumnDef="mediaDeviceId01Name">
        <th mat-header-cell *matHeaderCellDef localize="medialayouts.summary.grid.mediadeviceid01name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.mediaDeviceId01Name }}</td>
      </ng-container>

      <ng-container matColumnDef="mediaDeviceId02Name">
        <th mat-header-cell *matHeaderCellDef localize="medialayouts.summary.grid.mediadeviceid02name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.mediaDeviceId02Name }}</td>
      </ng-container>

      <ng-container matColumnDef="mediaDeviceId03Name">
        <th mat-header-cell *matHeaderCellDef localize="medialayouts.summary.grid.mediadeviceid03name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.mediaDeviceId03Name }}</td>
      </ng-container>

      <ng-container matColumnDef="mediaDeviceId04Name">
        <th mat-header-cell *matHeaderCellDef localize="medialayouts.summary.grid.mediadeviceid04name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.mediaDeviceId04Name }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons three-buttons">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button (click)="edit(element.mediaLayoutID)" localize="title:medialayouts.summary.grid.edit.edit.title">
                <icon icon="Pen"></icon>
              </button>
              <button mat-button (click)="delete(element.mediaLayoutID)" localize="title:medialayouts.summary.grid.edit.delete.title">
                <icon icon="Trash"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected':mediaLayoutID===row.mediaLayoutID}"></tr>

    </table>
  </div>

</div>
