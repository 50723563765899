import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FileExplorerComponent } from '../../fileexplorer/fileexplorer.component';
import File from '../../core/models/File';
import { IMediaSettingsCollection } from '../../core/interfaces/IMediaSettingsCollection';
import MediaSettingsCollection from '../../core/models/MediaSettingsCollection';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../../app-pwp/src/app/dialog/dialog.component';
import { KeyHandler, UiKeyHandleService } from '../../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../../app-pwp/src/app/core/services/common/security.service';
import { SettingsService } from '../../core/services/data/settings.service';
import { FileExplorerService } from '../../core/services/data/fileexplorer.service';
import { IReturnState } from '../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { application } from '../../../../../app-pwp/src/app/core/globals';
import { evision5 } from '../../core/globals';

@Component({
  selector: 'app-fileeditor',
  templateUrl: './fileeditor.component.html',
  styleUrls: ['./fileeditor.component.css']
})

export class FileEditorComponent {
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public content: string;

  private contentChanged: string;

  public element: File;
  public path: string;
  public name: string;
  public extension: string;

  public options = {
    lineNumbers: true,
    theme: 'material',
    mode: 'javascript'
  };

  public onClose = new EventEmitter();

  private settings: IMediaSettingsCollection = new MediaSettingsCollection();

  private initiated: boolean = false;

  private dialogGeneric: MatDialogRef<DialogComponent>;

  public keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private fileExplorerService: FileExplorerService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService) {
    //this.security.checkForRolesByName('devices.summary');
    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.EnableCancel(false);
    this.keyHandler.OnSave.subscribe(() => this.save());

    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.fileExplorerService);
    this.busyIndicator.Register(this.settingsService);
  };

  ngOnInit() {
    if (this.initiated) {
      this.settingsService.GetSettings().subscribe((_settings: IMediaSettingsCollection) => {
        this.settings = _settings;
      });

      return;
    }

    this.init('/', null);
  };

  public init(_path: string, _element: File) {
    this.path = _path;
    this.element = _element;
    this.initiated = true;

    if (this.extension === '.css') {
      this.options.mode = 'css';
    }
    else if (this.extension === '.js') {
      this.options.mode = 'javascript';
    }
    else if (this.extension === '.html') {
      this.options.mode = 'htmlmixed';
    }

    this.getContent();
  };

  public isImage(_file: File) {
    return this.settings.imageFormatsValue.indexOf(_file.extension) > -1;
  }

  public isVideo(_file: File) {
    return this.settings.videoFormatsValue.indexOf(_file.extension) > -1;
  }

  public showInIframe(_file: File) {
    return _file.extension == ".pdf" || _file.extension == ".html";
  }

  public getContent() {
    this.fileExplorerService.GetFileContent(this.element.path).subscribe((_result: IReturnState) => {
      this.content = _result.data as unknown as string;
    });
  };

  public onCodeChangedHandler(_event) {
    this.contentChanged = _event;
  };

  public save() {
    this.fileExplorerService.UpdateFileContent(this.element.path, this.contentChanged)
      .subscribe((_result: IReturnState) => {
        if (_result.success) {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
          this.close();
        }
        else {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
        }
      });
  };

  public close() {
    this.onClose.emit();
  };
}
