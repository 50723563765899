import { Component, Inject, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { KeyHandler, UiKeyHandleService } from '../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { DateTimeService } from '../../../../app-pwp/src/app/core/services/common/datetime.service';
import { FileSystemFileEntry, FileSystemDirectoryEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { GlobalSettingsService } from '../../../../app-pwp/src/app/core/services/data/globalsettings.service';
import { IGlobalSettings } from '../../../../app-pwp/src/app/core/interfaces/IGlobalSettings';
import { ChunkedUploadService } from '../../../../app-pwp/src/app/core/services/data/chunkedupload.service';
import { IImageItem } from '../../../../app-pwp/src/app/core/interfaces/IImageItem';
import { QueryString, UriBuilder } from '../../../../app-pwp/src/app/core/services/common/uribuilder.service';

@Component({
  selector: 'app-imageeditor',
  templateUrl: './imageeditor.component.html',
  styleUrls: ['./imageeditor.component.css']
})

export class ImageEditorComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  @ViewChild('imageEditor', { read: ElementRef })
  public imageElement: ElementRef;

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  private initiated: boolean = false;

  public settings: IGlobalSettings = null;

  public textDragnDrop: string = application.getRawText('mediaactivity.single.toolbar.dropfiles.lbl');
  public textWidth: string = application.getRawText('pwp.imageeditor.form.width.title');
  public textHeight: string = application.getRawText('pwp.imageeditor.form.height.title');
  public textTitle: string = application.getRawText('pwp.imageeditor.form.title.title');
  public textAlternateText: string = application.getRawText('pwp.imageeditor.form.alternatetext.title');
  public textPicPosLeft: string = application.getRawText('pwp.imageeditor.form.posleft.title');
  public textPicPosLine: string = application.getRawText('pwp.imageeditor.form.posline.title');
  public textPicPosRight: string = application.getRawText('pwp.imageeditor.form.posright.title');
  public textPicMarginTop: string = application.getRawText('pwp.imageeditor.form.margintop.title');
  public textPicMarginLeft: string = application.getRawText('pwp.imageeditor.form.marginleft.title');
  public textPicMarginRight: string = application.getRawText('pwp.imageeditor.form.marginright.title');
  public textPicMarginBottom: string = application.getRawText('pwp.imageeditor.form.marginbottom.title');

  public statusDragnDrop: string = this.textDragnDrop;
  public uploadRunning: boolean = false;

  // Selected file ... also for preview required.
  public selectedFile: NgxFileDropEntry = null;
  public selectedFileData: object = null;

  public imageSettings = {
    width: 0,
    height: 0,
    title: '',
    alternateText: '',
    picPosition: '',
    saveInLib: true,
    marginTop: '',
    marginLeft: '',
    marginRight: '',
    marginBottom: ''
  };

  //dialogGeneric: MatDialogRef<DialogComponent>;

  public keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private uiKeyHandleService: UiKeyHandleService,
    private uploadService: ChunkedUploadService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private dateTimeService: DateTimeService,
    private globalSettingsService: GlobalSettingsService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
    this.busyIndicator.Register(this.globalSettingsService);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());

    this.globalSettingsService.GetSettings().subscribe((_data: IGlobalSettings) => {
      this.settings = _data;
    });
  };

  private ngOnInit() {
    if (this.initiated) {
      return;
    }
  };

  /**
   * Saves the current selection.
   */
  public save() {
    if (this.selectedFile === null) {
      this.snackBarRef = this.snackBar.open(
        application.getRawText('pwp.imageeditor.apply.nofileselected.msg'),
        application.getRawText('common.close.msg'));
      return;
    }

    this.doUpload(this.selectedFile).subscribe((_result: IReturnState) => {
      if (_result.success === false) {
        return;
      }

      // Create HTML.
      this.insertContent(_result);

      // Done
      this.close();
    });
  };

  /**
   * Creates the content to insert to the editor.
   * @param _result
   */
  private insertContent(_result: IReturnState) {
    var imgElement = document.createElement('img');
    var orgWidth = this.imageElement.nativeElement.clientWidth;
    var orgHeight = this.imageElement.nativeElement.clientHeight;

    imgElement.src = new UriBuilder()
      .Parse(this.baseUrl)
      .AddSegment((_result.data as IImageItem).virtualPath)
      .Build();

    if (this.imageSettings.height !== null && this.imageSettings.height > 0) {
      imgElement.height = this.imageSettings.height;
    }

    if (this.imageSettings.width !== null && this.imageSettings.width > 0) {
      imgElement.width = this.imageSettings.width;
    }

    if (orgHeight > orgWidth) {
      // Setting to NULL does not work (imgElement.width = null;)
      imgElement.removeAttribute('width');
    }

    if (orgWidth > orgHeight) {
      // Setting to NULL does not work (imgElement.height = null;)
      imgElement.removeAttribute('height');
    }

    if (this.imageSettings.title !== null && this.imageSettings.title.length > 0) {
      imgElement.title = this.imageSettings.title;
    }

    if (this.imageSettings.alternateText !== null && this.imageSettings.alternateText.length > 0) {
      imgElement.alt = this.imageSettings.alternateText;
    }

    if (this.imageSettings.picPosition === 'right' || this.imageSettings.picPosition === 'left') {
      imgElement.classList.add('image_align_' + this.imageSettings.picPosition);
    }

    imgElement.style.marginBottom = this.determineMargin(this.imageSettings.marginBottom);
    imgElement.style.marginLeft = this.determineMargin(this.imageSettings.marginLeft);
    imgElement.style.marginRight = this.determineMargin(this.imageSettings.marginRight);
    imgElement.style.marginTop = this.determineMargin(this.imageSettings.marginTop);

    window.parent.postMessage({
      mceAction: 'insertContent',
      content: imgElement.outerHTML
    }, '*');
  };

  private determineMargin(_value: string): string {
    if (_value !== null && _value.length > 0) {
      if (_value.indexOf('px') < 0 && _value.indexOf('%') < 0) {
        _value = _value + 'px';
      }
    }
    else {
      _value = null;
    }

    return _value;
  };

  public close() {
    //this.onClose.emit(this.eventItem.itemID);
    window.parent.postMessage({
      mceAction: 'close',
    }, '*');
  };

  /**
   * User has dropped file. Show preview. Store it for later upload.
   * @param _event
   */
   public dropped(_event: NgxFileDropEntry[]) {
    if (this.uploadRunning === true || _event.length <= 0) {
      return;
    }

    this.selectedFile = _event[0];
    var tmp = this.selectedFile.fileEntry as FileSystemFileEntry;
    tmp.file(_file => {
      var reader = new FileReader();
      reader.onload = function (_e) {
        this.selectedFileData = _e.target.result;
      }.bind(this);
      reader.readAsDataURL(_file);
    });
  };

  /**
   * Performs the upload of a file.
   * @param _file
   */
  private doUpload(_file: NgxFileDropEntry): EventEmitter<IReturnState> {
    var onDone = new EventEmitter();

    var droppedFile = _file;

    // Is it a file?
    if (!droppedFile.fileEntry.isFile) {
      return;
    }

    // Check if the extension is allowed.
    var fileFormatIsValid = this.uploadService.HasValidExtension(droppedFile.fileEntry.name, this.settings.imageFormatsValue);
    if (fileFormatIsValid === false) {
      this.snackBarRef = this.snackBar.open(application.getRawText('pwp.imageeditor.upload.wrongext.title'), application.getRawText('common.close.msg'));
      return;
    }

    // Upload file
    var params = [
      new QueryString('width', this.imageSettings.width),
      new QueryString('height', this.imageSettings.height)
    ];

    this.uploadRunning = true;
    var file = this.uploadService.UploadFile(droppedFile.fileEntry as FileSystemFileEntry, '/', 'htmltext/upload', application, params);
    file.OnStatus.subscribe((_result: number) => {
      this.statusDragnDrop = (Math.round(_result)).toString() + ' %';
    });
    file.OnDone.subscribe((_result: IReturnState) => {
      this.uploadRunning = false;
      this.statusDragnDrop = this.textDragnDrop;

      if (_result.success) {
        this.snackBarRef = this.snackBar.open(application.getRawText('pwp.imageeditor.upload.success.msg'), application.getRawText('common.close.msg'));
        onDone.emit(_result);
      }
      else if (_result.stateID == 96) {
        this.snackBarRef = this.snackBar.open(application.getRawText('pwp.imageeditor.upload.wrongext.title'), application.getRawText('common.close.msg'));
      }
      //else if (_result.stateID == 97)
      //{
      //  this.snackBarRef = this.snackBar.open(application.getRawText('mediaactivity.single.upload.fileexists.msg'), application.getRawText('common.close.msg'));
      //}
      else if (_result.stateID == 98) {
        this.snackBarRef = this.snackBar.open(application.getRawText('pwp.imageeditor.upload.error.msg'), application.getRawText('common.close.msg'));
      }
      else if (_result.stateID == 99) {
        this.snackBarRef = this.snackBar.open(application.getRawText('pwp.imageeditor.upload.nowriteaccess.msg'), application.getRawText('common.close.msg'));
      }
      else {
        this.snackBarRef = this.snackBar.open(application.getRawText('pwp.imageeditor.upload.error.msg'), application.getRawText('common.close.msg'));
      }
    });

    return onDone;
  };

}
