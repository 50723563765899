import { Injectable, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { IGlobalEvision5 } from '../../interfaces/IGlobalEvision5';
import { ICanBeBusy, IsBusy } from '../../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { HttpWorkerFactoryService, HttpWorker } from '../../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { IReturnState } from '../../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { evision5 } from '../../globals';
import { HttpHeaders } from '@angular/common/http';
import { ICropModel } from '../../interfaces/ICropModel';

@Injectable({
  providedIn: 'root',
})

export class FileExplorerService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();
  private http: HttpWorker;

  private evision5: IGlobalEvision5 = evision5;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private router: Router,
    private httpWorkerFactory: HttpWorkerFactoryService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
  }

  public InUse(_path: string): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    this.http.get<IReturnState>(this.evision5.buildApi(this.baseUrl, 'files/inuse?path=' + encodeURIComponent(_path)), {})
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };

  public CreateDirectory(_path: string, _newDirectoryName: string): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    var data = {
      name: _newDirectoryName
    };
    this.http.post<IReturnState>(this.evision5.buildApi(this.baseUrl, 'directories?path=' + encodeURIComponent(_path)), data)
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };

  public DeleteDirectory(_path: string): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    this.http.delete<IReturnState>(this.evision5.buildApi(this.baseUrl, 'directories?path=' + encodeURIComponent(_path)))
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };

  public DeleteFile(_path: string): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    this.http.delete<IReturnState>(this.evision5.buildApi(this.baseUrl, 'files?path=' + encodeURIComponent(_path)))
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };

  /**
   * Gets the content of a file.-
   * @param _path
   */
  public GetFileContent(_path: string): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    this.http.get<IReturnState>(this.evision5.buildApi(this.baseUrl, 'files/content?path=' + encodeURIComponent(_path)))
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };

  /**
   * Updates the content of a file.
   * @param _path
   * @param _content
   */
  public UpdateFileContent(_path: string, _content: string): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();
    let uri = this.evision5.buildApi(this.baseUrl, 'files/content?path=' + encodeURIComponent(_path));
    let httpHeader = new HttpHeaders();
    httpHeader.append('Content-Type', 'text/plain');

    this.http.post<IReturnState>(uri, { content: encodeURIComponent(_content) }, { headers: httpHeader })
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };

  /**
   * Crops an image.
   * @param _path
   * @param _data
   */
  public CropImage(_path: string, _data: ICropModel): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();
    let uri = this.evision5.buildApi(this.baseUrl, 'files/image/crop?path=' + encodeURIComponent(_path));

    this.http.post<IReturnState>(uri, _data)
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };

  /**
   * Gets the file name for a filecopy.
   * @param _path
   * @param _data
   */
  public GetCopyFileName(_path: string, _copyTo: string): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();
    let uri = this.evision5.buildApi(this.baseUrl, 'files/copyfilename?path=' + encodeURIComponent(_path) + '&copyto=' + _copyTo);

    this.http.get<IReturnState>(uri)
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };

  /**
   * Copies a file.
   * @param _path
   * @param _data
   */
  public CopyFile(_path: string, _copyTo: string): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();
    let uri = this.evision5.buildApi(this.baseUrl, 'files/copy?path=' + encodeURIComponent(_path) + '&copyto=' + _copyTo);

    this.http.post<IReturnState>(uri, null)
      .subscribe(_result =>
      {
        onDone.emit(_result);
      });

    return onDone;
  };
}
