<div class="container-fluid" style="height:100%">
  <div class="row" style="height:100%" *ngIf="mode===2">
    <div class="col-sm-3 body-nav">
      <app-nav-menu (stateChanged)="onStateChangedHandler($event)" [isSessionLost]="isSessionLost"></app-nav-menu>
    </div>
    <div class="col-sm-9 body-content" style="height:100%">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="row panel-loading" style="height:100%" *ngIf="mode===1">
    <img class="loading-ani" src="./assets/images/loading.svg" />
  </div>
  <div class="row" style="height:100%" *ngIf="mode===0">
    <app-login (stateChanged)="onStateChangedHandler($event)" [isLocal]="isLocal" [isInit]="isInit"></app-login>
  </div>
</div>
