<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="filexplorer.editdirectory.header"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.back.msg" (click)="close()">
      <icon icon="ArrowLeft"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container">

      <mat-form-field>
        <input matInput [placeholder]="textPath" [(ngModel)]="path" disabled>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textName" [(ngModel)]="name">
      </mat-form-field>

    </div>

  </div>

</div>
