<div class="component component-container" [ngClass]="{'mode-dashboard':runningMode===MODE_DASHBOARD}"
  fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="runningMode===MODE_NORMAL" localize="mediajobs.summary.header"></h1>

  <mat-toolbar *ngIf="runningMode===MODE_NORMAL">
    <span class="toolbar-spacer"></span>
    <button mat-button *ngIf="application.hasRole('contentmanager')||application.isAdmin"
      localize="title:mediajobs.summary.toolbar.new.title" (click)="newMediaJob()">
      <icon icon="Plus"></icon>
    </button>
    <button mat-button (click)="loadMediaJobs()" localize="title:mediajobs.summary.toolbar.refresh.title">
      <icon icon="Sync"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!mediaJobs"><em localize="common.loadingdata.msg"></em></p>

    <div fxLayout="row" *ngIf="runningMode===MODE_NORMAL">
      <div fxFlex="250px" class="b-form-container in-one-row">
        <mat-checkbox [(ngModel)]="archived" (change)="loadMediaJobs()"><span
            localize="mediajobs.summary.archived.msg"></span></mat-checkbox>
      </div>
      <div fxFlex="250px" class="b-form-container in-one-row">
        <mat-checkbox [(ngModel)]="virtual" (change)="loadMediaJobs()"><span
            localize="mediajobs.summary.virtual.msg"></span></mat-checkbox>
      </div>

      <div fxFlex="250px" class="b-form-container">
        <mat-form-field>
          <mat-select [placeholder]="textDeviceFilter" [(ngModel)]="deviceID"
            (selectionChange)="onDeviceFilterChanged()">
            <mat-option value="" localize="$:common.noselection.msg"></mat-option>
            <mat-optgroup [label]="textDeviceDevice">
              <mat-option *ngFor="let d of devices" [value]="d.mediaDeviceID">{{ d.deviceName }}</mat-option>
            </mat-optgroup>
            <mat-optgroup [label]="textDeviceGroup">
              <mat-option *ngFor="let g of groups" [value]="g.mediaDeviceID">{{ g.deviceName }}</mat-option>
            </mat-optgroup>
            <mat-optgroup [label]="textDeviceVirtual">
              <mat-option *ngFor="let v of virtualdevices" [value]="v.mediaDeviceID">{{ v.deviceName }}</mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>

      </div>
    </div>

    <table mat-table [dataSource]="mediaJobsGrouped">

      <ng-container matColumnDef="jobName">
        <th mat-header-cell *matHeaderCellDef localize="mediajobs.summary.grid.name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.jobName }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="column-status" localize="mediajobs.summary.grid.status.title"></th>
        <td mat-cell *matCellDef="let element" class="column-status">
          <span class="status-wrapper">
            <span class="centered">
              <span *ngIf="element.status===1" localize="title:mediajobs.summary.grid.status.running.title">
                <icon icon="Play"></icon>
              </span>
              <span *ngIf="element.status===5" localize="title:mediajobs.summary.grid.status.stopped.title">
                <icon icon="Stop"></icon>
              </span>
              <span *ngIf="element.status===3" localize="title:mediajobs.summary.grid.status.other.title">
                <icon icon="CalendarAlt"></icon>
              </span>
              <span *ngIf="element.status===2" localize="title:mediajobs.summary.grid.status.paused.title">
                <icon icon="Pause"></icon>
              </span>
              <span *ngIf="element.status===4" localize="title:mediajobs.summary.grid.status.other.title">
                <icon icon="Clock"></icon>
              </span>
              <!--&nbsp;
            <span *ngIf="element.looped===true&&element.status!==1" localize="title:mediajobs.summary.grid.status.repeat.title">
              <icon icon="Redo"></icon>
            </span>-->
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="startStopAction">
        <th mat-header-cell *matHeaderCellDef class="column-elementcount cell-buttons one-button"></th>
        <td mat-cell *matCellDef="let element" class="column-elementcount cell-buttons one-button">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button *ngIf="element.status===5" localize="title:mediajobs.summary.grid.action.start.title"
                (click)="start(element.mediaJobID)">
                <icon icon="Play"></icon>
              </button>
              <button mat-button *ngIf="element.status===2" localize="title:mediajobs.summary.grid.action.start.title"
                (click)="start(element.mediaJobID)">
                <icon icon="Play"></icon>
              </button>
              <button mat-button *ngIf="element.status===1" localize="title:mediajobs.summary.grid.action.stop.title"
                (click)="stop(element.mediaJobID)">
                <icon icon="Stop"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef class="column-start" localize="mediajobs.summary.grid.start.title"></th>
        <td mat-cell *matCellDef="let element" class="column-start">{{ element.startTime | date:'short':'UTC' }}</td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef localize="mediajobs.summary.grid.duration.title"
          class="column-duration cell-right"></th>
        <td mat-cell *matCellDef="let element" class="column-duration cell-right">{{ element.durationTotalFormatted }}
        </td>
      </ng-container>

      <ng-container matColumnDef="mediaListName">
        <th mat-header-cell *matHeaderCellDef class="column-medialist"
          localize="mediajobs.summary.grid.medialistname.title"></th>
        <td mat-cell *matCellDef="let element" class="column-medialist">
          <a [routerLink]="['/medialists', element.mediaListID, 'mediaactivities']">
            {{ element.listName }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="device">
        <th mat-header-cell *matHeaderCellDef class="column-device" localize="mediajobs.summary.grid.device.title"></th>
        <td mat-cell *matCellDef="let element" class="column-device">
          <button mat-button *ngIf="element.deviceName!==null" (click)="editDevice(element.mediaDevice)"
            localize="title:mmediajobs.summary.grid.btn.editDevice.title">
            {{ element.deviceName }}
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="deviceDisplay">
        <th mat-header-cell *matHeaderCellDef class="column-device" localize="mediajobs.summary.grid.device.title"></th>
        <td mat-cell *matCellDef="let element" class="column-device">
          <span *ngIf="element.deviceName!==null">{{ element.deviceName }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons three-buttons">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button *ngIf="element.status===1" (click)="refreshMedia(element.mediaJobID)"
                localize="title:mediajobs.summary.grid.btn.refresh.title">
                <icon icon="Sync"></icon>
              </button>
              <button mat-button *ngIf="application.hasRole('contentmanager')||application.isAdmin"
                (click)="delete(element.mediaJobID)" localize="title:mediajobs.summary.grid.btn.delete.title">
                <icon icon="Trash"></icon>
              </button>
              <button mat-button localize="title:mediajobs.summary.grid.btn.edit.title"
                (click)="editMediaJob(element.mediaJobID)">
                <icon icon="Pen"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected':mediaJobID===row.mediaJobID}">
      </tr>

      <!-- Group header cells-->
      <ng-container matColumnDef="groupReducer">
        <td colspan="999" mat-cell *matCellDef="let group">
          <icon *ngIf="group.reduced" icon="CaretDown"></icon>
          <icon *ngIf="!group.reduced" icon="CaretUp"></icon>
          <span>&nbsp;</span>
          <strong *ngIf="group.value===utilsService.EmptyGuid()" class="group-name">{{group.groupName}}</strong>
          <button mat-button *ngIf="group.value!==utilsService.EmptyGuid()" (click)="editDevice($event, group.value)"
            localize="title:mediajobs.summary.grid.btn.editDevice.title">
            {{ group.groupName }}
          </button>
          <a mat-button (click)="$event.stopPropagation();"
            *ngIf="group.value!==utilsService.EmptyGuid()&&devicesDic[group.value]&&devicesDic[group.value].deviceGroup===false"
            class="device-preview" localize="title:mediadevices.summary.grid.preview.btn.title" target="_blank"
            [href]="devicesDic[group.value].previewUrl">
            <icon icon="Eye"></icon>
          </a>
        </td>
      </ng-container>

      <!-- Group line -->
      <tr mat-row class="clickable" *matRowDef="let row; columns: ['groupReducer']; when: isGroup"
        (click)="reduceGroup(row)">
      </tr>

    </table>
  </div>

</div>