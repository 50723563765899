import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { HttpWorkerFactoryService, HttpWorker } from './../http/httpworkerfactory.service';
import { ICanBeBusy, IsBusy } from './../busyindicator/busyindicatorfactory.service';
import { IUserApiKey } from '../../interfaces/IUserApiKey';
import { IReturnState } from '../../interfaces/IReturnState';
import { IApiKey } from '../../interfaces/IApiKey';
import { IGlobal } from '../../interfaces/IGlobal';
import { IGlobalSettings } from '../../interfaces/IGlobalSettings';
import { application } from '../../globals';
import { IApiAccessSlack } from '../../interfaces/IApiAccessSlack';

@Injectable({
  providedIn: 'root',
})

export class SlackService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();

  private globalSettings: IGlobalSettings = null;

  private http: HttpWorker;

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
  }

  /**
   * Gets all available apiKeys.
   */
  public GetAll(): EventEmitter<IApiAccessSlack[]>
  {
    let onDone = new EventEmitter<IApiAccessSlack[]>();

    this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'apiaccessslacks'))
      .subscribe(result =>
      {
        var data = result.data as IApiAccessSlack[];
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Gets a single apiKey.
   * @param _apiId
   */
  public GetApiKey(_apiId: number): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'apiaccessslack/' + _apiId))
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
  * Gets a single apiKey.
  * @param _apiKeyID
  */
  public SaveApiKey(_apiId: IApiAccessSlack): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var url = _apiId.id > 0
      ? application.buildApi(this.baseUrl, 'apiaccessslack/' + _apiId.id)
      : application.buildApi(this.baseUrl, 'apiaccessslack');

    this.http.post<IReturnState>(url, _apiId)
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Updates the api-access.
   * @param _apiKeyID
   */
  public ConfigureSuccess(_apiId: IApiAccessSlack, _state: boolean): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var url = application.buildApi(this.baseUrl, `apiaccessslack/${_apiId.id}/configure/${_state}`);

    this.http.post<IReturnState>(url, _apiId)
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
  * Deletes an apiKey.
  * @param _apiId
  */
  public DeleteApiKey(_apiId: number): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var url = application.buildApi(this.baseUrl, 'apiaccessslack/' + _apiId);
    this.http.delete<IReturnState>(url, {})
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };
}
