import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LocaleService
{
  constructor() { }

  getLanguage(): string
  {
    return navigator.language; // || navigator.userLanguage;
    //return 'fr-FR';
  }
}
