import { IApiAccessSlack } from '../interfaces/IApiAccessSlack';

export default class ApiAccessSlack implements IApiAccessSlack
{
  id: number;
  name: string;

  clientID: string;
  clientSecret: string;
  signingSecret: string;
  appID: string;
  accessToken: string;
  scope: string;
  userAccessToken: string;
  success: boolean;
  responseDate: string;
  state: string;
}
