import { IMediaJob } from '../interfaces/IMediaJob';

export default class MediaJob implements IMediaJob
{
  archive: boolean;
  deviceName: string;
  durationSum: number;
  durationTotal: number;
  endTime: string;
  everyDay: boolean;
  executed: boolean;
  iPFilter: string;
  jobName: string;
  listName: string;
  mediaListFirstMediaUrl: string;
  loopCount: number;
  looped: boolean;
  mediaDevice: string;
  mediaJobID: string;
  mediaListID: string;
  replayDays: number;
  startTime: string;
  status: number;
  isScene: boolean;

  durationTotalFormatted: string;
}
