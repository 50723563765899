<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <!--<h1 *ngIf="eventItem" localize="pwp.evenitem.single.header.edit"></h1>
  <h1 *ngIf="!eventItem||eventItem===0" localize="pwp.evenitem.single.header.new"></h1>-->

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <ngx-file-drop [dropZoneLabel]="statusDragnDrop"
                   (onFileDrop)="dropped($event)">
    </ngx-file-drop>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.cancel.msg" (click)="close()">
      <icon icon="ArrowLeft"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container no-scroll">

    <div fxFlex="500px" class="b-form-container">

      <div fxLayout="row" fxFlex="48px">
        <div class="b-form-container" fxFlex="128px">

          <mat-form-field>
            <input matInput [placeholder]="textWidth" [(ngModel)]="imageSettings.width">
          </mat-form-field>

        </div>
        <div class="b-form-container" fxFlex="128px">

          <mat-form-field>
            <input matInput [placeholder]="textHeight" [(ngModel)]="imageSettings.height">
          </mat-form-field>

        </div>
      </div>

      <mat-form-field>
        <input matInput [placeholder]="textTitle" [(ngModel)]="imageSettings.title">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textAlternateText" [(ngModel)]="imageSettings.alternateText">
      </mat-form-field>

      <div fxLayout="row" fxFlex="48px">
        <div fxFlex="128px" style="vertical-align:middle; line-height:39px;">

          <span localize="$:pwp.imageeditor.form.position.title"></span>

        </div>
        <div fxFlex="128px">

          <div>
            <mat-button-toggle-group name="picPosition" [(ngModel)]="imageSettings.picPosition" aria-label="Font Style">
              <mat-button-toggle ngDefaultControl [title]="textPicPosLeft" value="left"><span class="img-pic img-pic-left"></span></mat-button-toggle>
              <mat-button-toggle ngDefaultControl [title]="textPicPosLine" value="line"><span class="img-pic img-pic-line"></span></mat-button-toggle>
              <mat-button-toggle ngDefaultControl [title]="textPicPosRight" value="right"><span class="img-pic img-pic-right"></span></mat-button-toggle>
            </mat-button-toggle-group>
          </div>

        </div>
      </div>

      <div fxLayout="row" fxFlex="144px">
        <div fxFlex="128px" style="line-height:144px; vertical-align:middle;">

          <span localize="$:pwp.imageeditor.form.margins.title"></span>

        </div>
        <div fxFlex="128px">

          <div fxLayout="row">
            <div fxLayout="column" fxFlex="48px">
              <div fxFlex="48px" class="cell"></div>
              <div fxFlex="48px" class="cell">
                <div class="b-form-container nopadding" fxFlex="48px">
                  <mat-form-field>
                    <input matInput [placeholder]="textPicMarginTop" [(ngModel)]="imageSettings.marginTop">
                  </mat-form-field>
                </div>
              </div>
              <div fxFlex="48px" class="cell"></div>
            </div>
            <div fxLayout="column" fxFlex="48px">
              <div fxFlex="48px" class="cell">
                <div class="b-form-container nopadding" fxFlex="48px">
                  <mat-form-field>
                    <input matInput [placeholder]="textPicMarginLeft" [(ngModel)]="imageSettings.marginLeft">
                  </mat-form-field>
                </div>
              </div>
              <div fxFlex="48px" class="cell">
                <span class="img-pic img-pic-margins" style="height:100%; width:100%; background-size:75%; background-position:center;"></span>
              </div>
              <div fxFlex="48px" class="cell">
                <div class="b-form-container nopadding" fxFlex="48px">
                  <mat-form-field>
                    <input matInput [placeholder]="textPicMarginRight" [(ngModel)]="imageSettings.marginRight">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div fxLayout="column" fxFlex="48px">
              <div fxFlex="48px" class="cell"></div>
              <div fxFlex="48px" class="cell">
                <div class="b-form-container nopadding" fxFlex="48px">
                  <mat-form-field>
                    <input matInput [placeholder]="textPicMarginBottom" [(ngModel)]="imageSettings.marginBottom">
                  </mat-form-field>
                </div>
              </div>
              <div fxFlex="48px" class="cell"></div>
            </div>
          </div>

        </div>
      </div>

      <!--<mat-checkbox [(ngModel)]="imageSettings.saveInLib">
        <span localize="$:pwp.imageeditor.form.storeinlib.title"></span>
      </mat-checkbox>-->

    </div>
    <div fxFlex="300px" class="b-form-container">

      <img [src]="selectedFileData"
           *ngIf="selectedFileData!==null"
           id="imageEditor"
           #imageEditor
           width="400"
           class="preview" />

    </div>

  </div>

</div>
