import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { UriBuilderService } from '../../../../app-pwp/src/app/core/services/common/uribuilder.service';
import { IWidgetDefinition } from '../core/interfaces/IWidgetDefinition';
import { WidgetsService } from '../core/services/data/widgets.service';

@Component({
  selector: 'app-widget-single',
  templateUrl: './widget-single.component.html',
  styleUrls: ['./widget-single.component.css']
})
export class WidgetSingleComponent implements OnInit {

  constructor(private widgetsService: WidgetsService,
    private uriBuilderService: UriBuilderService) {
  }

  private widgetDefinition: IWidgetDefinition;
  private widgetPath: string;

  public parameterValues: object = {};
  public pathIsWidget: boolean = false;

  @Input()
  public set WidgetPath(value: string) { this.widgetPath = value; this.getWidget(); };

  @Output()
  public onUrlChanged = new EventEmitter();

  ngOnInit(): void {
  }

  public getWidget() {

    if (!this.widgetPath) {
      return;
    }

    let url = this.uriBuilderService.Get().Parse(this.widgetPath).ClearQueryStrings().Build();
    if (url.endsWith('.html')) {
      url = url.substring(0, url.lastIndexOf('/'));
    }

    this.widgetsService.GetWidget(url).subscribe((data: IReturnState) => {

      if (data.success) {
        this.pathIsWidget = true;
        this.widgetDefinition = data.data as IWidgetDefinition;

        if (!this.widgetDefinition) {
          return;
        }

        let uriBuilder = this.uriBuilderService.Get().Parse(this.widgetPath);
        for (let i = 0; i < this.widgetDefinition.parameters.length; i++) {
          let p = this.widgetDefinition.parameters[i];
          this.parameterValues[p.urlAttribute] = uriBuilder.QueryStrings.hasOwnProperty(p.urlAttribute)
            ? uriBuilder.QueryStrings[p.urlAttribute]
            : null;
        }
      }
      else {
        this.pathIsWidget = false;
      }

    });
  }

  public onValueChanged() {

    let uriBuilder = this.uriBuilderService.Get();
    uriBuilder.Parse(this.widgetPath);

    if (!this.widgetDefinition) {
      return;
    }

    for (let i = 0; i < this.widgetDefinition.parameters.length; i++) {
      let p = this.widgetDefinition.parameters[i];
      let parameterValue = this.parameterValues[p.urlAttribute];

      if (parameterValue !== undefined && parameterValue !== null) {
        uriBuilder.AddQueryString(p.urlAttribute, parameterValue);
      }
    }

    let uri = uriBuilder.Build();
    this.onUrlChanged.emit(uri);
  }

}
