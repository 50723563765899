import { IMediaActivity } from '../interfaces/IMediaActivity';

export default class MediaActivity implements IMediaActivity {
  mediaActivityID: string;
  mediaListID: string;
  duration: number;
  rank: number;
  title: string;
  mediaUrl: string;
  secondsPerPage: number;
  mediaLayoutID: string;
}
