<div class="component component-container" fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 mat-dialog-title><span localize="mediaactivities.duration.header.01"></span><span>{{item.mediaListName}}</span><span localize="mediaactivities.duration.header.02"></span></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button [mat-dialog-close]="true" localize="title:common.cancel.msg">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="300px" class="b-form-container">

      <mat-form-field>
        <input matInput [placeholder]="textPlaceholderDuration" [(ngModel)]="item.duration">
      </mat-form-field>

    </div>

  </div>

</div>
