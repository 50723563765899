import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IGlobal } from '../../interfaces/IGlobal';
import { application } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class SecurityService
{
  constructor(private router: Router) { }

  checkForRolesByName(_roleAccessObjectName: string, _redirect: boolean = true)
  {
    return true;
    //var rao: RoleAccessObjects = null;
    //for (var i = 0; i < this.babel.roleAccessObjects.length; i++)
    //{
    //    if (this.babel.roleAccessObjects[i].name === _roleAccessObjectName)
    //    {
    //        rao = this.babel.roleAccessObjects[i];
    //        break;
    //    }
    //}

    //if (rao === null)
    //{
    //    this.checkForRoles([], _redirect);
    //    return;
    //}

    //var roles = [];
    //if (rao.roleAdministrator)
    //{
    //    roles.push('A');
    //}

    //if (rao.roleDeveloper)
    //{
    //    roles.push('D');
    //}

    //if (rao.roleTechnicalWriter)
    //{
    //    roles.push('TC');
    //}

    //if (rao.roleTranslator)
    //{
    //    roles.push('T');
    //}

    //return this.checkForRoles(roles, _redirect);
  };

  public CheckForRoles(_requiresAdmin: boolean, _roles: string[], _redirect: boolean = true)
  {
    var success = _requiresAdmin && application.isAdmin;
    if (!success)
    {
      for (var i = 0; i < _roles.length; i++)
      {
        if (application.hasRole(_roles[i]))
        {
          success = true;
          break;
        }
      }
    }

    if (!success && _redirect === true)
    {
      this.router.navigate(['']);
    }

    return success;
  };

  public CheckForFeature(_featureName: string, _redirect: boolean = true)
  {
    var enabled = application.featureEnabled(_featureName);

    if (!enabled && _redirect === true)
    {
      this.router.navigate(['']);
    }

    return enabled;
  };
}
