import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class HttpErrorService
{
  public http401: BehaviorSubject<boolean>;

  constructor()
  {
    //initialize it to false
    this.http401 = new BehaviorSubject<boolean>(false);
  }
}
