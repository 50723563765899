import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { HttpWorkerFactoryService, HttpWorker } from './../http/httpworkerfactory.service';
import { ICanBeBusy, IsBusy } from './../busyindicator/busyindicatorfactory.service';
import { IUserApiKey } from '../../interfaces/IUserApiKey';
import { IReturnState } from '../../interfaces/IReturnState';
import { IApiKey } from '../../interfaces/IApiKey';
import { IGlobal } from '../../interfaces/IGlobal';
import { IGlobalSettings } from '../../interfaces/IGlobalSettings';
import { application } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class ApiKeysService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();

  private globalSettings: IGlobalSettings = null;

  private http: HttpWorker;

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
  }

  /**
 * Gets all available apiKeys.
 */
  public GetApiKeysByUser(_userID: number): EventEmitter<IUserApiKey[]>
  {
    let onDone = new EventEmitter<IUserApiKey[]>();

    this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'apikeys/user/' + _userID))
      .subscribe(result =>
      {
        var data = result.data as IUserApiKey[];
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Gets all available apiKeys.
   */
  public GetApiKeys(): EventEmitter<IUserApiKey[]>
  {
    let onDone = new EventEmitter<IUserApiKey[]>();

    this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'apikeys/extended'))
      .subscribe(result =>
      {
        var data = result.data as IUserApiKey[];
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Gets a single apiKey.
   * @param _apiKeyID
   */
  public GetApiKey(_apiKeyID: number): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'apikeys/' + _apiKeyID + '/key'))
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
  * Gets a single apiKey.
  * @param _apiKeyID
  */
  public SaveApiKey(_apiKey: IApiKey): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var url = _apiKey.id > 0
      ? application.buildApi(this.baseUrl, 'apikey/' + _apiKey.id)
      : application.buildApi(this.baseUrl, 'apikey');

    this.http.post<IReturnState>(url, _apiKey)
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
  * Deletes an apiKey.
  * @param _apiKeyID
  */
  public DeleteApiKey(_apiKeyID: number): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var url = application.buildApi(this.baseUrl, 'apikey/' + _apiKeyID);
    this.http.delete<IReturnState>(url, {})
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };
}
