<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="!mediaDeviceID && mode==='device'" localize="mediadevice.single.header.new"></h1>
  <h1 *ngIf="mediaDeviceID && mode==='device'" localize="mediadevice.single.header.edit"></h1>

  <h1 *ngIf="!mediaDeviceID && mode==='group'" localize="mediadevice.single.header.group.new"></h1>
  <h1 *ngIf="mediaDeviceID && mode==='group'" localize="mediadevice.single.header.group.edit"></h1>

  <mat-toolbar>
    <button mat-button localize="title:mediadevice.activate.title" (click)="createActivationKey()">
      <icon icon="Plug"></icon>
    </button>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.back.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!mediaDevice"><em localize="common.loadingdata.msg"></em></p>

    <div fxFlex="100%" class="b-form-container wrapper">

      <div class="b-form-container wrapper">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title><span localize="$:mediadevice.single.form.group.primary.header"></span></mat-panel-title>
          </mat-expansion-panel-header>
           
          <div class="b-form-container wrapper">

            <mat-form-field *ngIf="mode==='device'">
              <input matInput [placeholder]="textDeviceName" [(ngModel)]="mediaDevice.deviceName">
            </mat-form-field>

            
            <div class="b-form-container" *ngIf="mode==='device'">
              <mat-checkbox [(ngModel)]="mediaDevice.virtualDevice"><span localize="mediadevice.single.form.virtualdevice.title"></span></mat-checkbox>
            </div>
            

            <mat-form-field *ngIf="mode==='group'">
              <input matInput [placeholder]="textGroupName" [(ngModel)]="mediaDevice.deviceName">
            </mat-form-field>

            <mat-form-field *ngIf="mode==='device'">
              <input matInput [placeholder]="textMachineName" [(ngModel)]="mediaDevice.machineName">
            </mat-form-field>

            <mat-form-field *ngIf="mode==='device'">
              <input matInput [placeholder]="textDeviceIP" [(ngModel)]="mediaDevice.deviceIP">
            </mat-form-field>

            <mat-form-field *ngIf="mode==='device'">
              <input matInput [placeholder]="textDeviceMAC" [(ngModel)]="mediaDevice.deviceMAC">
            </mat-form-field>


            <mat-form-field *ngIf="mode==='device'">
              <textarea matInput [placeholder]="textDescription" [(ngModel)]="mediaDevice.description" class="textarea-description"></textarea>
            </mat-form-field>

            <mat-form-field *ngIf="mode==='device'">
              <input matInput [placeholder]="textDefaultUrl" [(ngModel)]="mediaDevice.defaultUrl">
            </mat-form-field>

            <mat-form-field *ngIf="mode==='device'">
              <input matInput [placeholder]="textLocation" [(ngModel)]="mediaDevice.location">
            </mat-form-field>

            <p>
              <span localize="mediadevice.single.form.start-stop.title"></span>
            </p>

            <ul *ngIf="days && mode==='device'">
              <li *ngFor="let d of days" class="weekdays"><mat-checkbox [(ngModel)]="d.selected">{{ d.text }}</mat-checkbox></li>
            </ul>

            <div fxLayout="row">
              <div class="b-form-container" fxFlex="35%" *ngIf="mode==='device'" localize="$:mediadevice.single.form.start-stop.from.title"></div>
              <div class="b-form-container" fxFlex="35%" *ngIf="mode==='device'" localize="$:mediadevice.single.form.start-stop.to.title"></div>
            </div>

            <div class="b-form-container" fxLayout="row">

              <div class="b-form-container nopadding form-field" fxFlex="180px" *ngIf="mode==='device'">
                <ngx-timepicker-field [format]="24" [(ngModel)]="startTime"></ngx-timepicker-field>
              </div>

              <div class="b-form-container nopadding form-field" fxFlex="180px" *ngIf="mode==='device'">
                <ngx-timepicker-field [format]="24" [(ngModel)]="endTime"></ngx-timepicker-field>
              </div>

            </div>


            <!--<div fxLayout="row">-->
            <!--<div class="b-form-container calendar-time">
    <div class="time-picker-wrapper">
      <input atp-time-picker [value]="startTime" type="time" />
    </div>
  </div>

  <div class="b-form-container calendar-time">
    <div class="time-picker-wrapper">
      <input atp-time-picker [value]="endTime" type="time" (change)="onStartupTimeChangeHandler($event)"/>
    </div>
  </div>-->
            <!--<div class="b-form-container calendar-time" *ngIf="mode==='device'">-->
            <!--<ngx-timepicker-field [format]="24" [(ngModel)]="startTime"></ngx-timepicker-field>-->
            <!--</div>-->
            <!--<div class="b-form-container calendar-time" *ngIf="mode==='device'">-->
            <!--<ngx-timepicker-field [format]="24" [(ngModel)]="endTime"></ngx-timepicker-field>-->
            <!--</div>-->
            <!--</div>-->

            <p></p>

            <mat-form-field *ngIf="mode==='device'">
              <mat-select [placeholder]="textSilentVideoPlayback" [(ngModel)]="mediaDevice.silentVideoPlayback">
                <mat-option [value]="-1" localize="$:mediadevice.single.form.silentvideoplayback.system"></mat-option>
                <mat-option [value]="0" localize="$:mediadevice.single.form.silentvideoplayback.unmuted"></mat-option>
                <mat-option [value]="1" localize="$:mediadevice.single.form.silentvideoplayback.muted"></mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="mode==='device'">
              <input matInput [placeholder]="textDisplayTypeName" [(ngModel)]="mediaDevice.displayTypeName">
            </mat-form-field>

            <ul *ngIf="mode==='group'">
              <li class="device" *ngFor="let md of mediaDevices">
                <mat-checkbox [(ngModel)]="md.selected">{{ md.deviceName }}</mat-checkbox>
              </li>
            </ul>

          </div>

        </mat-expansion-panel>
      </div>

      <div class="b-form-container wrapper" *ngIf="mode==='device'&&application.isAdmin">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title><span localize="$:mediadevice.single.form.group.roles.header"></span></mat-panel-title>
          </mat-expansion-panel-header>

          <div fxFlex="250px" class="b-form-container wrapper">

            <ul class="roles">
              <li class="role" *ngFor="let r of roles">
                <mat-checkbox [(ngModel)]="r.selected">{{ r.roleName }}</mat-checkbox>
              </li>
            </ul>

          </div>

        </mat-expansion-panel>
      </div>

      <div class="b-form-container wrapper" *ngIf="mode==='device'">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title><span localize="$:mediadevice.single.form.group.additional.header"></span></mat-panel-title>
          </mat-expansion-panel-header>

          <div class="b-form-container wrapper">
            <mat-form-field *ngIf="mode==='device'">
              <input matInput [placeholder]="textDisplaySerialNumber" [(ngModel)]="mediaDevice.displaySerialNumber">
            </mat-form-field>

            <mat-form-field *ngIf="mode==='device'">
              <input matInput [matDatepicker]="displayWarantyDatepicker" [placeholder]="textDisplayWaranty" [(ngModel)]="mediaDevice.displayWaranty">
              <mat-datepicker-toggle matSuffix [for]="displayWarantyDatepicker">
              </mat-datepicker-toggle>
              <mat-datepicker #displayWarantyDatepicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field *ngIf="mode==='device'">
              <input matInput [placeholder]="textPcSerialNumber" [(ngModel)]="mediaDevice.pcSerialNumber">
            </mat-form-field>

            <mat-form-field *ngIf="mode==='device'">
              <input matInput [matDatepicker]="displayPcWaranty" [placeholder]="textPcWaranty" [(ngModel)]="mediaDevice.pcWaranty">
              <mat-datepicker-toggle matSuffix [for]="displayPcWaranty">
              </mat-datepicker-toggle>
              <mat-datepicker #displayPcWaranty></mat-datepicker>
            </mat-form-field>

          </div>

        </mat-expansion-panel>
      </div>

    </div>

  </div>

</div>
