import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { IGlobal } from '../../../../app-pwp/src/app/core/interfaces/IGlobal';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

//class Card
//{
//  cols: number;
//  rows: number;
//}

export class DashboardComponent
{
  public time: string = '';

  public application: IGlobal = application;

  // Total count of cols defined in html: 6

  private cardsFullSize = {
    welcome: { cols: 4, rows: 1 },
    time: { cols: 2, rows: 1 },
    medialists: { cols: 3, rows: 8 },
    mediadevices: { cols: 3, rows: 4 },
    mediajobs: { cols: 3, rows: 4 }
  };
  private cardsTabletSize = {
    welcome: { cols: 4, rows: 1 },
    time: { cols: 2, rows: 1 },
    medialists: { cols: 3, rows: 8 },
    mediadevices: { cols: 3, rows: 4 },
    mediajobs: { cols: 3, rows: 4 }
  };
  private cardsHandSetSize = {
    welcome: { cols: 3, rows: 1 },
    time: { cols: 3, rows: 1 },
    medialists: { cols: 6, rows: 4 },
    mediadevices: { cols: 6, rows: 4 },
    mediajobs: { cols: 6, rows: 4 }
  };

  /** Based on the screen size, switch from standard to one column per row */
  public cards = this.cardsFullSize;

  constructor(private breakpointObserver: BreakpointObserver)
  {
    setInterval(() =>
    {
      this.time = new Date().toLocaleString();
    }, 500);

    this.breakpointObserver
      .observe(Breakpoints.Web)
      .subscribe((_a) =>
      {
        if (_a.matches)
          this.cards = this.cardsFullSize;
      });

    this.breakpointObserver
      .observe([Breakpoints.TabletLandscape, Breakpoints.TabletPortrait, Breakpoints.HandsetLandscape])
      .subscribe((_a) =>
      {
        if (_a.matches)
          this.cards = this.cardsTabletSize;
      });

    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait])
      .subscribe((_a) =>
      {
        if (_a.matches)
          this.cards = this.cardsHandSetSize;
      });
  }
}
