import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';
import { IMediaJob } from '../core/interfaces/IMediaJob';
import MediaJob from '../core/models/MediaJob';
import { IMediaList } from '../core/interfaces/IMediaList';
import { IMediaDevice } from '../core/interfaces/IMediaDevice';
import { IWeekDayItem } from '../../../../app-pwp/src/app/core/interfaces/IWeekDayItem';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { KeyHandler, UiKeyHandleService } from '../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { DateTimeService } from '../../../../app-pwp/src/app/core/services/common/datetime.service';
import { WeekDaysService } from '../../../../app-pwp/src/app/core/services/common/weekdays.service';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';

@Component({
  selector: 'app-mediajob-single',
  templateUrl: './mediajob-single.component.html',
  styleUrls: ['./mediajob-single.component.css']
})

export class MediaJobSingleComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private dateTimePickerSettings = {
    bigBanner: true,
    timePicker: true,
    format: 'dd-MM-yyyy hh:mm',
    defaultOpen: false
  };

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;

  private datePickerConfig: {};

  // this flag is required to handle the manual init via init().
  // Avoids resetting/overwriting the id from parameters.
  private initiated: boolean = false;

  public mediaJobID: string = null;
  public mediaJob: IMediaJob = new MediaJob();
  public mediaLists: IMediaList[] = null;
  private mediaDevices: IMediaDevice[] = null;
  public devices: IMediaDevice[] = null;
  public groups: IMediaDevice[] = null;

  public startTime: string = '00:00';
  public endTime: string = '00:00';

  public days: IWeekDayItem[] = this.weekDaysService.GetWeekDays();

  public textName: string = application.getRawText('mediajob.single.form.name.title');
  public textMediaList: string = application.getRawText('mediajob.single.form.medialist.title');
  public textLooped: string = application.getRawText('mediajob.single.form.looped.title');
  public textStart: string = application.getRawText('mediajob.single.form.start.title');
  public textEnd: string = application.getRawText('mediajob.single.form.end.title');
  public textLoopCount: string = application.getRawText('mediajob.single.form.loopcount.title');
  public textDevice: string = application.getRawText('mediajob.single.form.device.title');
  public textDeviceDevice: string = application.getRawText('mediajob.single.form.device.device.title');
  public textDeviceGroup: string = application.getRawText('mediajob.single.form.device.group.title');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  public keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,

    private route: ActivatedRoute,
    private dateTimeService: DateTimeService,
    private weekDaysService: WeekDaysService,
    private uiKeyHandleService: UiKeyHandleService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
    this.keyHandler.OnCancel.subscribe(() => this.triggerOnClose());
  };

  ngOnInit() {
    if (this.initiated) {
      return;
    }

    this.mediaJobID = this.route.snapshot.paramMap.get('id');
    this.initMediaJob();
    this.loadMediaLists();
    this.loadMediaDevices();

    this.initiated = true;
  };

  public init(_mediaJobID: string) {
    this.mediaJobID = _mediaJobID;
    this.initMediaJob();
    this.loadMediaLists();
    this.loadMediaDevices();

    this.initiated = true;
  };

  private initMediaJob() {
    if (this.mediaJobID === 'new') {
      this.mediaJobID = null;
    }

    if (this.mediaJobID === null) {
      this.mediaJob = new MediaJob();
      this.mediaJob.isScene = false;
      this.mediaJob.startTime = this.dateTimeService.Now();
      this.mediaJob.endTime = null;
      this.mediaJob.looped = false;
      this.mediaJob.loopCount = -1;

      this.startTime = this.dateTimeService.GetTime(this.mediaJob.startTime, true);
      this.endTime = this.dateTimeService.GetTime(this.mediaJob.endTime, true);
      if (this.endTime === null) {
        this.endTime = '00:00';
      }
    }
    else {
      this.loadMediaJob();
    }
  };

  private loadMediaJob() {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediajob/' + this.mediaJobID))
      .subscribe(result => {
        var tmp = result.data as IMediaJob;
        this.setMediaJob(tmp);
      }, error => console.error(error));
  };

  public setMediaJob(_mediaJob: IMediaJob) {
    this.mediaJob = _mediaJob;
    this.getReplayDaysFromModel();

    var tmpStart = this.dateTimeService.EnsureUTC(this.mediaJob.startTime);
    this.startTime = this.dateTimeService.GetTime(tmpStart, true);
    this.mediaJob.startTime = this.dateTimeService.TimeToLunch(tmpStart);

    if (this.mediaJob.endTime !== null) {
      this.mediaJob.endTime = this.dateTimeService.EnsureUTC(this.mediaJob.endTime);

      if (this.mediaJob.endTime === this.dateTimeService.MaxDate()) {
        this.mediaJob.endTime = null;
        this.endTime = '00:00';
      }
      else {
        this.endTime = this.dateTimeService.GetTime(this.mediaJob.endTime, true);
        this.mediaJob.endTime = this.dateTimeService.TimeToLunch(this.mediaJob.endTime);
      }
    }
  };

  private loadMediaLists() {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'medialists'))
      .subscribe(result => {
        this.mediaLists = result.data as IMediaList[];
      }, error => console.error(error));
  };

  private loadMediaDevices() {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevices/includinggroups'))
      .subscribe(result => {
        this.mediaDevices = result.data as IMediaDevice[];
        this.devices = [];
        this.groups = [];
        for (var i = 0; i < this.mediaDevices.length; i++) {
          if (this.mediaDevices[i].deviceGroup) {
            this.groups.push(this.mediaDevices[i]);
          }
          else {
            this.devices.push(this.mediaDevices[i]);
          }
        }

      }, error => console.error(error));
  };

  public loopedChangeHandler() {
    if (this.mediaJob.looped === false) {
      this.mediaJob.loopCount = -1;
      this.mediaJob.endTime = null;
      this.endTime = '00:00';
    }
    else {
      this.mediaJob.loopCount = 1;
      this.mediaJob.endTime = null;
      this.endTime = '00:00';
    }
  };

  private setReplayDaysToModel() {
    this.mediaJob.replayDays = this.weekDaysService.ConvertStartupDaysToValue(this.days);
  };

  private getReplayDaysFromModel() {
    this.weekDaysService.ConvertStartupDaysFromValue(this.days, this.mediaJob.replayDays);
  };

  public startFromNow() {
    var tmp = this.dateTimeService.Now();
    this.startTime = this.dateTimeService.GetTime(tmp, true);
    this.mediaJob.startTime = this.dateTimeService.TimeToLunch(tmp);
  };

  public endToNow() {
    var tmp = this.dateTimeService.Now();
    this.endTime = this.dateTimeService.GetTime(tmp, true);
    this.mediaJob.endTime = this.dateTimeService.TimeToLunch(tmp);
  };

  public save() {
    this.setReplayDaysToModel();

    if (!this.mediaJob.jobName) {
      this.dialogGeneric = this.dialog.open(DialogComponent, {
        autoFocus: true,
        height: '250px',
        width: '550px'
      });
      this.dialogGeneric.componentInstance.options.actionOk = true;
      this.dialogGeneric.componentInstance.options.title = application.getRawText('common.error.msg');
      this.dialogGeneric.componentInstance.options.message = application.getRawText('scene.single.save.error.missing-name.msg');
      return;
    }

    var start = this.dateTimeService.SetTimeToDate(this.startTime, this.mediaJob.startTime);
    var end = this.mediaJob.endTime;
    if (this.mediaJob.looped === false) {
      if (end === null) {
        end = this.dateTimeService.MaxDate();
      }
      else {
        end = this.dateTimeService.SetTimeToDate(this.endTime, this.mediaJob.endTime);
      }
    }

    if (this.mediaJob.looped === true) {
      end = this.dateTimeService.MaxDate();

      if (!(this.mediaJob.loopCount > 0)) {
        this.mediaJob.loopCount = 1;
      }
    }
    else {
      this.mediaJob.loopCount = 1;
      // Check dates
      if (new Date(start) >= new Date(end)) {
        this.dialogGeneric = this.dialog.open(DialogComponent, {
          autoFocus: true,
          height: '250px',
          width: '550px'
        });
        this.dialogGeneric.componentInstance.options.actionOk = true;
        this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
        this.dialogGeneric.componentInstance.options.message = application.getRawText('mediajobs.summary.error.startdate-greater-enddate.msg');
        return;
      }
    }

    var url = this.mediaJobID === null
      ? evision5.buildApi(this.baseUrl, 'mediajob')
      : evision5.buildApi(this.baseUrl, 'mediajob/' + this.mediaJobID);

    this.mediaJob.startTime = start;
    this.mediaJob.endTime = end;

    this.http
      .post<IReturnState>(url, this.mediaJob)
      .subscribe(
        result => {
          if (result.success) {
            var tmp = result.data as IMediaJob;
            this.setMediaJob(tmp);

            this.mediaJobID = this.mediaJob.mediaJobID;
            this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
            this.triggerOnClose();
          }
          else {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        },
        error => console.error(error));
  };

  public triggerOnClose() {
    this.onClose.emit();
  };
}
