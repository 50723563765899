import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FileExplorerComponent } from '../../fileexplorer/fileexplorer.component';
import File from '../../core/models/File';
import { IMediaSettingsCollection } from '../../core/interfaces/IMediaSettingsCollection';
import MediaSettingsCollection from '../../core/models/MediaSettingsCollection';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../../app-pwp/src/app/dialog/dialog.component';
import { KeyHandler, UiKeyHandleService } from '../../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../../app-pwp/src/app/core/services/common/security.service';
import { SettingsService } from '../../core/services/data/settings.service';
import { FileExplorerService } from '../../core/services/data/fileexplorer.service';
import { IReturnState } from '../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { application } from '../../../../../app-pwp/src/app/core/globals';
import { evision5 } from '../../core/globals';
import { FileEditorComponent } from '../fileeditor/fileeditor.component';
import { ImageCropEditorComponent } from '../imagecropeditor/imagecropeditor.component';
import { image } from 'html2canvas/dist/types/css/types/image';
import { UriBuilderService } from '../../../../../app-pwp/src/app/core/services/common/uribuilder.service';

@Component({
  selector: 'app-previewfile',
  templateUrl: './previewfile.component.html',
  styleUrls: ['./previewfile.component.css']
})

export class PreviewFileComponent {
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public plainText: string;
  public element: File;
  public path: string;
  public name: string;
  public files: File[] = [];

  public currentPreviewIndex: number = 0;

  public onClose = new EventEmitter();

  private settings: IMediaSettingsCollection = null; //new MediaSettingsCollection();
  private fileWasEdited: boolean = false;

  private initiated: boolean = false;

  public textPath: string = application.getRawText('filexplorer.editdirectory.form.path.title');
  public textName: string = application.getRawText('filexplorer.editdirectory.form.name.title');

  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogFileEditor: MatDialogRef<FileEditorComponent>;
  private dialogImageCropEditor: MatDialogRef<ImageCropEditorComponent>;

  public keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private fileExplorerService: FileExplorerService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private uriBuilderService: UriBuilderService) {
    //this.security.checkForRolesByName('devices.summary');
    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());

    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.fileExplorerService);
    this.busyIndicator.Register(this.settingsService);
  };

  ngOnInit() {
    if (this.initiated) {
      return;
    }

    this.init('/', null, null);
  };

  init(_path: string, _element: File, _files: File[]) {
    this.path = _path;
    this.files = _files;
    this.element = _element;

    this.settingsService.GetSettings().subscribe((_settings: IMediaSettingsCollection) => {
      this.settings = _settings;
      this.loadContentIfPlainText();
    });

    this.getIndexOfCurrent();

    this.initiated = true;
  };

  private loadContentIfPlainText() {
    if (this.isPlainText(this.element)) {
      this.fileExplorerService.GetFileContent(this.element.path).subscribe((_result: IReturnState) => {
        this.plainText = _result.data as unknown as string;
      });
    }
  };

  public getMediaUrlPath(_file: File) {
    if (this.settings === null) {
      return '';
    }

    if (this.isImage(_file))
      return _file.url;
    else if (this.isVideo(_file))
      return _file.url;
    else if (this.isPlainText(_file))
      return _file.url;
    else
      return evision5.buildApi(this.baseUrl, 'files/thumbnail?mediaurl=' + encodeURIComponent(_file.url));
  };

  public getMediaDownloadUrlPath(_file: File) {
    var uri = this.uriBuilderService.Get();
    uri.Parse(this.element.url)
      .AddQueryString('t', Date.now().toString())
      .AddQueryString('download', 'true');
    var uriString = uri.Build();
    return uriString;
  };

  private refreshPreview() {
    var uri = this.uriBuilderService.Get();
    uri.Parse(this.element.url).AddQueryString('t', Date.now().toString());
    var uriNow = uri.Build();
    this.element.url = uriNow;
  };

  private getFileExt(_file: File) {
    var ext = _file.extension.toLowerCase();
    return ext;
  };

  public isPlainText(_file: File) {
    return this.settings &&
      this.settings.plainTextFormatsValue &&
      this.settings.plainTextFormatsValue.indexOf(this.getFileExt(_file)) > -1;
  }

  public isImage(_file: File) {
    return this.settings &&
      this.settings.imageFormatsValue &&
      this.settings.imageFormatsValue.indexOf(this.getFileExt(_file)) > -1;
  }

  private isVideo(_file: File) {
    return this.settings &&
      this.settings.videoFormatsValue &&
      this.settings.videoFormatsValue.indexOf(this.getFileExt(_file)) > -1;
  }

  public showInIframe(_file: File) {
    return this.settings &&
      this.settings.plainTextFormatsValue &&
      this.settings.plainTextFormatsValue.indexOf(this.getFileExt(_file)) > -1;
  }

  private getIndexOfCurrent() {
    var i = 0;
    var n = this.files.length;
    for (i; i < n; i++) {
      if (this.files[i].url == this.element.url) {
        this.currentPreviewIndex = i - 1;
        break;
      }
    }
  }

  public prev() {
    var i = 0;
    var n = this.files.length;
    for (i; i < n; i++) {
      if (this.files[i].url == this.element.url) {
        if (i > 0) {
          this.element = this.files[i - 1];
        }

        this.currentPreviewIndex = i - 1;

        this.loadContentIfPlainText();
        break;
      }
    }
  }

  public next() {
    var i = 0;
    var n = this.files.length;
    for (i; i < n; i++) {
      if (this.files[i].url == this.element.url) {
        if (i < n - 1) {
          this.element = this.files[i + 1];
        }

        this.currentPreviewIndex = i + 1;

        this.loadContentIfPlainText();
        break;
      }
    }
  }

  private save() {
  };

  public openEditor() {
    if (this.isPlainText(this.element)) {
      this.fileWasEdited = true;

      this.dialogFileEditor = this.dialog.open(FileEditorComponent, {
        autoFocus: true,
        height: '80%',
        width: '80%'
      });

      this.dialogFileEditor.componentInstance.extension = this.element.extension;
      this.dialogFileEditor.componentInstance.init(this.path, this.element);
      this.dialogFileEditor.componentInstance.onClose.subscribe(() => {
        this.refreshPreview();
        this.dialogFileEditor.close();
        this.dialogFileEditor = null;
      });
    }
    else if (this.isImage(this.element)) {
      this.fileWasEdited = true;

      this.dialogImageCropEditor = this.dialog.open(ImageCropEditorComponent, {
        autoFocus: true,
        height: '80%',
        width: '80%'
      });

      this.dialogImageCropEditor.componentInstance.init(this.path, this.element);
      this.dialogImageCropEditor.componentInstance.onClose.subscribe(() => {
        this.refreshPreview();
        this.dialogImageCropEditor.close();
        this.dialogImageCropEditor = null;
      });
    }
  };

  public HasFileBeenEdited(): boolean {
    return this.fileWasEdited;
  };

  public close() {
    this.onClose.emit();
  };
}
