import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IGlobal } from '../../../../../../app-pwp/src/app/core/interfaces/IGlobal';
import { IGlobalEvision5 } from '../../interfaces/IGlobalEvision5';
import { IsBusy, ICanBeBusy } from '../../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { IGlobalSettings } from '../../../../../../app-pwp/src/app/core/interfaces/IGlobalSettings';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { GlobalSettingsService } from '../../../../../../app-pwp/src/app/core/services/data/globalsettings.service';
import { ApiKeysService } from '../../../../../../app-pwp/src/app/core/services/data/apikeys.service';
import { RolesService } from '../../../../../../app-pwp/src/app/core/services/data/roles.service';
import { IReturnState } from '../../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IMediaListExtended } from '../../interfaces/IMediaListExtended';
import { application } from '../../../../../../app-pwp/src/app/core/globals';
import { evision5 } from '../../globals';


@Injectable({
  providedIn: 'root',
})

export class MediaListsService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();

  private application: IGlobal = application;
  private evision5: IGlobalEvision5 = evision5;

  private globalSettings: IGlobalSettings = null;

  private http: HttpWorker;

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private globalSettingsService: GlobalSettingsService,
    private apiKeyService: ApiKeysService,
    private rolesService: RolesService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
    this.IsBusy.PassThrough(this.globalSettingsService);
    this.IsBusy.PassThrough(this.rolesService);
  }

  /**
   * Gets all roleIDs assigned to a mediaList.
   */
  public GetRolesOfMediaList(_mediaListID: string): EventEmitter<number[]>
  {
    let onDone = new EventEmitter<number[]>();

    if (!application.isAdmin)
    {
      onDone.emit([]);
      return onDone;
    }

    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'medialist/' + _mediaListID + '/roles'))
      .subscribe(result =>
      {
        var data = result.data as number[];
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Sets the roles of a medialist.
   * @param _mediaListID
   */
  public SetRolesOfMediaList(_mediaListID: string, _roleIDs: number[]): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    this.http.post<IReturnState>(evision5.buildApi(this.baseUrl, 'medialist/' + _mediaListID + '/roles'), _roleIDs)
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Saves a medialist and the role-assignments.
   * @param _mediaList
   * @param _mediaListID
   * @param _roleIDs
   */
  public Save(_mediaList: IMediaListExtended, _mediaListID: string, _roleIDs: number[]): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var url = _mediaListID === null
      ? evision5.buildApi(this.baseUrl, 'medialist')
      : evision5.buildApi(this.baseUrl, 'medialist/' + _mediaListID);

    // Save medialist
    this.http
      .post<IReturnState>(url, _mediaList)
      .subscribe(
        _resultMediaList =>
        {
          if (_resultMediaList.success && application.isAdmin)
          {
            // Save role-assignment
            var tmp = _resultMediaList.data as IMediaListExtended;
            this.SetRolesOfMediaList(tmp.mediaListID, _roleIDs).subscribe((_resultRoles: IReturnState) =>
            {
              _resultRoles.data = tmp;
              onDone.emit(_resultRoles);
            });
          }
          else
          {
            onDone.emit(_resultMediaList);
          }
        },
        error => console.error(error));

    return onDone;
  };

  ///**
  // * Checks if the user can be deleted.
  // * 0 = yes
  // * 1 = no, is admin.
  // * 2 = no, has still apiKeys
  // * @param _user
  // */
  //public CanDelete(_user: IUserToEdit): EventEmitter<number>
  //{
  //  let onDone = new EventEmitter<number>();

  //  setTimeout(() =>
  //  {
  //    if (this.IsAdmin(_user))
  //    {
  //      onDone.emit(1);
  //    }
  //    else
  //    {
  //      this.apiKeyService.GetApiKeysByUser(_user.userID).subscribe((_apiKeys: IUserApiKey[]) =>
  //      {
  //        if (_apiKeys.length > 0)
  //        {
  //          onDone.emit(2);
  //        }
  //        else
  //        {
  //          onDone.emit(0);
  //        }
  //      });
  //    }
  //  });

  //  return onDone;
  //};

  ///**
  // * Gets all available users for a selection-usecase.
  // */
  //public GetUsersForSelection(): EventEmitter<IUserToEdit[]>
  //{
  //  let onDone = new EventEmitter<IUserToEdit[]>();

  //  this.GetUsers().subscribe((_user: IUserToEdit[]) =>
  //  {
  //    var tmp = [];

  //    var empty = new UserToEdit();
  //    empty.userID = null;
  //    empty.name = this.application.getRawText('common.noselection.msg');
  //    tmp.push(empty);

  //    for (var i = 0; i < _user.length; i++)
  //    {
  //      tmp.push(_user[i]);
  //    }

  //    onDone.emit(tmp);
  //  });

  //  return onDone;
  //};



  ///**
  // * Deletes a user.
  // * @param _userId
  // */
  //public DeleteUser(_userId: number): EventEmitter<IReturnState>
  //{
  //  let onDone = new EventEmitter<IReturnState>();

  //  this.http
  //    .delete<IReturnState>(application.buildApi(this.baseUrl, 'user/' + _userId), {})
  //    .subscribe(
  //      _result =>
  //      {
  //        onDone.emit(_result as IReturnState);
  //      });

  //  return onDone;
  //};

  ///**
  // * Gets a user.
  // * @param _userId
  // */
  //public LoadUser(_userId: number): EventEmitter<IUserToEdit>
  //{
  //  let onDone = new EventEmitter<IUserToEdit>();

  //  this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'user/' + _userId))
  //    .subscribe(result =>
  //    {
  //      var data = result.data as IUserToEdit;
  //      onDone.emit(data);
  //    }, error => console.error(error));

  //  return onDone;
  //};

  ///**
  // * Saves a user.
  // * @param _user
  // */
  //public SaveUser(_user: IUserToEdit): EventEmitter<IReturnState>
  //{
  //  let onDone = new EventEmitter<IReturnState>();

  //  var url = _user.userID > 0
  //    ? application.buildApi(this.baseUrl, 'user/' + _user.userID)
  //    : application.buildApi(this.baseUrl, 'user');

  //  this.http.post<IReturnState>(url, _user)
  //    .subscribe(result =>
  //    {
  //      var data = result as IReturnState;
  //      onDone.emit(data);
  //    }, error => console.error(error));

  //  return onDone;
  //};

  ///**
  // * Sends an email to the user to reset the password.
  // * @param _user
  // */
  //public SendResetPasswordLink(_userID: number): EventEmitter<IReturnState>
  //{
  //  let onDone = new EventEmitter<IReturnState>();

  //  this.http.post<IReturnState>(application.buildApi(this.baseUrl, 'security/' + _userID + '/sendpasswordreset'), null)
  //    .subscribe(result =>
  //    {
  //      var data = result as IReturnState;
  //      onDone.emit(data);
  //    }, error => console.error(error));

  //  return onDone;
  //};
}
