<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="htmlTextID" localize="pwp.htmltext.single.header.edit"></h1>
  <h1 *ngIf="!htmlTextID||htmlTextID===0" localize="pwp.htmltext.single.header.new"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button  localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.cancel.msg" (click)="close()">
      <icon icon="ArrowLeft"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container" fxLayout="column">

    <mat-form-field>
      <input matInput [placeholder]="textModule"
             [(ngModel)]="htmlText.moduleDisplayName"
             disabled>
    </mat-form-field>

    <div class="b-form-container wrapper">
      <span localize="$:pwp.htmltext.single.form.html.title"></span>

      <!-- plugins: image media -->
      <editor apiKey="r6otx01d5e9090z66libgivrhq0d1t1zno8zb2brn7mjp3ak"
              [(ngModel)]="html"
              [init]="editorSettings"></editor>

    </div>

  </div>

</div>
