<div class="component component-container"
     fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1><span localize="scene-jobs.summary.header"></span>&nbsp;[{{ scene?.jobName }}]</h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:scene-jobs.summary.toolbar.new.title" (click)="edit()">
      <icon icon="Pen"></icon>
    </button>
    <button mat-button localize="title:common.back.msg" routerLink="/scenes">
      <icon icon="ArrowLeft"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!mediaJobs"><em localize="common.loadingdata.msg"></em></p>

    <table mat-table [dataSource]="mediaJobs">

      <ng-container matColumnDef="preview">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="cell-preview ">
          <span class="preview" [ngStyle]="{'background-image': 'url(\'' + getMediaUrlPath(element.mediaListFirstMediaUrl) + '\')'}"></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef localize="scene-jobs.summary.grid.name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.jobName }}</td>
      </ng-container>

      <ng-container matColumnDef="device">
        <th mat-header-cell *matHeaderCellDef localize="scene-jobs.summary.grid.device.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.deviceName }}</td>
      </ng-container>

      <ng-container matColumnDef="medialist">
        <th mat-header-cell *matHeaderCellDef localize="scene-jobs.summary.grid.medialist.title"></th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/medialists', element.mediaListID, 'mediaactivities']"
             localize="title:scene-jobs.summary.grid.medialist.nav.title">
            {{ element.listName }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons one-button">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button (click)="delete(element.mediaJobID)" localize="title:scene-jobs.summary.grid.edit.delete.title">
                <icon icon="Trash"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected':mediaJobID===row.mediaJobID}"></tr>

    </table>
  </div>

</div>
