import { Component, Inject, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FileExplorerComponent } from '../fileexplorer/fileexplorer.component';

import { IMediaActivity } from '../core/interfaces/IMediaActivity';
import MediaActivity from '../core/models/MediaActivity';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';
import { IMediaSettingsCollection } from '../core/interfaces/IMediaSettingsCollection';
import MediaSettingsCollection from '../core/models/MediaSettingsCollection';
import { KeyHandler, UiKeyHandleService } from '../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { SettingsService } from '../core/services/data/settings.service';
import { UtilsService } from '../../../../app-pwp/src/app/core/services/common/utils.service';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IUrlDetails } from '../core/interfaces/IUrlDetails';
import File from '../core/models/File';
import { MediaLayoutsService } from '../core/services/data/medialayouts.service';
import { IMediaLayout } from '../core/interfaces/IMediaLayout';
import { MediaActivityRuleComponent } from '../mediaactivity-rule/mediaactivity-rule.component';

@Component({
  selector: 'app-mediaactivity-single',
  templateUrl: './mediaactivity-single.component.html',
  styleUrls: ['./mediaactivity-single.component.css']
})

export class MediaActivitySingleComponent {
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public mediaActivity: IMediaActivity = new MediaActivity();
  public mediaLayouts: IMediaLayout[] = [];

  public mediaListID: string = null;
  public mediaActivityID: string = null;
  public onClose = new EventEmitter();

  private pendingSave: boolean = false;
  private pendingMeta: boolean = false;

  private initiated: boolean = false;

  private screenCaptureData: string = null;

  private DEFAULT_DURATION_SEC: number = 60;

  public textTitle: string = application.getRawText('mediaactivity.single.form.title.title');
  public textUrl: string = application.getRawText('mediaactivity.single.form.url.title');
  public textDuration: string = application.getRawText('mediaactivity.single.form.duration.title');
  public textSecondsPerPage: string = application.getRawText('mediaactivity.single.form.secondsperpage.title');
  public textMediaLayout: string = application.getRawText('mediaactivity.single.form.medialayout.title');

  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogFileExplorer: MatDialogRef<FileExplorerComponent>;
  private dialogActivityRule: MatDialogRef<MediaActivityRuleComponent>;

  private settings: IMediaSettingsCollection = new MediaSettingsCollection();

  public keyHandler: KeyHandler;

  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private cdref: ChangeDetectorRef,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private utilsService: UtilsService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private mediaLayoutService: MediaLayoutsService) {
    //this.security.checkForRolesByName('devices.summary');
    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save(false));

    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
    this.busyIndicator.Register(this.settingsService);

    this.mediaLayoutService.LoadAllForSelection().subscribe((_layouts) => {
      this.mediaLayouts = _layouts;
    });
  };

  public ngOnInit() {
    if (this.initiated) {
      return;
    }

    this.mediaListID = this.route.snapshot.paramMap.get('id');
    this.mediaActivityID = this.route.snapshot.paramMap.get('idActivity');
    this.initMediaActivity();

    this.initiated = true;
  };

  public initMediaActivity() {
    this.settingsService.GetSettings().subscribe((_settings: IMediaSettingsCollection) => {
      this.settings = _settings;
      this.DEFAULT_DURATION_SEC = this.settings.defaultActivityDurationSecValue;

      if (this.mediaActivityID === 'new') {
        this.mediaActivityID = null;
      }

      if (this.mediaActivityID === null) {
        this.mediaActivity = new MediaActivity();
        this.mediaActivity.mediaListID = this.mediaListID;
        this.mediaActivity.duration = this.DEFAULT_DURATION_SEC;
      }
      else {
        this.loadMediaActivity();
      }
    });
    this.initiated = true;
  };

  public onDurationChangedHandler(_duration: number) {
    if (_duration === null) {
      return;
    }

    this.mediaActivity.duration = _duration !== null
      ? Math.floor(_duration) + 1
      : this.DEFAULT_DURATION_SEC;
    this.cdref.detectChanges();
  };

  public onCaptureDoneHandler(_image: string) {
    this.screenCaptureData = _image;
  };

  private loadMediaActivity() {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediaactivity/' + this.mediaActivityID))
      .subscribe(result => {
        this.mediaActivity = result.data as IMediaActivity;
      }, error => console.error(error));
  };

  public save(_silent: boolean) {
    if (this.pendingMeta === true) {
      this.pendingSave = true;
      return;
    }

    if (!this.mediaActivity.title) {
      this.mediaActivity.title = this.mediaActivity.mediaUrl;
    }

    var screen = { value: this.screenCaptureData };
    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'files/thumbnail?mediaurl=' + encodeURIComponent(this.mediaActivity.mediaUrl)), screen)
      .subscribe(
        result => {
          if (result.success) {
          }
          else {
          }
        },
        error => console.error(error));

    var url = this.mediaActivityID === null
      ? evision5.buildApi(this.baseUrl, 'mediaactivity')
      : evision5.buildApi(this.baseUrl, 'mediaactivity/' + this.mediaActivityID);

    this.http
      .post<IReturnState>(url, this.mediaActivity)
      .subscribe(
        result => {
          if (result.success) {
            this.mediaActivity = result.data as IMediaActivity;
            this.mediaActivityID = this.mediaActivity.mediaActivityID;

            if (_silent === false) {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
              this.onClose.emit();
            }
          }
          else {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        },
        error => console.error(error));
  };

  public onUrlChange() {
    if (this.utilsService.IsUrl(this.mediaActivity.mediaUrl) === false) {
      return;
    }

    this.pendingMeta = true;

    this.http
      .get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediaactivity/metaofurl?url=' + encodeURIComponent(this.mediaActivity.mediaUrl)))
      .subscribe(_result => {
        if (_result.success) {
          var tmp = _result.data as IUrlDetails;

          if (tmp.title !== null && tmp.title !== '') {
            this.mediaActivity.title = tmp.title
          }

          if (tmp.hasDuration === true) {
            this.mediaActivity.duration = tmp.duration;
          }
        }

        this.pendingMeta = false;
        if (this.pendingSave == true) {
          this.save(false);
        }
      });
  };

  public saveAll(_files: File[]) {
    if (this.mediaActivityID != null) {
      this.save(true);
    }

    var activities: IMediaActivity[] = [];
    for (var i = 0; i < _files.length; i++) {
      var ma = new MediaActivity();
      ma.mediaListID = this.mediaListID;
      ma.mediaUrl = _files[i].path;
      ma.title = _files[i].name;
      ma.duration = this.mediaActivity.duration;
      activities.push(ma);
    }

    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediaactivities'), activities)
      .subscribe(
        result => {
          if (result.success) {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
            this.onClose.emit();
          }
          else {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        },
        error => console.error(error));
  };

  public close() {
    this.onClose.emit();
  };

  public openRuleDialog() {
    this.dialogActivityRule = this.dialog.open(MediaActivityRuleComponent, {
      autoFocus: true,
      height: '600',
      width: '600'
    });

    this.dialogActivityRule.componentInstance.mediaActivityID = this.mediaActivityID;
    this.dialogActivityRule.componentInstance.initMediaActivityRule();

    this.dialogActivityRule.componentInstance.onClose.subscribe(() => {
      this.dialogActivityRule.close();
    });
  }

  public openFileExplorer() {
    this.dialogFileExplorer = this.dialog.open(FileExplorerComponent, {
      autoFocus: true,
      height: '98%',
      width: '100%'
    });
    this.dialogFileExplorer.componentInstance.enableMediaActions();
    this.dialogFileExplorer.componentInstance.initExplorer();
    this.dialogFileExplorer.componentInstance.onClose.subscribe(() => {
      this.dialogFileExplorer.close();
    });
    this.dialogFileExplorer.componentInstance.onSelected.subscribe(() => {
      var file = this.dialogFileExplorer.componentInstance.selectedFile;
      this.mediaActivity.title = file.name;
      this.mediaActivity.mediaUrl = file.path;
      this.dialogFileExplorer.close();
    });
    this.dialogFileExplorer.componentInstance.onAddAllFiles.subscribe(() => {
      this.addSelectedFiles(true);
    });
    this.dialogFileExplorer.componentInstance.onAddSelectedFiles.subscribe(() => {
      var files = this.dialogFileExplorer.componentInstance.selectedFiles;
      if (files.length == 1) {
        var file = files[0];
        this.mediaActivity.title = file.name;
        this.mediaActivity.mediaUrl = file.path;
        this.dialogFileExplorer.close();
      }
      else {
        this.addSelectedFiles(false);
      }
    });
  };

  public addSelectedFiles(_all: boolean) {
    var _this = this;
    var msg = _all
      ? 'filexplorer.toolbar.applyall.question.msg'
      : 'filexplorer.toolbar.applyselection.question.msg';

    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.note.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText(msg);
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      var tmp = this.dialogFileExplorer.componentInstance.selectedFiles;
      this.saveAll(tmp);
      this.dialogFileExplorer.close();
    });
  };

  public onWidgetUrlChanged($event: string) {
    this.mediaActivity.mediaUrl = $event;
  }

  public isVideo() {

    if (!this.mediaActivity || !this.mediaActivity.mediaUrl) {
      return false;
    }

    var isVideo = false,
      formats = this.settings.videoFormatsValue !== null && this.settings.videoFormatsValue !== undefined
        ? this.settings.videoFormatsValue
        : [];
    for (var i = 0; i < formats.length; i++) {
      if (this.mediaActivity.mediaUrl.endsWith(formats[i])) {
        isVideo = true;
        break;
      }
    }

    return isVideo;
  }
}
