import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { HttpWorkerFactoryService, HttpWorker } from './../http/httpworkerfactory.service';
import { ICanBeBusy, IsBusy } from './../busyindicator/busyindicatorfactory.service';
import { GlobalSettingsService } from './../data/globalsettings.service';
import { ApiKeysService } from './../data/apikeys.service';
import { CrudService, CrudServiceFactory } from './../data/common/crud.service';
import { IGlobal } from '../../interfaces/IGlobal';
import { IGlobalSettings } from '../../interfaces/IGlobalSettings';
import { IHtmlText } from '../../interfaces/IHtmlText';
import { IReturnState } from '../../interfaces/IReturnState';
import { application } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class HtmlTextsService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();

  private globalSettings: IGlobalSettings = null;

  private http: HttpWorker;
  private crudService: CrudService<IHtmlText, number>;

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private globalSettingsService: GlobalSettingsService,
    private apiKeyService: ApiKeysService,
    private crudServiceFactory: CrudServiceFactory)
  {
    this.crudService = this.crudServiceFactory.Get<IHtmlText, number>('htmltexts', 'htmltext', application);
    this.http = this.httpWorkerFactory.GetWorker();

    this.IsBusy.PassThrough(this.crudService);
    this.IsBusy.PassThrough(this.http);
    this.IsBusy.PassThrough(this.globalSettingsService);
    this.IsBusy.PassThrough(this.apiKeyService);

    //this.globalSettingsService.GetSettings().subscribe(_state =>
    //{
    //  this.globalSettings = _state;
    //});
  };

  /**
   * Gets all htmlTexts.
   */
  public GetHtmlTexts(_moduleID: number): EventEmitter<IHtmlText[]>
  {
    let onDone = new EventEmitter<IHtmlText[]>();

    var url = application.buildApi(this.baseUrl, 'htmltexts' + ((_moduleID > 0) ? '/' + _moduleID : ''));
    this.http.get<IReturnState>(url)
      .subscribe(result =>
      {
        var data = result.data as IHtmlText[];
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Gets an htmlText.
  */
  public GetHtmlText(_htmlTextID: number): EventEmitter<IHtmlText>
  {
    return this.crudService.Get(_htmlTextID);
  };

  /**
   * Deletes an htmlText.
   * @param _htmlTextID
   */
  public DeleteHtmlText(_htmlTextID: number): EventEmitter<IReturnState>
  {
    return this.crudService.Delete(_htmlTextID);
  };

  /**
   * Saves an htmlText.
   * @param _htmlText
   */
  public SaveHtmlText(_htmlText: IHtmlText): EventEmitter<IReturnState>
  {
    var id = _htmlText.moduleID > 0
      ? _htmlText.moduleID
      : null;
    return this.crudService.Save(_htmlText, id);
  };
}
