<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="pwp.imageselector.header"></h1>

  <mat-toolbar>
    <ngx-file-drop [dropZoneLabel]="statusDragnDrop"
               (onFileDrop)="dropped($event)">
    </ngx-file-drop>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button  localize="title:common.cancel.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container">

      <img [src]="selectedFileData"
           *ngIf="selectedFileData!==null"
           class="preview" />

    </div>

  </div>

</div>
