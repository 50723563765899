<div fxLayout="column" fxLayoutAlign="space-around center" class="login">

  <div [ngClass]="{'front':(mediaFrames[0].state==='visible'||mediaFrames[0].state==='fadein')&&mediaFrames[1].state!=='fadein', 'show':mediaFrames[0].state==='fadein', 'visible':mediaFrames[0].state==='visible', 'hidden':mediaFrames[0].state==='hidden'}"
       class="presenter-frame fade f0"
       *ngIf="mediaFrames[0].isActive">
    <presenter [secondsPerPage]="mediaFrames[0].secondsPerPage"
               [url]="urls[0]"
               [fullscreen]="fullscreen"
               [avoidFullscreenInWindowsMedia]="settings.avoidFullscreenInWindowsMediaValue"
               [directImage]="settings.directImageValue"
               [crossFadingDurationMs]="settings.crossFadingDurationMsValue"
               [muted]="muted"></presenter>
  </div>
  <div [ngClass]="{'front':(mediaFrames[1].state==='visible'||mediaFrames[1].state==='fadein')&&mediaFrames[0].state!=='fadein', 'show':mediaFrames[1].state==='fadein', 'visible':mediaFrames[1].state==='visible', 'hidden':mediaFrames[1].state==='hidden'}"
       class="presenter-frame fade f1"
       *ngIf="mediaFrames[1].isActive">
    <presenter [secondsPerPage]="mediaFrames[1].secondsPerPage"
               [url]="urls[1]"
               [fullscreen]="fullscreen"
               [avoidFullscreenInWindowsMedia]="settings.avoidFullscreenInWindowsMediaValue"
               [directImage]="settings.directImageValue"
               [crossFadingDurationMs]="settings.crossFadingDurationMsValue"
               [muted]="muted"></presenter>
  </div>

</div>
