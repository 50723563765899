import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FileExplorerComponent } from '../../fileexplorer/fileexplorer.component';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../../app-pwp/src/app/dialog/dialog.component';
import { KeyHandler, UiKeyHandleService } from '../../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../../app-pwp/src/app/core/services/common/security.service';
import { SettingsService } from '../../core/services/data/settings.service';
import { FileExplorerService } from '../../core/services/data/fileexplorer.service';
import { IReturnState } from '../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { application } from '../../../../../app-pwp/src/app/core/globals';

@Component({
  selector: 'app-editdirectory',
  templateUrl: './editdirectory.component.html',
  styleUrls: ['./editdirectory.component.css']
})

export class EditDirectoryComponent
{
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public path: string;
  public name: string;

  public onClose = new EventEmitter();

  private initiated: boolean = false;

  public textPath: string = application.getRawText('filexplorer.editdirectory.form.path.title');
  public textName: string = application.getRawText('filexplorer.editdirectory.form.name.title');

  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogFileExplorer: MatDialogRef<FileExplorerComponent>;

  public keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private fileExplorerService: FileExplorerService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService)
  {
    //this.security.checkForRolesByName('devices.summary');
    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());

    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.fileExplorerService);
    this.busyIndicator.Register(this.settingsService);
  };

  ngOnInit()
  {
    if (this.initiated)
    {
      return;
    }

    this.init('/');
  };

  init(_path: string)
  {
    this.path = _path;
    this.initiated = true;
  };

  public save()
  {
    this.fileExplorerService.CreateDirectory(this.path, this.name)
      .subscribe((_result: IReturnState) =>
      {
        if (_result.success && _result.stateID === 2)
        {
          this.snackBarRef = this.snackBar.open(application.getRawText('filexplorer.editdirectory.error.alreadyexists.msg'), application.getRawText('common.close.msg'));
          this.close();
        }
        else if (_result.success)
        {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
          this.close();
        }
        else
        {
          this.snackBarRef = this.snackBar.open(application.getRawText('filexplorer.editdirectory.error.msg'), application.getRawText('common.close.msg'));
        }
      });
  };

  public close()
  {
    this.onClose.emit();
  };
}
