import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IsBusy, ICanBeBusy } from '../../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { GlobalSettingsService } from '../../../../../../app-pwp/src/app/core/services/data/globalsettings.service';
import { IReturnState } from '../../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { application } from '../../../../../../app-pwp/src/app/core/globals';
import { evision5 } from '../../globals';
import { IMediaLayout } from '../../interfaces/IMediaLayout';
import MediaLayout from '../../models/MediaLayout';


@Injectable({
  providedIn: 'root',
})

export class MediaLayoutsService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();

  private http: HttpWorker;

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private globalSettingsService: GlobalSettingsService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
    this.IsBusy.PassThrough(this.globalSettingsService);
  }

  /**
   * Gets all mediaLayouts for a selection-situation like dropdown.
   */
  public LoadAllForSelection(): EventEmitter<IMediaLayout[]>
  {
    let onDone = new EventEmitter<IMediaLayout[]>();
    this.LoadAll().subscribe((_layouts) =>
    {
      var tmp: IMediaLayout[] = [];

      var empty = new MediaLayout();
      empty.name = application.getRawText('common.noselection.msg');
      empty.mediaLayoutID = null;
      tmp.push(empty);

      for (var i = 0; i < _layouts.length; i++)
      {
        tmp.push(_layouts[i]);
      }

      onDone.emit(tmp);

    });
    return onDone;
  };

  /**
   * Gets all medialayouts.
   */
  public LoadAll(): EventEmitter<IMediaLayout[]>
  {
    let onDone = new EventEmitter<IMediaLayout[]>();

    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'medialayouts'))
      .subscribe(result =>
      {
        var data = result.data as IMediaLayout[];
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Saves a mediaLayout.
   */
  public Save(_mediaList: IMediaLayout, _mediaLayoutID: string): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var url = _mediaLayoutID === null
      ? evision5.buildApi(this.baseUrl, 'medialayout')
      : evision5.buildApi(this.baseUrl, 'medialayout/' + _mediaLayoutID);

    // Save medialist
    this.http
      .post<IReturnState>(url, _mediaList)
      .subscribe(
        _resultMediaLayout =>
        {
          onDone.emit(_resultMediaLayout);
        },
        error => console.error(error));

    return onDone;
  };

  /**
   * Deletes a mediaLayout.
   * @param _mediaLayoutID
   */
  public Delete(_mediaLayoutID: string): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    this.http
      .delete<IReturnState>(evision5.buildApi(this.baseUrl, 'medialayout/' + _mediaLayoutID), {})
      .subscribe(
        _result =>
        {
          onDone.emit(_result as IReturnState);
        });

    return onDone;
  };

  /**
   * Gets a mediaLayout.
   * @param _medialayoutID
   */
  public Load(_medialayoutID: string): EventEmitter<IMediaLayout>
  {
    let onDone = new EventEmitter<IMediaLayout>();

    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'medialayout/' + _medialayoutID))
      .subscribe(result =>
      {
        var data = result.data as IMediaLayout;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

}
