import { IMediaListRoleSelectable } from '../interfaces/IMediaListRoleSelectable';

export class MediaListRoleSelectable implements IMediaListRoleSelectable
{
  id: string;
  role: number;
  roleName: string;
  itemID: string;
  selected: boolean;
}
