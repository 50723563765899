import { IMediaListExtended } from '../interfaces/IMediaListExtended';

export default class MediaListExtended implements IMediaListExtended
{
  mediaListID: string;
  durationSum: number;
  listName: string;
  looped: boolean;
  createDate: string;
  linkedList: boolean;
  elementCount: number = 0;
  mediaTypes: string = null;
  durationSumFormatted: string = null;
  firstMediaUrl: string = null;
}
