export default class File 
{
  extension: string;
  name: string;
  path: string;
  date: string;
  url: string;
  selected: boolean;
  filesize: number;
}
