import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditorModule } from '@tinymce/tinymce-angular';
import { LayoutModule } from '@angular/cdk/layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EventItemsSummaryComponent } from './eventitems-summary/eventitems-summary.component';
import { EventItemSingleComponent } from './eventitem-single/eventitem-single.component';
import { UsersSummaryComponent } from './users-summary/users-summary.component';
import { UserSingleComponent } from './user-single/user-single.component';
import { RolesSummaryComponent } from './roles-summary/roles-summary.component';
import { RoleSingleComponent } from './role-single/role-single.component';
import { ApiKeysSummaryComponent } from './apikeys-summary/apikeys-summary.component';
import { ApiKeySingleComponent } from './apikey-single/apikey-single.component';
import { HtmlTextsSummaryComponent } from './htmltexts-summary/htmltexts-summary.component';
import { HtmlTextSingleComponent } from './htmltext-single/htmltext-single.component';
import { ImageSelectorComponent } from './image-selector/image-selector.component';
import { BusyIndicatorModule } from './busy-indicator/busy-indicator.module';
import { LocalizeModule } from './localize/localize.module';
import { RoleFunctionModule } from './rolefunction/rolefunction.module';
import { FormsModule } from '@angular/forms';
import { ApiKeySingleModule } from './apikey-single/apikey-single.module';
import { ApiKeysSummaryModule } from './apikeys-summary/apikeys-summary.module';
import { ArticlesSummaryComponent } from './articles-summary/articles-summary.component';
import { ArticleSingleComponent } from './article-single/article-single.component';
import { StopPropagationModule } from './stop-propagation/stop-propagation.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconComponent, IconModule } from './icon/icon.component';
import { SlackModule } from './slack/slack.module';
import { NumericInputModule } from './numeric-input/numeric-input.module';

@NgModule({
  declarations: [
    AppComponent,

    ArticlesSummaryComponent,
    ArticleSingleComponent,
    EventItemsSummaryComponent,
    EventItemSingleComponent,
    UsersSummaryComponent,
    UserSingleComponent,
    RolesSummaryComponent,
    RoleSingleComponent,
    HtmlTextsSummaryComponent,
    HtmlTextSingleComponent,

    ImageSelectorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    EditorModule,
    FontAwesomeModule,

    IconModule,
    AppRoutingModule,
    RoleFunctionModule,
    LocalizeModule,
    BusyIndicatorModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatCheckboxModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDialogModule,
    MatListModule,
    MatSliderModule,
    MatExpansionModule,
    MatIconModule,
    NgxFileDropModule,
    NgxMaterialTimepickerModule,
    DragDropModule,
    MatBadgeModule,
    MatSortModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    LayoutModule
  ],
  exports: [],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

@NgModule({
  id: 'app-pwp',
  exports: [
    ApiKeySingleModule,
    ApiKeysSummaryModule,
    SlackModule,
    RoleFunctionModule,
    LocalizeModule,
    StopPropagationModule,
    BusyIndicatorModule,
    IconModule,
    NumericInputModule
  ]
})
export class AppPwp
{
  static forRoot(): ModuleWithProviders<AppModule>
  {
    return {
      ngModule: AppModule,
      providers: []
    }
  }
}
