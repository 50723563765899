import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { application } from './../core/globals';
import { ActivatedRoute, Router } from '@angular/router';

import { UiKeyHandleService, KeyHandler } from './../core/services/common/uikeyhandle.service';
import { SecurityService } from './../core/services/common/security.service';
import { DateTimeService } from './../core/services/common/datetime.service';
import { HttpWorkerFactoryService, HttpWorker } from './../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { UsersService } from './../core/services/data/users.service';
import { RolesService } from './../core/services/data/roles.service';
import UserToEdit from './../core/models/UserToEdit';
import { IUserToEdit } from '../core/interfaces/IUserToEdit';
import { IRoleSelectable } from '../core/interfaces/IRoleSelectable';
import { IRole } from '../core/interfaces/IRole';

@Component({
  selector: 'app-user-single',
  templateUrl: './user-single.component.html',
  styleUrls: ['./user-single.component.css']
})

export class UserSingleComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public userID: number = null;
  public user: IUserToEdit = new UserToEdit();

  public roles: IRoleSelectable[] = [];
  private userRoles: IRole[] = [];

  private initiated: boolean = false;

  public textName: string = application.getRawText('pwp.user.single.form.name.title');
  public textEmail: string = application.getRawText('pwp.user.single.form.email.title');
  public textPassword: string = application.getRawText('pwp.user.single.form.password.title');
  public textCreationDate: string = application.getRawText('pwp.user.single.form.creation-date.title');
  public textLogInDate: string = application.getRawText('pwp.user.single.form.login-date.title');
  public textPasswordChangeDate: string = application.getRawText('pwp.user.single.form.password-change-date.title');
  public textExpirationDate: string = application.getRawText('pwp.user.single.form.expiration-date.title');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  public keyHandler: KeyHandler;
  private lastName: string = null;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private dateTimeService: DateTimeService,
    public userService: UsersService,
    private roleService: RolesService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
  };

  private ngOnInit() {
    if (this.initiated) {
      return;
    }

    var tmpID = parseInt(this.route.snapshot.paramMap.get('id'));
    this.userID = tmpID > 0 ? tmpID : null;
    this.initUser();
  };

  public initUser() {
    if (this.initiated) {
      return;
    }

    if (!(this.userID > 0)) {
      this.userID = null;
      this.user = new UserToEdit();
    }
    else {
      this.loadUser();
    }

    this.loadRoles();

    this.initiated = true;
  };

  public onNameChangeHandler() {
    var isAdmin = this.userService.IsAdmin(this.user);
    if (isAdmin) {
      this.user.name = this.lastName;
      this.snackBarRef = this.snackBar.open(application.getRawText('pwp.user.single.error.cannotchangetoadmin.msg'), application.getRawText('common.close.msg'));
    }
    else {
      this.lastName = this.user.name;
    }
  };

  public onNeverExpiresChange() {
    if (this.user.userNeverExpires === false) {
      if (this.user.expirationDate === null) {
        this.user.expirationDate = this.dateTimeService.Now();
      }
    }
    else {
      this.user.expirationDate = null;
    }
  };

  private loadRoles() {
    this.roleService.GetRoles().subscribe((_result: any) => {
      this.roles = _result;
    });
  };

  private loadUser() {
    this.userService.LoadUser(this.userID).subscribe(_userToEdit => {
      this.user = _userToEdit as IUserToEdit;
      this.lastName = this.user.name;

      if (this.user.userNeverExpires) {
        this.user.expirationDate = null;
      }

      this.roleService.GetRolesOfUser(this.user.userID).subscribe(_result => {
        var tmp = _result;
        for (var i = 0; i < this.roles.length; i++) {
          this.roles[i].selected = false;
          for (var r = 0; r < tmp.length; r++) {
            if (this.roles[i].roleID === tmp[r].roleID) {
              this.roles[i].selected = true;
              break;
            }
          }
        }
      });
    });
  };

  public save() {
    if (this.user.email === null || this.user.email === undefined || this.user.email.length === 0) {
      this.snackBarRef = this.snackBar.open(application.getRawText('pwp.user.single.save.error.emailempty.msg'), application.getRawText('common.close.msg'));
      return;
    }

    this.userService.SaveUser(this.user).subscribe(_returnState => {
      if (_returnState.success) {
        this.user = _returnState.data as IUserToEdit;
        this.userID = this.user.userID;

        var isAdmin = this.userService.IsAdmin(this.user);
        if (isAdmin === false) {
          this.roleService.UpdateRoles(this.user.userID, this.roles).subscribe(_result => {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
            this.close();
          });
        }
        else {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
        }
      }
      else if (_returnState.stateID === 99) {
        this.snackBarRef = this.snackBar.open(application.getRawText('pwp.user.single.save.error.emailexists.msg'), application.getRawText('common.close.msg'));
      }
      else if (_returnState.stateID === 98) {
        this.snackBarRef = this.snackBar.open(application.getRawText('pwp.user.single.save.error.nameexists.msg'), application.getRawText('common.close.msg'));
      }
      else {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  public close() {
    this.onClose.emit();
  };

  public sendResetPasswordLink() {
    this.userService.SendResetPasswordLink(this.user.userID).subscribe(_returnState => {
      if (_returnState.success) {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.emailsent.msg'), application.getRawText('common.close.msg'));
      }
      else {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });

  };

  public sendInvitationLink() {
    this.userService.SendInvitationLink(this.user.userID).subscribe(_returnState => {
      if (_returnState.success) {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.emailsent.msg'), application.getRawText('common.close.msg'));
      }
      else {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });

  };

}
