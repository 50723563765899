import { IArticle } from '../interfaces/IArticle';

export default class Article implements IArticle
{
  id: number;
  moduleID: number;
  startDate: string;
  endDate: string;
  title: string;
  shortDescription: string;
  text: string;
  smallPic: string;
  articleDate: string;
  author: string;
  changedTime: string;
  deleted: boolean;
  activeAuthor: string;
  released: boolean;
  imageID: number;
  thumbID: number;
  itemId: string;
  redirectType: number;
  redirectUrl: string;
  linkTarget: number;
}
