import { HttpClient } from '@angular/common/http';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { application } from '../globals';
import { HttpErrorService } from './httperror.service';
import { DialogComponent } from './dialog/dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IGlobal } from '../interfaces/IGlobal';
import { IState } from '../interfaces/IState';

@Component({
  selector: 'app-webplayer-root',
  templateUrl: './app-webplayer.component.html',
  styleUrls: ['./app-webplayer.component.css']
})

export class AppWebPlayerComponent 
{
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private http401Handled: boolean = false;

  private readonly MODE_LOGIN = 0;
  private readonly MODE_LOADING = 1;
  private readonly MODE_APPLICATION = 2;

  /*
   * 0 - login
   * 1 - loading
   * 2 - application
   */
  public mode: number = 0;
  private isAuthenticated: boolean = false;

  //public showLogin: boolean = true;
  public isLocal: boolean = false;
  public isInit: boolean = false;
  public isSessionLost: boolean = false;

  private application: IGlobal = application;

  private title = 'app';
  private state: IState;

  private stateLoadingIsPending: boolean = false;

  constructor(private http: HttpClient,
    private httpErrorService: HttpErrorService,
    @Inject('BASE_URL') private baseUrl: string,
    @Inject(LOCALE_ID) private locale: string,
    private dialog: MatDialog)
  {
    // Neither texts nor authentication-check is required.
    this.doStateCheck();
  };

  ngOnInit()
  {
    this.httpErrorService.http401.asObservable().subscribe(values =>
    {
      if (values === true)
      {
        this.stateLoadingIsPending = false;
        this.isAuthenticated = false;
        this.setMode();

        //if (this.http401Handled === false)
        //{
        //  this.isSessionLost = true;
        //  this.http401Handled = true;
        //  this.dialogGeneric = this.dialog.open(DialogComponent, {
        //    autoFocus: true,
        //    height: '250px',
        //    width: '550px'
        //  });
        //  this.dialogGeneric.componentInstance.options.actionYes = true;
        //  this.dialogGeneric.componentInstance.options.actionNo = true;
        //  this.dialogGeneric.componentInstance.options.title = 'Warning';
        //  this.dialogGeneric.componentInstance.options.message = 'Your session expired. You need to sign in to continue working. Do you want to be redirected to the login page?';
        //  this.dialogGeneric.componentInstance.onAction.subscribe((_action) =>
        //  {
        //    this.http401Handled = false;
        //    if (_action.action === 'no')
        //    {

        //    }
        //    else
        //    {
        //      this.http
        //        .get<IState>(this.baseUrl + 'api/pwp/security/logout')
        //        .subscribe(result =>
        //        {
        //          this.onStateChangedHandler(result);
        //          application.technicalRoleNames = null;
        //        }, error => console.error(error));
        //    }
        //  });
        //}
      }
    });
  }

  private doStateCheck()
  {
    this.stateLoadingIsPending = true;
    this.setMode();

    this.http
      .get<IState>(this.baseUrl + 'api/pwp/security/state')
      .subscribe(result =>
      {
        this.application.version = result.version;
        this.state = result;
        this.isLocal = this.state.isLocal === true;
        this.isInit = this.state.isInit === true;

        this.isAuthenticated = this.state.value === true;

        if (this.state.value === true)
        {
          application.technicalRoleNames = this.state.technicalRoleNames;
          application.user = this.state.userName;
        }

        this.stateLoadingIsPending = false;
        this.setMode();

      }, error => console.error(error));
  };

  private onStateChangedHandler(event: IState)
  {
    this.state = event;
    this.isAuthenticated =
      ((this.state.action === 1 || this.state.action === 4) &&
        this.state.value === true);
    this.isSessionLost = this.isAuthenticated === false;

    this.setMode();
  };

  private setMode()
  {
    if (this.stateLoadingIsPending)
    {
      this.mode = this.MODE_LOADING;
    }
    else if (this.isAuthenticated === true)
    {
      this.mode = this.MODE_APPLICATION;
    }
    else
    {
      this.mode = this.MODE_LOGIN;
    }
  };
}
