<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="userID" localize="pwp.user.single.header.edit"></h1>
  <h1 *ngIf="!userID||userID===0" localize="pwp.user.single.header.new"></h1>

  <mat-toolbar>
    <button mat-button localize="$:pwp.user.single.toolbar.resetpassword.txt" (click)="sendResetPasswordLink()" class="buttonsendpassword"></button>
    <button mat-button localize="$:pwp.user.single.toolbar.invitation.txt" (click)="sendInvitationLink()" class="buttonsendinvitation"></button>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.cancel.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container">

      <mat-form-field>
        <input matInput [placeholder]="textName"
               (change)="onNameChangeHandler()"
               [(ngModel)]="user.name"
               [disabled]="this.userService.IsAdmin(this.user)">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textEmail" [(ngModel)]="user.email">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textPassword" type="password" [(ngModel)]="user.password">
      </mat-form-field>

      <mat-checkbox [(ngModel)]="user.userNeverExpires"
                    (change)="onNeverExpiresChange()">
        <span localize="$:pwp.user.single.form.never-expires.title"></span>
      </mat-checkbox>

      <mat-form-field>
        <input matInput
               [matDatepicker]="expirationDate"
               [placeholder]="textExpirationDate"
               [(ngModel)]="user.expirationDate">
        <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
        <mat-datepicker #expirationDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <input matInput
               disabled="disabled"
               [matDatepicker]="userLogIn"
               [placeholder]="textLogInDate"
               [(ngModel)]="user.lastLogIn">
        <mat-datepicker-toggle matSuffix [for]="userLogIn"></mat-datepicker-toggle>
        <mat-datepicker #userLogIn></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <input matInput
               disabled="disabled"
               [matDatepicker]="passwordChangeDate"
               [placeholder]="textPasswordChangeDate"
               [(ngModel)]="user.passwordChangeDate">
        <mat-datepicker-toggle matSuffix [for]="passwordChangeDate"></mat-datepicker-toggle>
        <mat-datepicker #passwordChangeDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <input matInput
               disabled="disabled"
               [matDatepicker]="creationDate"
               [placeholder]="textCreationDate"
               [(ngModel)]="user.creationDate">
        <mat-datepicker-toggle matSuffix [for]="creationDate"></mat-datepicker-toggle>
        <mat-datepicker #creationDate></mat-datepicker>
      </mat-form-field>

    </div>
    <div fxFlex="250px" class="b-form-container">

      <p>
        <span localize="pwp.user.single.roles-assignment.title"></span>
      </p>

      <ul class="roles">
        <li class="role" *ngFor="let r of roles">
          <mat-checkbox [(ngModel)]="r.selected" [disabled]="this.userService.IsAdmin(this.user)">{{ r.roleName }}</mat-checkbox>
        </li>
      </ul>

    </div>

  </div>
