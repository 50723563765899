import { CommonModule } from '@angular/common';
import { Directive, ElementRef, HostListener, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[numericInput]'
})
export class NumbericInputDirective
{

  @Output() valueChange = new EventEmitter()
  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event)
  {
    const initalValue = this._el.nativeElement.value;
    const newValue = initalValue.replace(/[^0-9]*/g, '');
    this._el.nativeElement.value = newValue;
    this.valueChange.emit(newValue);
    if (initalValue !== this._el.nativeElement.value)
    {
      event.stopPropagation();
    }
  }
}


@NgModule({
  declarations: [
    NumbericInputDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NumbericInputDirective
  ]
})
export class NumericInputModule { }
