import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { SecurityService } from './security.service';
import { application } from '../globals';
import { IGlobal } from '../interfaces/IGlobal';

@Directive({
  selector: '[localize]'
})

/*
 *  $:mein.text,title:mein.title.text
 *  mein.text,title:mein.title.text
 * 
 **/
export class LocalizeDirective
{
  application: IGlobal = application;

  constructor(private el: ElementRef,
    private security: SecurityService)
  {
  }

  // ----

  _localize: string;

  get localize(): string { return this._localize; }

  @Input('localize')
  set localize(_value: string)
  {
    var details = _value;
    if (details)
    {
      var splitted = details.split(',');
      for (var i = 0; i < splitted.length; i++)
      {
        var assignment = splitted[i].split(':');
        if (assignment.length === 2)
        {
          if (assignment[0] === '$')
          {
            this.el.nativeElement.innerText = application.getRawText(assignment[1]);
          }
          else
          {
            this.el.nativeElement.setAttribute(assignment[0], application.getRawText(assignment[1]));
          }
        }
        else if (assignment.length === 1)
        {
          this.el.nativeElement.innerText = application.getRawText(assignment[0]);
        }
      }
    }
  }
}
