import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IGlobal } from '../../../../../../app-pwp/src/app/core/interfaces/IGlobal';
import { IGlobalEvision5 } from '../../interfaces/IGlobalEvision5';
import { IGlobalSettings } from '../../../../../../app-pwp/src/app/core/interfaces/IGlobalSettings';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { IsBusy, ICanBeBusy } from '../../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { GlobalSettingsService } from '../../../../../../app-pwp/src/app/core/services/data/globalsettings.service';
import { ApiKeysService } from '../../../../../../app-pwp/src/app/core/services/data/apikeys.service';
import { IMediaJobWithGrouping } from '../../interfaces/IMediaJobWithGrouping';
import { IReturnState } from '../../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IMediaJob } from '../../interfaces/IMediaJob';
import { IMediaJobSelectable } from '../../interfaces/IMediaJobSelectable';
import { evision5 } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class WidgetsService implements ICanBeBusy {
  public IsBusy: IsBusy = new IsBusy();

  private http: HttpWorker;
  private globalSettings: IGlobalSettings;

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private globalSettingsService: GlobalSettingsService,
    private apiKeyService: ApiKeysService) {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
    this.IsBusy.PassThrough(this.globalSettingsService);
    this.IsBusy.PassThrough(this.apiKeyService);

    this.globalSettingsService.GetSettings().subscribe(_state => {
      this.globalSettings = _state;
    });
  }

  /**
   * Gets all widgets of a certain path.
   * @param _path
   */
  public GetWidgets(_path: string): EventEmitter<IReturnState> {
    var onDone = new EventEmitter<IReturnState>();

    this.http
      .get<IReturnState>(evision5.buildApi(this.baseUrl, 'widgets?path=' + encodeURIComponent(_path)), {})
      .subscribe(
        result => {
          onDone.emit(result);
        },
        error => console.error(error));

    return onDone;
  };

  /**
   * Gets a single widgets of a certain path.
   * @param _path
   */
  public GetWidget(_path: string): EventEmitter<IReturnState> {
    var onDone = new EventEmitter<IReturnState>();

    this.http
      .get<IReturnState>(evision5.buildApi(this.baseUrl, 'widgets/single?path=' + encodeURIComponent(_path)), {})
      .subscribe(
        result => {
          onDone.emit(result);
        },
        error => console.error(error));

    return onDone;
  };
}
