import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { application } from './../core/globals';
import { ActivatedRoute, Router } from '@angular/router';

import { UiKeyHandleService, KeyHandler } from './../core/services/common/uikeyhandle.service';
import { SecurityService } from './../core/services/common/security.service';
import { DateTimeService } from './../core/services/common/datetime.service';
import { HttpWorkerFactoryService, HttpWorker } from './../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { UsersService } from './../core/services/data/users.service';
import { ApiKeysService } from './../core/services/data/apikeys.service';
import ApiKey from './../core/models/ApiKey';
import { IReturnState } from '../core/interfaces/IReturnState';
import { IUserToEdit } from '../core/interfaces/IUserToEdit';
import { IGlobal } from '../core/interfaces/IGlobal';
import { IApiKey } from '../core/interfaces/IApiKey';

@Component({
  selector: 'app-apikey-single',
  templateUrl: './apikey-single.component.html',
  styleUrls: ['./apikey-single.component.css']
})

export class ApiKeySingleComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private application: IGlobal = application;

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public apiKeyID: number = null;
  public apiKey: IApiKey = new ApiKey();
  public users: IUserToEdit[] = [];

  private initiated: boolean = false;

  public textName: string = application.getRawText('pwp.apikey.single.form.name.title');
  public textExpirationDate: string = application.getRawText('pwp.apikey.single.form.expirationDate.title');
  public textUser: string = application.getRawText('pwp.apikey.single.form.user.title');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  public keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private dateTimeService: DateTimeService,
    private userService: UsersService,
    private apiKeysService: ApiKeysService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
  };

  private ngOnInit() {
    if (this.initiated) {
      return;
    }

    var tmpID = parseInt(this.route.snapshot.paramMap.get('id'));
    this.apiKeyID = tmpID > 0 ? tmpID : null;
    this.initApiKey();
  };

  public initApiKey() {
    if (this.initiated) {
      return;
    }

    if (!(this.apiKeyID > 0)) {
      this.apiKeyID = null;
      this.apiKey = new ApiKey();
    }
    else {
      this.loadApiKey();
    }

    this.loadUsers();

    this.initiated = true;
  };

  private loadUsers() {
    this.userService.GetUsers().subscribe(_result => {
      this.users = _result;
    });
  };

  private loadApiKey() {
    this.apiKeysService.GetApiKey(this.apiKeyID).subscribe((_apiKey: IReturnState) => {
      this.apiKey = _apiKey.data as IApiKey;
    });
  };

  public save() {
    this.apiKeysService.SaveApiKey(this.apiKey).subscribe(_returnState => {
      if (_returnState.success) {
        this.apiKey = _returnState.data as IApiKey;
        this.apiKeyID = this.apiKey.id;
        this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
        this.close();
      }
      else {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  public close() {
    this.onClose.emit();
  };
}
