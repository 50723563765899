<div class="component component-container"
     fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <!--<h1 localize="pwp.slack.summary.header"></h1>-->

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button
            localize="title:pwp.slack.summary.toolbar.new.title"
            (click)="edit(null)">
      <icon icon="Plus"></icon>
    </button>
    <button mat-button
            localize="title:pwp.slack.summary.toolbar.refresh.title"
            (click)="refresh()">
      <icon icon="Sync"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <table mat-table [dataSource]="slacksDataSource">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef localize="pwp.slack.summary.grid.col.id.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef localize="pwp.slack.summary.grid.col.name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="appID">
        <th mat-header-cell *matHeaderCellDef localize="pwp.slack.summary.grid.col.appid.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.appID }}</td>
      </ng-container>

      <ng-container matColumnDef="responseDate">
        <th mat-header-cell *matHeaderCellDef localize="pwp.slack.summary.grid.col.responsedate.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.responseDate | date:'short':'UTC' }}</td>
      </ng-container>

      <ng-container matColumnDef="success">
        <th mat-header-cell *matHeaderCellDef localize="pwp.slack.summary.grid.col.success.title"></th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox [(ngModel)]="element.success" disabled="disabled"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons two-buttons">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button (click)="delete(element)" localize="title:pwp.slack.summary.grid.col.edit.delete.title">
                <icon icon="Trash"></icon>
              </button>
              <button mat-button (click)="edit(element)" localize="title:pwp.slack.summary.grid.col.edit.edit.title">
                <icon icon="Pen"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>

</div>
