import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import SystemLanguage from './../../models/SystemLanguage';
import { IGlobal } from '../../interfaces/IGlobal';
import { IReturnState } from '../../interfaces/IReturnState';
import { ILog } from '../../interfaces/ILog';
import { application } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class SystemService
{
  constructor(private http: HttpClient,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string)
  {
  }

  public GetLanguages(): EventEmitter<SystemLanguage[]>
  {
    let onDone = new EventEmitter<SystemLanguage[]>();

    this.http
      .get<IReturnState>(application.buildApi(this.baseUrl, 'system/languages'))
      .subscribe(result =>
      {
        var settings = result.data as SystemLanguage[];
        onDone.emit(settings);
      }, error => console.error(error));

    return onDone;
  };

  public GetLanguagesForSelection(): EventEmitter<SystemLanguage[]>
  {
    let onDone = new EventEmitter<SystemLanguage[]>();

    this.GetLanguages().subscribe((_result: SystemLanguage[]) =>
    {
      var tmp: SystemLanguage[] = [];

      var systemLang = new SystemLanguage();
      systemLang.culture = "default";
      systemLang.name = "System";
      tmp.push(systemLang);

      for (var i = 0; i < _result.length; i++)
      {
        tmp.push(_result[i]);
      }

      onDone.emit(tmp);
    });

    return onDone;
  };

  public GetLogs(_count: number, _levels: string[]): EventEmitter<ILog[]>
  {
    let onDone = new EventEmitter<ILog[]>();

    var levels = '';
    for (var i = 0; i < _levels.length; i++)
    {
      levels = levels + _levels[i];
      if (i < _levels.length - 1)
      {
        levels = levels + ',';
      }
    }

    this.http
      .get<IReturnState>(application.buildApi(this.baseUrl, 'system/logs?count=' + _count + '&levels=' + encodeURIComponent(levels)))
      .subscribe(result =>
      {
        var log = result.data as ILog[];
        onDone.emit(log);
      }, error => console.error(error));

    return onDone;
  };

}
