import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IGlobal } from '../../interfaces/IGlobal';

@Injectable({
  providedIn: 'root',
})

export class UiKeyHandleService {
  constructor(private router: Router) { }

  public GetKeyHandler(): KeyHandler {
    return new KeyHandler();
  };
}

export class KeyHandler {
  public OnSave = new EventEmitter();
  public OnCancel = new EventEmitter();

  private saveEnabled: boolean = true;
  private cancelEnabled: boolean = true;

  public EnableSave(_enable: boolean): KeyHandler {
    this.saveEnabled = _enable;
    return this;
  };

  public EnableCancel(_enable: boolean): KeyHandler {
    this.cancelEnabled = _enable;
    return this;
  };

  public TriggerOnKeyDown(_event) {
    this.onKeyDown(_event);
  };

  private onKeyDown(_event): void {
    // Detect platform
    if (navigator.platform.match('Mac')) {
      this.handleMacKeyEvents(_event);
    }
    else {
      this.handleWindowsKeyEvents(_event);
    }
  };

  private handleMacKeyEvents(_event) {
    // MetaKey documentation
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/metaKey
    let charCode = String.fromCharCode(_event.which).toLowerCase();
    if (this.saveEnabled && _event.metaKey && charCode === 's') {
      // Action on Cmd + S
      _event.preventDefault();
      _event.stopImmediatePropagation();
      this.OnSave.emit();
    }
    else if (this.cancelEnabled && _event.keyCode === 13 || _event.keyCode === 27) {
      _event.preventDefault();
      _event.stopImmediatePropagation();
      this.OnCancel.emit();
    }
  };

  private handleWindowsKeyEvents(_event) {
    let charCode = String.fromCharCode(_event.which).toLowerCase();
    if (this.saveEnabled && _event.ctrlKey && charCode === 's') {
      // Action on Ctrl + S
      _event.preventDefault();
      _event.stopImmediatePropagation();
      this.OnSave.emit();
    }
    else if (this.cancelEnabled && _event.keyCode === 13 || _event.keyCode === 27) {
      _event.preventDefault();
      _event.stopImmediatePropagation();
      this.OnCancel.emit();
    }
  };
};
