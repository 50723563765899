<div class="component component-container fileexplorer"
     fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="showMediaActions" localize="filexplorer.header.default"></h1>
  <h1 *ngIf="showDirectorySelection" localize="filexplorer.header.copyfile"></h1>
  <h1 *ngIf="!showMediaActions&&!showDirectorySelection" localize="filexplorer.header.mediadirectory"></h1>

  <mat-toolbar>
    <!--<span localize="filexplorer.toolbar.path.label"></span>-->
    <button mat-button localize="title:filexplorer.toolbar.refresh.label" (click)="loadData()">
      <icon icon="Sync"></icon>
    </button>
    <button mat-button localize="title:filexplorer.toolbar.newdir.label" (click)="newDirectory()">
      <icon icon="FolderPlus"></icon>
    </button>
    <button mat-button localize="title:filexplorer.toolbar.dirup.label" (click)="directoryUp()">
      <icon icon="LevelUp"></icon>
    </button>
    <ul class="directories">
      <li class="directory-item" *ngFor="let d of directoryStack" (click)="selectDirectoryByBreadCrumbs(d)"><span>&frasl;&nbsp;</span><span>{{ d.name }}</span></li>
    </ul>
    <span class="toolbar-spacer"></span>
    <ngx-file-drop *ngIf="showUpload"
                   ngf-select [dropZoneLabel]="statusDragnDrop"
                   (onFileDrop)="dropped($event, t)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <button type="button" class="uploadbutton" (click)="openFileSelector()">{{ statusDragnDrop }}</button>
      </ng-template>
    </ngx-file-drop>
    <span class="toolbar-spacer"></span>
    <button *ngIf="showMediaActions" mat-button localize="title:filexplorer.toolbar.applyselection.label" (click)="applySelection()">
      <icon icon="Check"></icon>
    </button>
    <button *ngIf="showMediaActions" mat-button localize="title:filexplorer.toolbar.applyall.label" (click)="applyAll()">
      <icon icon="List"></icon>
    </button>
    <button *ngIf="showMediaActions" mat-button localize="title:common.cancel.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
    <button *ngIf="showDirectorySelection" mat-button
            (click)="doCopyFile(element)"
            localize="title:filexplorer.grid.edit.selectdirectory.title">
      <icon icon="Check"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container no-scroll frame">

    <div class="b-form-container">
      <div class="component-content-container">

        <app-widgets-summary [Path]="path"
                             [ShowApplyButton]="showMediaActions"
                             (onSelected)="onWidgetSelected($event)"></app-widgets-summary>

      </div>
    </div>

    <div class="b-form-container">
      <div class="component-content-container">

        <p *ngIf="showNewFileName" class="copy-details">
          <span class="input-title" localize="$:filexplorer.form.copy.newdirectory.description"></span>&nbsp;

          <mat-form-field class="input">
            <input matInput [placeholder]="textNewDirectory" [(ngModel)]="path">
          </mat-form-field>

          <span class="input-title" localize="$:filexplorer.form.copy.newfilename.description"></span>&nbsp;

          <mat-form-field class="input">
            <input matInput [placeholder]="textNewFileName" [(ngModel)]="newFileName">
          </mat-form-field>
        </p>

        <p *ngIf="!directories"><em localize="common.loadingdata.msg"></em></p>

        <p *ngIf="!(directories.length>0)" class="emptylabel"><em localize="filexplorer.griddir.empty"></em></p>

        <table mat-table [dataSource]="directories" *ngIf="directories.length>0">
          <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef class="column-icon"></th>
            <td mat-cell *matCellDef="let element" class="column-icon">
              <icon icon="FolderOpen" class="cursor-pointer" (click)="selectDirectory(element)"></icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class=""><span localize="filexplorer.griddir.edit.name.title"></span></th>
            <td mat-cell *matCellDef="let element" class="">
              <a (click)="selectDirectory(element)" class="cursor-pointer">
                {{ element.name }}
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="column-date"><span localize="filexplorer.griddir.edit.date.title"></span></th>
            <td mat-cell *matCellDef="let element" class="column-date">{{ element.date | date:'short':'UTC' }}</td>
          </ng-container>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef class="cell-buttons one-button"></th>
            <td mat-cell *matCellDef="let element" class="cell-buttons one-button">
              <button mat-button
                      class="trashbutton"
                      (click)="deleteDirectory(element)"
                      localize="title:filexplorer.griddir.edit.deletedir.title">
                <icon icon="Trash"></icon>
              </button>
              <button mat-button
                      (click)="selectDirectory(element)"
                      localize="title:filexplorer.griddir.edit.selectdir.title">
                <icon icon="ArrowRight"></icon>
              </button>
              <!--<button *ngIf="showDirectorySelection" mat-button
                      (click)="selectFile(element)"
                      localize="title:filexplorer.grid.edit.selectdirectory.title">
                <icon icon="Check"></icon>
              </button>-->
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedDirColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedDirColumns;"></tr>

        </table>

      </div>

    </div>

    <div class="b-form-container" *ngIf="hideFilesList===false">

      <div class="component-content-container">

        <p *ngIf="!files"><em localize="common.loadingdata.msg"></em></p>
        <p *ngIf="!files.length>0" class="emptylabel"><em localize="filexplorer.grid.empty"></em></p>
        <table mat-table [dataSource]="files" *ngIf="files.length>0">

          <ng-container matColumnDef="fileicon">
            <th mat-header-cell *matHeaderCellDef class="column-fileicon"></th>
            <td mat-cell *matCellDef="let element" class="column-fileicon">
              <icon icon="File" class="size-l cursor-pointer" (click)="previewfile(element);"></icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="selection">
            <th mat-header-cell *matHeaderCellDef class="column-selection"></th>
            <td mat-cell *matCellDef="let element" class="column-selection">
              <mat-checkbox [(ngModel)]="element.selected"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="preview">
            <th mat-header-cell *matHeaderCellDef class="cell-preview"></th>
            <td mat-cell *matCellDef="let element" class="cell-preview" (click)="previewfile(element);">
              <span class="preview" [ngStyle]="{'background-image': 'url(\'' + getMediaUrlPath(element) + '\')'}"></span>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class=""><span localize="filexplorer.grid.edit.name.title"></span></th>
            <td mat-cell *matCellDef="let element" class="">
              <a (click)="previewfile(element);" class="cursor-pointer">
                {{ element.name }}
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="ext">
            <th mat-header-cell *matHeaderCellDef class="column-ext"><span localize="filexplorer.grid.edit.ext.title"></span></th>
            <td mat-cell *matCellDef="let element" class="column-ext">{{ element.extension }}</td>
          </ng-container>


          <ng-container matColumnDef="filesize">
            <th mat-header-cell *matHeaderCellDef class="header-filesize"><span localize="filexplorer.grid.edit.filesize.title"></span></th>
            <td mat-cell *matCellDef="let element" class="column-filesize">{{ humanFileSize(element.filesize) }}</td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="column-date"><span localize="filexplorer.grid.edit.date.title"></span></th>
            <td mat-cell *matCellDef="let element" class="column-date">{{ element.date | date:'short':'UTC' }}</td>
          </ng-container>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef class="cell-buttons one-button"></th>
            <td mat-cell *matCellDef="let element" class="cell-buttons one-button">
              <button mat-button
                      class="trashbutton"
                      (click)="deleteFile(element)"
                      localize="title:filexplorer.grid.edit.deletefile.title">
                <icon icon="Trash"></icon>
              </button>
              <button mat-button *ngIf="false" (click)="editFile(element)" localize="title:fileexplorer.grid.edit.editfile.title">
                <icon icon="Pen"></icon>
              </button>
              <button *ngIf="showMediaActions" mat-button
                      (click)="selectFile(element)"
                      localize="title:filexplorer.grid.edit.selectfile.title">
                <icon icon="Check"></icon>
              </button>
              <button mat-button
                      (click)="copyFile(element)"
                      localize="title:filexplorer.grid.edit.copyfile.title">
                <icon icon="Copy"></icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedFilesColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedFilesColumns;"></tr>

        </table>

      </div>

    </div>

  </div>

</div>
