<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="mediaJobID" localize="mediajob.single.header.edit"></h1>
  <h1 *ngIf="!mediaJobID" localize="mediajob.single.header.new"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.back.msg" (click)="triggerOnClose()" [routerLink]="['/mediajobs']">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!mediaJob"><em localize="common.loadingdata.msg"></em></p>

    <div fxFlex="500px" class="b-form-container wrapper">

      <mat-form-field>
        <input matInput [placeholder]="textName" [(ngModel)]="mediaJob.jobName">
      </mat-form-field>

      <mat-form-field>
        <mat-select [placeholder]="textDevice" [(ngModel)]="mediaJob.mediaDevice">
          <mat-optgroup [label]="textDeviceDevice">
            <mat-option *ngFor="let d of devices" [value]="d.mediaDeviceID">{{ d.deviceName }}</mat-option>
          </mat-optgroup>
          <mat-optgroup [label]="textDeviceGroup">
            <mat-option *ngFor="let g of groups" [value]="g.mediaDeviceID">{{ g.deviceName }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select [placeholder]="textMediaList" [(ngModel)]="mediaJob.mediaListID">
          <mat-option *ngFor="let ml of mediaLists" [value]="ml.mediaListID">{{ml.listName}} ({{ml.durationSumFormatted}})</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select [placeholder]="textLooped" [(ngModel)]="mediaJob.looped" (selectionChange)="loopedChangeHandler()">
          <mat-option [value]="false" localize="mediajob.single.form.looped.startend.title"></mat-option>
          <mat-option [value]="true" localize="mediajob.single.form.looped.loop.title"></mat-option>
        </mat-select>
      </mat-form-field>

      <!-- STARTTIME -->

      <div class="b-form-container dateTimeArea" fxLayout="row">

        <mat-form-field fxFlex="40%">
          <input matInput [matDatepicker]="startDatePicker" [placeholder]="textStart" [(ngModel)]="mediaJob.startTime">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="b-form-container" fxFlex="40%">
          <ngx-timepicker-field [format]="24" [(ngModel)]="startTime"></ngx-timepicker-field>
        </div>

        <div class="b-form-container" fxFlex="15%" class="button-start-container">
          <button mat-button localize="title:mediajob.single.form.looped.fromnow.title,$:mediajob.single.form.looped.fromnow.title" (click)="startFromNow()"></button>
        </div>

      </div>

      <!-- ENDTIME -->

      <div class="b-form-container dateTimeArea" fxLayout="row">

        <mat-form-field fxFlex="40%" *ngIf="mediaJob.looped===false">
          <input matInput [matDatepicker]="endDatePicker" [placeholder]="textEnd" [(ngModel)]="mediaJob.endTime">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="b-form-container calendar-time" fxFlex="40%" *ngIf="mediaJob.looped===false">
          <ngx-timepicker-field [format]="24" [(ngModel)]="endTime"></ngx-timepicker-field>
        </div>

        <div class="b-form-container" fxFlex="15%" *ngIf="mediaJob.looped===false" class="button-end-container">
          <button mat-button localize="title:mediajob.single.form.looped.tonow.title,$:mediajob.single.form.looped.tonow.title" (click)="endToNow()"></button>
        </div>

      </div>

      <!-- ... -->

      <mat-form-field *ngIf="mediaJob.looped===true">
        <input matInput [disabled]="mediaJob.looped===false" [placeholder]="textLoopCount" [(ngModel)]="mediaJob.loopCount">
      </mat-form-field>

      <p>
        <span localize="$:mediajob.single.form.weekdays.title"></span>
      </p>

      <ul *ngIf="days">
        <li *ngFor="let d of days" class="weekdays"><mat-checkbox [(ngModel)]="d.selected">{{ d.text }}</mat-checkbox></li>
      </ul>

      <div class="b-form-container">
        <mat-checkbox [(ngModel)]="mediaJob.archive"><span localize="mediajob.single.form.archive.title"></span></mat-checkbox>
      </div>

    </div>

  </div>

</div>
