<div class="component component-container" fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="pwp.htmltexts.summary.header"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <!--<button mat-button
            localize="title:pwp.htmltexts.summary.toolbar.new.title"
            (click)="editHtmlTexts(null)"><icon icon="Plus"></icon></button>-->
  </mat-toolbar>

  <div class="component-content-container">

    <!--<div fxLayout="row">

      <div fxFlex="250px" class="b-form-container">

        <mat-form-field>
          <mat-select [placeholder]="textFilterModule" [(ngModel)]="filterModuleID" (selectionChange)="onModuleFilterChanged()">
            <mat-option *ngFor="let module of modules" [value]="module.moduleID">{{module.moduleTitle}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </div>-->

    <table mat-table [dataSource]="htmlTexts">

      <ng-container matColumnDef="moduleID">
        <th mat-header-cell *matHeaderCellDef localize="pwp.htmltexts.summary.grid.col.moduleid.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.moduleID }}</td>
      </ng-container>

      <ng-container matColumnDef="module">
        <th mat-header-cell *matHeaderCellDef localize="pwp.htmltexts.summary.grid.col.module.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.moduleDisplayName }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons one-button">
          <span class="button-wrapper">
            <span class="centered">
              <!--<button mat-button  (click)="delete(element)" localize="title:pwp.htmltexts.summary.grid.col.edit.delete.title">
                <icon icon="Trash"></icon>
              </button>-->
              <button mat-button (click)="editHtmlTexts(element)"
                localize="title:pwp.htmltexts.summary.grid.col.edit.edit.title">
                <icon icon="Pen"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected':moduleID===row.moduleID}">
      </tr>

    </table>
  </div>

</div>