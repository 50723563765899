import { IEventItem } from '../interfaces/IEventItem';

export default class EventItem implements IEventItem
{
  itemID: number;
  moduleID: number;
  title: string;
  description: string;
  expireDate: string;
  startDate: string;
  place: string;
  imageID: number;
  thumbID: number;
}
