import { Directive, ElementRef, HostListener, Input, EventEmitter, Output } from '@angular/core';
import html2canvas from 'html2canvas';
import { SecurityService } from '../../../app-pwp/src/app/core/services/common/security.service';

@Directive({
  selector: 'websiteWrapper',
  inputs: ['url']
})

export class WebSiteWrapperDirective
{
  private elFrame: HTMLIFrameElement = null;
  private canvas: HTMLCanvasElement = null;

  constructor(private el: ElementRef,
    private security: SecurityService)
  {
    this.canvas = document.createElement('canvas');

    this.elFrame = document.createElement('iframe');
    //this.elFrame.crossOrigin = 'anonymous';
    this.elFrame.style.height = '100%';
    this.elFrame.style.width = '100%';
    this.elFrame.onload = () =>
    {
      this.triggerScreenshot();
    };

    this.el.nativeElement.append(this.elFrame);
  }

  triggerScreenshot()
  {
    if (this.url === null || this.url === undefined || this.url === '')
    {
      return;
    }

    var tmp = this.elFrame.contentDocument || this.elFrame.contentWindow.document;
    if (tmp === null || tmp === undefined)
    {
      return;
    }

    html2canvas(tmp.body).then(function (_canvas)
    {
      var imageData = _canvas.toDataURL('image/jpeg', 0.7);
      this.onCaptureDone.emit(imageData);
    }.bind(this));

  };

  // --------------------------------------------------------------------------

  _url: string;

  get url(): string { return this._url; }

  @Input('url')
  set url(_value: string)
  {
    this._url = _value;
    this.elFrame.src = this._url;
  }

  // --------------------------------------------------------------------------

  @Output('onCaptureDone')
  onCaptureDone: EventEmitter<string> = new EventEmitter<string>();
}
