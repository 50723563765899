<div class="authentication">

  <div class="component-container" fxLayout="column">

    <img class="logo" src="../../assets/images/evision5-white.svg" />

    <h1>Activation</h1>

    <div class="component-content-container no-scroll">

      <div fxLayout="column" fxLayoutAlign="space-around none">

        <div fxFlex="auto" class="b-form-container">

          <p>
            Please enter an activation code for this webplayer. The code handles
            activation and authentication to enable this playing any content.
            Required credentials will be stored in the localstorage of the browser. Deleting
            all browser files requires reactivation.
          </p>

        </div>
        <div fxFlex>

          <mat-form-field>
            <input matInput placeholder="Activation code" [(ngModel)]="activationCode">
          </mat-form-field>

        </div>

      </div>

    </div>

    <mat-toolbar>
      <span class="toolbar-spacer"></span>
      <button mat-button (click)="handleAction()">OK</button>
    </mat-toolbar>

  </div>

</div>
