<div class="component component-container" fxLayout="column" (keydown)="keyHandler.TriggerOnKeyDown($event)"
  tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="eventItem" localize="pwp.evenitem.single.header.edit"></h1>
  <h1 *ngIf="!eventItem||eventItemID===0" localize="pwp.evenitem.single.header.new"></h1>

  <mat-toolbar>
    <button mat-button localize="title:pwp.evenitem.single.toolbar.selectimage.title" (click)="openSelectImage()">
      <icon icon="FileImage"></icon>
    </button>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.cancel.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container">

      <mat-form-field>
        <input matInput [placeholder]="textTitle" [(ngModel)]="eventItem.title">
      </mat-form-field>

      <mat-form-field>
        <mat-select [placeholder]="textModule" [(ngModel)]="eventItem.moduleID">
          <mat-option *ngFor="let module of modules" [value]="module.moduleID">{{module.moduleTitle}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textPlace" [(ngModel)]="eventItem.place">
      </mat-form-field>

      <div class="b-form-container" fxLayout="row">

        <mat-form-field class="datecontainer">
          <input matInput [matDatepicker]="startDatePicker" [placeholder]="textStartDate"
            [(ngModel)]="eventItem.startDate">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="b-form-container" fxFlex="40%">
          <ngx-timepicker-field [format]="24" [(ngModel)]="startTime"></ngx-timepicker-field>
        </div>

        <div class="b-form-container buttonfromnow" fxFlex="15%">
          <button mat-button
            localize="title:pwp.evenitem.single.form.startdate.fromnow.title,$:pwp.evenitem.single.form.startdate.fromnow.title"
            (click)="startFromNow()"></button>
        </div>

      </div>
      <div class="b-form-container" fxLayout="row">

        <mat-form-field class="datecontainer">
          <input matInput [matDatepicker]="expireDatePicker" [placeholder]="textExpireDate"
            [(ngModel)]="eventItem.expireDate">
          <mat-datepicker-toggle matSuffix [for]="expireDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #expireDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="b-form-container" fxFlex="40%">
          <ngx-timepicker-field [format]="24" [(ngModel)]="expireTime"></ngx-timepicker-field>
        </div>

        <!--<div class="b-form-container" fxFlex="15%">
          <button mat-button localize="title:pwp.evenitem.single.form.expiredate.tonow.title,$:pwp.evenitem.single.form.expiredate.tonow.title" (click)="endToNow()"></button>
        </div>-->

      </div>

    </div>
    <div fxFlex="300px" class="b-form-container">

      <mat-form-field>
        <textarea matInput [placeholder]="textDescription" rows="6" [(ngModel)]="eventItem.description"></textarea>
      </mat-form-field>

    </div>

  </div>

</div>