<div class="component component-container" fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="medialistsetlinkedlists.summary.header"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.back.msg" (click)="close()">
      <icon icon="Times"></icon>"
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!mediaList||!mediaLists"><em localize="common.loadingdata.msg"></em></p>

    <div fxFlex="500px" class="b-form-container">

      <mat-form-field>
        <input matInput [placeholder]="textListName" [(ngModel)]="mediaList.listName">
      </mat-form-field>

      <!--<mat-form-field>
        <input matInput [placeholder]="textTotalDuration" [ngModel]="mediaList.durationSumFormatted" disabled>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textMediatypes" [ngModel]="mediaList.mediaTypes" disabled>
      </mat-form-field>-->

      <div class="b-form-container">
        <mat-checkbox [(ngModel)]="mediaList.linkedList" disabled><span localize="medialistsetlinkedlists.summary.form.linkedlist.title"></span></mat-checkbox>
      </div>

      <ul>
        <li class="medialist" *ngFor="let ml of mediaLists">
          <mat-checkbox [(ngModel)]="ml.selected">{{ ml.listName }}</mat-checkbox>
        </li>
      </ul>

    </div>

  </div>

</div>
