<div class="component component-container"
     fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="pwp.roles.summary.header"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button
            localize="title:pwp.roles.summary.toolbar.new.title"
            (click)="edit(null)">
      <icon icon="Plus"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <table mat-table [dataSource]="rolesDataSource">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef localize="pwp.roles.summary.grid.col.name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.roleName }}</td>
      </ng-container>

      <ng-container matColumnDef="isAuthor">
        <th mat-header-cell *matHeaderCellDef localize="pwp.roles.summary.grid.col.isauthor.title" class="column-isauthor"></th>
        <td mat-cell *matCellDef="let element" class="column-isauthor">
          <mat-checkbox [(ngModel)]="element.isAuthor" disabled></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="isEditor">
        <th mat-header-cell *matHeaderCellDef localize="pwp.roles.summary.grid.col.iseditor.title" class="column-iseditor"></th>
        <td mat-cell *matCellDef="let element" class="column-iseditor">
          <mat-checkbox [(ngModel)]="element.isEditor" disabled></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="isContentManager">
        <th mat-header-cell *matHeaderCellDef localize="pwp.roles.summary.grid.col.iscontentmanager.title" class="column-iscontentmanager"></th>
        <td mat-cell *matCellDef="let element" class="column-iscontentmanager">
          <mat-checkbox [(ngModel)]="element.isContentManager" disabled></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons two-buttons">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button (click)="delete(element)" localize="title:pwp.roles.summary.grid.col.edit.delete.title">
              <icon icon="Trash"></icon></button>
              <button mat-button (click)="edit(element)" localize="title:pwp.roles.summary.grid.col.edit.edit.title">
              <icon icon="Pen"></icon></button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected':roleID===row.roleID}"></tr>

    </table>
  </div>

</div>
