import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})

export class LandingPageComponent
{
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string)
  {
  };

  ngOnInit()
  {
  };

}
