<div class="component-container" fxLayout="column">

  <h1>{{options.title}}</h1>

  <div class="component-content-container">

    <div fxLayout="row" fxLayoutAlign="space-around none">

      <div fxFlex="auto" class="b-form-container">

        <p [innerHTML]="options.message"></p>

      </div>
      <div fxFlex></div>

    </div>

  </div>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button *ngIf="options.actionYes" (click)="handleAction('yes')" localize="common.yes.lbl"></button>
    <button mat-button *ngIf="options.actionNo" (click)="handleAction('no')" localize="common.no.lbl"></button>
    <button mat-button *ngIf="options.actionOk" (click)="handleAction('ok')" localize="common.ok.lbl"></button>
    <button mat-button *ngIf="options.actionCancel" (click)="handleAction('cancel')" localize="common.cancel.lbl"></button>
  </mat-toolbar>

</div>
