<div class="component component-container"
     fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <!--<h1 localize="pwp.users.summary.header"></h1>-->

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button
            localize="title:pwp.apikeys.summary.toolbar.new.title"
            (click)="edit(null)">
      <icon icon="Plus"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxLayout="row">

      <div fxFlex="250px" class="b-form-container">

        <mat-form-field>
          <mat-select [placeholder]="textUserFilter" [(ngModel)]="filterUserID" (selectionChange)="onUserFilterChanged()">
            <mat-option *ngFor="let u of users" [value]="u.userID">{{u.name}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </div>

    <table mat-table [dataSource]="apiKeysDataSource">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef localize="pwp.apikeys.summary.grid.col.name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.apiKey_Name }}</td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef localize="pwp.apikeys.summary.grid.col.creationDate.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.apiKey_CreationDate | date:'short':'UTC' }}</td>
      </ng-container>

      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef localize="pwp.apikeys.summary.grid.col.key.title"></th>
        <td mat-cell *matCellDef="let element">
          <span class="button-wrapper left">
            <span class="centered">
              <button mat-button (click)="watchApiKey(element)" localize="title:pwp.apikeys.summary.grid.col.key.watch.title">
                <icon icon="Eye"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="disabled">
        <th mat-header-cell *matHeaderCellDef localize="pwp.apikeys.summary.grid.col.disabled.title"></th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox [(ngModel)]="element.apiKey_Disabled" disabled="disabled"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="expirationDate">
        <th mat-header-cell *matHeaderCellDef localize="pwp.apikeys.summary.grid.col.expirationDate.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.apiKey_ExpirationDate | date:'short':'UTC' }}</td>
      </ng-container>

      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef localize="pwp.apikeys.summary.grid.col.userName.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons two-buttons">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button (click)="delete(element)" localize="title:pwp.apikeys.summary.grid.col.edit.delete.title">
                <icon icon="Trash"></icon>
              </button>
              <button mat-button (click)="edit(element)" localize="title:pwp.apikeys.summary.grid.col.edit.edit.title">
                <icon icon="Pen"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected':apiKey_ID===row.apiKey_ID}"></tr>

    </table>
  </div>

</div>
