import { Directive, ElementRef, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { IGlobal } from '../../../app-pwp/src/app/core/interfaces/IGlobal';
import { SecurityService } from '../../../app-pwp/src/app/core/services/common/security.service';
var Cropper = require('assets/cropper/cropper.js');

@Directive({
  selector: 'imageCropWrapper',
  inputs: ['url', 'ratio']
})

export class ImageCropWrapperDirective
{
  private elementImage: HTMLImageElement = null;
  private elementContainer: HTMLDivElement;

  private cropper: any = null;

  constructor(private el: ElementRef,
    private security: SecurityService)
  {
    this.createStructure();
  }

  private createStructure()
  {
    this.elementImage = document.createElement('img');
    this.elementImage.style.height = '100%';
    this.elementContainer = document.createElement('div');
    this.elementContainer.style.height = '100%';

    this.elementContainer.append(this.elementImage);
    this.el.nativeElement.append(this.elementContainer);
  };

  private createCropper()
  {
    if (this.cropper !== null)
    {
      if (this._ratio === null)
      {
        this.cropper.setAspectRatio(null);
      }
      else if (this._ratio > 0)
      {
        this.cropper.setAspectRatio(this._ratio);
      }
      else
      {
        var croppedImage = this.cropper.getData(true);
        this.cropper.setAspectRatio(croppedImage.width / croppedImage.height);
      }
    }
    else
    {
      this.cropper = new Cropper(this.elementImage, {
        //aspectRatio: 16 / 9,
        //viewMode: 1,
        ready: () =>
        {
          var croppedImage = this.cropper.getData(true);
          this.onImageCropped.emit(croppedImage);

          this.cropper.setAspectRatio(croppedImage.width / croppedImage.height);
        },
        cropend: (e) =>
        {
          var croppedImage = this.cropper.getData(true);
          this.onImageCropped.emit(croppedImage);
        }
      });
    }
  };

  private check()
  {
    if (!(this._url === null || this._url === undefined || this._url === ''))
    {
      if (this.elementImage.src === '')
      {
        this.elementImage.src = this._url;
      }

      this.createCropper();
    }
  };

  // --------------------------------------------------------------------------

  _url: string;

  get url(): string { return this._url; }

  @Input('url')
  set url(_value: string) { this._url = _value; this.check(); }

  // --------------------------------------------------------------------------

  _ratio: number;

  get ratio(): number { return this._ratio; }

  @Input('ratio')
  set ratio(_value: number) { this._ratio = _value; this.check(); }

  // --------------------------------------------------------------------------

  @Output('onImageCropped')
  onImageCropped: EventEmitter<any> = new EventEmitter<any>();
}
