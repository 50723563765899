<div class="component component-container" fxLayout="column"
  [ngClass]="{'mode-dashboard':runningMode===MODE_DASHBOARD}">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="runningMode===MODE_NORMAL" localize="mediadevices.summary.header"></h1>

  <mat-toolbar *ngIf="runningMode===MODE_NORMAL">
    <button mat-button localize="title:mediadevices.summary.toolbar.setduration.title" (click)="setStartupTime()">
      <icon icon="Clock"></icon>
    </button>
    <button mat-button localize="title:navigation.mediadevicestartupexceptions.label"
      [routerLink]='["/mediadevices", "startup", "exceptions"]'>
      <icon icon="Bolt"></icon>
    </button>
    <mat-checkbox [(ngModel)]="remoteView" (change)="onRemoteViewChangeHandler()"><span
        localize="$:mediadevices.summary.toolbar.showremoteview.title"></span></mat-checkbox>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:mediadevices.summary.toolbar.new.title" (click)="editDevice('new')">
      <icon icon="Plus"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!mediaDevices"><em localize="common.loadingdata.msg"></em></p>

    <ul *ngIf="runningMode===MODE_NORMAL" class="devicegroups">
      <li class="devicegroup action" (click)="onGroupSelectedHandler('all')" [ngClass]="{'selected':groupMode==='all'}">
        <span class="title"
          localize="$:mediadevices.summary.devices.alldevices,title:mediadevices.summary.devices.alldevices.title"></span>
      </li>
      <li class="devicegroup action" (click)="onGroupSelectedHandler('withoutgroup')"
        [ngClass]="{'selected':groupMode==='withoutgroup'}">
        <span class="title"
          localize="$:mediadevices.summary.devices.withoutgroup,title:mediadevices.summary.devices.withoutgroup.title"></span>
      </li>
      <li class="devicegroup action" (click)="onGroupSelectedHandler('new')">
        <span class="title"
          localize="$:mediadevices.summary.devices.newgroup,title:mediadevices.summary.devices.newgroup.title"></span>
        <span class="actions add">
          <icon icon="Plus"></icon>
        </span>
      </li>
      <li class="devicegroup" *ngFor="let dg of mediaDeviceGroups"
        [ngClass]="{'selected':selectedGroup===dg.mediaDeviceID}">
        <span class="title" (click)="onGroupSelectedHandler(dg.mediaDeviceID)">
          {{ dg.deviceName }}
        </span>
        <span class="actions">
          <button mat-button (click)="deleteGroup(dg.mediaDeviceID)"
            localize="title:mediadevices.summary.devices.group.delete">
            <icon icon="Trash"></icon>
          </button>
          <button mat-button (click)="newGroup(dg.mediaDeviceID)"
            localize="title:mediadevices.summary.devices.group.edit">
            <icon icon="Pen"></icon>
          </button>
        </span>
      </li>
    </ul>

    <table mat-table [dataSource]="mediaDevices" *ngIf="viewMode===VIEW_MODE_DATA">

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef localize="mediadevices.summary.grid.state.title" class="column-state">
        </th>
        <td mat-cell *matCellDef="let element" class="column-state">
          <span *ngIf="element.state===2" localize="title:mediadevices.summary.grid.state.notavailable.title">
            <icon class="font-red" icon="CircleXMark"></icon>
          </span>
          <span *ngIf="element.state===1" localize="title:mediadevices.summary.grid.state.available.title">
            <icon class="font-green" icon="CircleCheck"></icon>
          </span>
          <span *ngIf="element.state===4" localize="title:mediadevices.summary.grid.state.disabled.title">
            <icon icon="CircleMinus"></icon>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="deviceName">
        <th mat-header-cell *matHeaderCellDef localize="mediadevices.summary.grid.devicename.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.deviceName }}</td>
      </ng-container>

      <ng-container matColumnDef="machineName">
        <th mat-header-cell *matHeaderCellDef class="column-duration"
          localize="mediadevices.summary.grid.machinename.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.machineName }}</td>
      </ng-container>

      <ng-container matColumnDef="deviceIp">
        <th mat-header-cell *matHeaderCellDef class="column-elementcount cell-right"
          localize="mediadevices.summary.grid.deviceip.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.deviceIP }}</td>
      </ng-container>

      <ng-container matColumnDef="deviceMac">
        <th mat-header-cell *matHeaderCellDef localize="mediadevices.summary.grid.devicemac.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.deviceMAC }}</td>
      </ng-container>

      <ng-container matColumnDef="devicePreview">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/webpresenter', element.mediaDeviceID]"
            localize="mediadevices.summary.grid.preview.title"></a>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons three-buttons">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button (click)="openPreview(element)"
                localize="title:mediadevices.summary.grid.preview.btn.title">
                <icon icon="Eye"></icon>
              </button>
              <button mat-button (click)="deleteMediaDevice(element.mediaDeviceID)"
                localize="title:mediadevices.summary.grid.delete.btn.title">
                <icon icon="Trash"></icon>
              </button>
              <button mat-button (click)="editDevice(element.mediaDeviceID)"
                localize="title:mediadevices.summary.grid.edit.btn.title">
                <icon icon="Pen"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{'selected':mediaDeviceID===row.mediaDeviceID}"></tr>

    </table>

    <ul *ngIf="viewMode===VIEW_MODE_REMOTEVIEW" class="remoteviews">
      <li class="device remoteview" *ngFor="let md of mediaNonVirtualDevices">
        <span class="title">{{md.deviceName}}&nbsp;</span>
        <span class="plh" *ngIf="RemoteViewImages[md.mediaDeviceID]===null">
          <icon icon="VideoSlash"></icon>
          <br />
          <span class="text" localize="$:mediadevices.summary.toolbar.remoteview.na.title"></span>
        </span>

        <span class="img" *ngIf="RemoteViewImages[md.mediaDeviceID]!==null"
          [ngStyle]="{'background-image': 'url(\''+RemoteViewImages[md.mediaDeviceID]+'\')'}"
          (click)="openRemoteViewPreview(md)"></span>

      </li>
    </ul>

    <div class="remoteview-preview-big" *ngIf="mediaDevicePreviewBig">
      <div class="remoteview-preview-big-inner">
        <mat-toolbar>
          <span class="toolbar-spacer"></span>
          <button mat-button (click)="closeRemoteViewPreviewBig()">
            <icon icon="Times"></icon>
          </button>
        </mat-toolbar>
        <span class="img-preview"
          [ngStyle]="{'background-image': 'url(\''+RemoteViewImages[mediaDevicePreviewBig?.mediaDeviceID]+'\')'}"></span>
      </div>
    </div>

  </div>

</div>