import { IMediaDevice } from '../interfaces/IMediaDevice';

export default class MediaDevice implements IMediaDevice
{
  previewUrl: string;

  mediaDeviceID: string;
  defaultUrl: string;
  description: string;
  virtualDevice: boolean;
  deviceGroup: boolean;
  deviceIP: string;
  deviceMAC: string;
  deviceName: string;
  displaySerialNumber: string;
  displayTypeName: string;
  displayWaranty: Date;
  height: number;
  id: string;
  imgUrl: string;
  lastScreenCapture: Date;
  location: string;
  machineName: string;
  pcSerialNumber: string;
  pcWaranty: Date;
  screenCaptureInterval: number;
  shutdownTimeInSeconds: number;
  startupDays: number;
  startupTimeInSeconds: number;
  width: number;
  state: number;
  silentVideoPlayback: number;
}
