import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';
import { IMediaDeviceStartupException } from '../core/interfaces/IMediaDeviceStartupException';
import MediaDeviceStartupException from '../core/models/MediaDeviceStartupException';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { KeyHandler, UiKeyHandleService } from '../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { DateTimeService } from '../../../../app-pwp/src/app/core/services/common/datetime.service';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';

@Component({
  selector: 'app-mediadevicestartupexception-single',
  templateUrl: './mediadevicestartupexception-single.component.html',
  styleUrls: ['./mediadevicestartupexception-single.component.css']
})

export class MediaDeviceStartupExceptionSingleComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();

  private dialogGeneric: MatDialogRef<DialogComponent>;
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  // this flag is required to handle the manual init via init().
  // Avoids resetting/overwriting the id from parameters.
  private initiated: boolean = false;

  public exceptionID: string = null;
  public exception: IMediaDeviceStartupException = new MediaDeviceStartupException();

  public textName: string = application.getRawText('mediadevicestartupexception.single.form.name.title');
  public textStartDate: string = application.getRawText('mediadevicestartupexception.single.form.startdate.title');
  public textEndDate: string = application.getRawText('mediadevicestartupexception.single.form.enddate.title');

  public keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService) {
    //this.security.checkForRolesByName('devices.summary');
    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
  };

  private ngOnInit() {
    if (this.initiated) {
      return;
    }

    this.exceptionID = this.route.snapshot.paramMap.get('id');
    this.initException();

    this.initiated = true;
  };

  public init(_exceptionID: string) {
    this.exceptionID = _exceptionID;
    this.initException();

    this.initiated = true;
  };

  private initException() {
    if (this.exceptionID === 'new') {
      this.exceptionID = null;
    }

    if (this.exceptionID === null) {
      let now = this.dateTimeService.Now();
      let later = new Date(now);
      later.setUTCDate(later.getUTCDate() + 1);

      this.exception = new MediaDeviceStartupException();
      this.exception.startDate = now;
      this.exception.endDate = this.dateTimeService.FormatDateTimeAsISO(later);
    }
    else {
      this.loadException();
    }
  };

  private loadException() {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevicestartupexception/' + this.exceptionID))
      .subscribe(result => {
        this.exception = result.data as MediaDeviceStartupException;
      }, error => console.error(error));
  };

  public save() {
    // Check dates
    if (new Date(this.exception.startDate) >= new Date(this.exception.endDate)) {
      this.dialogGeneric = this.dialog.open(DialogComponent, {
        autoFocus: true,
        height: '250px',
        width: '550px'
      });
      this.dialogGeneric.componentInstance.options.actionOk = true;
      this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
      this.dialogGeneric.componentInstance.options.message = application.getRawText('mediadevicestartupexception.single.error.startdate-greater-enddate.msg');
      return;
    }

    var url = this.exceptionID === null
      ? evision5.buildApi(this.baseUrl, 'mediadevicestartupexception')
      : evision5.buildApi(this.baseUrl, 'mediadevicestartupexception/' + this.exceptionID);

    this.http
      .post<IReturnState>(url, this.exception)
      .subscribe(
        result => {
          if (result.success) {
            this.exception = result.data as IMediaDeviceStartupException;
            this.exceptionID = this.exception.id;
            this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
            this.triggerOnClose();
          }
          else {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        },
        error => console.error(error));
  };

  public triggerOnClose() {
    this.onClose.emit();
  };
}
