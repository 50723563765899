import { IMediaDeviceRoleSelectable } from '../interfaces/IMediaDeviceRoleSelectable';

export class MediaDeviceRoleSelectable implements IMediaDeviceRoleSelectable
{
  id: string;
  role: number;
  roleName: string;
  itemID: string;
  selected: boolean;
}
