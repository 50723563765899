import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { HttpWorkerFactoryService, HttpWorker } from './../../http/httpworkerfactory.service';
import { ICanBeBusy, IsBusy } from './../../busyindicator/busyindicatorfactory.service';
import { IApiUriBuilder } from '../../../interfaces/IApiUriBuilder';
import { IGlobal } from '../../../interfaces/IGlobal';
import { IReturnState } from '../../../interfaces/IReturnState';
import { application } from '../../../globals';

@Injectable({
  providedIn: 'root',
})

export class CrudServiceFactory
{
  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string)
  {
  }

  public Get<TItem, IID>(_multiControllerName: string, _singleControllerName: string, _apiUriBuilder: IApiUriBuilder): CrudService<TItem, IID>
  {
    var crud = new CrudService<TItem, IID>(this.httpWorkerFactory, this.router, this.baseUrl);
    crud.ControllerNameMulti = _multiControllerName;
    crud.ControllerNameSingle = _singleControllerName;
    crud.ApiBuilder = _apiUriBuilder;
    return crud;
  };
};

export class CrudService<TItem, IID> implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();

  public ControllerNameSingle: string = null;
  public ControllerNameMulti: string = null;
  public ApiBuilder: IApiUriBuilder = null;

  private application: IGlobal = application;

  private http: HttpWorker;

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    private baseUrl: string)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
  }

  /**
   * Gets all items.
   */
  public GetAll(): EventEmitter<TItem[]>
  {
    let onDone = new EventEmitter<TItem[]>();

    this.http.get<IReturnState>(application.buildApi(this.baseUrl, this.ControllerNameMulti))
      .subscribe(result =>
      {
        var data = result.data as TItem[];
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Gets an item.
   * @param _itemID
   */
  public Get(_itemID: IID): EventEmitter<TItem>
  {
    let onDone = new EventEmitter<TItem>();

    this.http.get<IReturnState>(application.buildApi(this.baseUrl, this.ControllerNameSingle + '/' + _itemID.toString()))
      .subscribe(result =>
      {
        var data = result.data as unknown as TItem;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Saves an item. (both insert and update)
   * @param _roleID
   */
  public Save(_item: TItem, _itemID: IID): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var url = _itemID !== null
      ? application.buildApi(this.baseUrl, this.ControllerNameSingle + '/' + _itemID)
      : application.buildApi(this.baseUrl, this.ControllerNameSingle);

    this.http.post<IReturnState>(url, _item)
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
 * Deletes am item.
 * @param _itemID
 */
  public Delete(_itemID: IID): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    this.http.delete<IReturnState>(application.buildApi(this.baseUrl, this.ControllerNameSingle + '/' + _itemID.toString()), {})
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

}
