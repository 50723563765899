import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'encodeUri'
})
export class EncodeUriPipe implements PipeTransform
{

  constructor(private sanitizer: DomSanitizer)
  {
  }

  transform(value: any, args?: any): any
  {
    var tmp = encodeURI(value);
    return tmp;
  }
}
