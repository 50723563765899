//
// ===== File globals.ts    
//
'use strict';

import { IGlobal } from "./interfaces/IGlobal";
import { IGlobalEvision5 } from "./interfaces/IGlobalEvision5";
import { ISystemTextExtended } from "./interfaces/ISystemTextExtended";

export let application: IGlobal = {
  ADMIN: 'A',
  DEV: 'D',
  TASK_CREATOR: 'TC',// TECHNICAL_WRITER
  TRANSLATOR: 'T',

  version: '0.0.0.0',
  user: '<no user>',

  texts: [],

  apiUrl: 'api/pwp/',

  //roleAccessObjects: [],

  technicalRoleNames: null,

  //setRoleAccessObjects(_rao: RoleAccessObjects[])
  //{
  //    this.roleAccessObjects = _rao;
  //},

  hasRole(_techRoleName: string): boolean
  {
    return true;
    //var has = this.technicalRoleNames !== null && this.technicalRoleNames.indexOf(_techRoleName) >= 0;
    //return has;
  },

  missingRole(_techRoleName: string): boolean
  {
    var missing = !this.hasRole(_techRoleName);
    return missing;
  },

  setTexts(_texts: ISystemTextExtended[])
  {
    this.texts = _texts;
  },

  getRawText(_textName: string): string
  {
    var culture = 'de-DE';

    for (var i = 0; i < this.texts.length; i++)
    {
      if (this.texts[i].culture === culture && this.texts[i].name === _textName)
      {
        return this.texts[i].text;
      }
    }

    return '<TEXT MISSING>';
  },

  buildApi(_basePath: string, _api: string): string
  {
    return _basePath + this.apiUrl + _api;
  }
};

export let evision5: IGlobalEvision5 = {
  apiUrl: 'api/evision5/',

  buildApi(_basePath: string, _api: string): string
  {
    return _basePath + this.apiUrl + _api;
  }
};
