import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogComponent } from './../dialog/dialog.component';
import { EventItemSingleComponent } from '../eventitem-single/eventitem-single.component';

import { application } from './../core/globals';
import { SecurityService } from './../core/services/common/security.service';
import { DateTimeService } from './../core/services/common/datetime.service';
import { HttpWorkerFactoryService, HttpWorker } from './../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { ModulesService } from './../core/services/data/modules.service';
import { IEventItemExtended } from '../core/interfaces/IEventItemExtended';
import { IModule } from '../core/interfaces/IModule';
import { IReturnState } from '../core/interfaces/IReturnState';
import { ArticlesService } from '../core/services/data/articles.service';
import { IArticleExtended } from '../core/interfaces/IArticleExtended';

@Component({
  selector: 'app-articles-summary',
  templateUrl: './articles-summary.component.html',
  styleUrls: ['./articles-summary.component.css']
})

export class ArticlesSummaryComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogEditEventItem: MatDialogRef<EventItemSingleComponent>;

  private displayColumnsAll: string[] = ['title', 'module', 'shortDescription', 'startDate', 'endDate', 'edit'];
  public displayedColumns: string[] = this.displayColumnsAll;

  public modules: IModule[] = [];

  public filterModuleID: number = null;

  public textFilterModule: string = application.getRawText('pwp.articles.summary.filter.eventitemmodule.title');

  private id: number = null;
  public articles: IArticleExtended[] = [];
  public articlesDataSource = new MatTableDataSource(this.articles);

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private modulesService: ModulesService,
    private articlesService: ArticlesService) {
    //this.security.CheckForFeature('events');

    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
  };

  ngOnInit() {
    this.loadArticles();
    this.loadModules();
  };

  private loadModules() {
    this.modulesService.GetArticlessForSelection().subscribe((_modules) => {
      this.modules = _modules;
    });
  };

  public onModuleFilterChanged() {
    this.loadArticles();
  };

  public delete(_article: IArticleExtended) {
    this.id = _article.id;
    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('pwp.articles.summary.delete.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      this.articlesService.DeleteArticle(_article.id).subscribe(_result => {
        var result = _result as IReturnState;
        if (result.success) {
          this.loadArticles();
          this.snackBarRef = this.snackBar.open(application.getRawText('pwp.articles.summary.deleted.msg'), application.getRawText('common.close.msg'));
        }
        else {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
        }
      },
        error => console.error(error));
    });
  };

  private loadArticles() {
    this.articlesService.GetArticles(this.filterModuleID).subscribe(result => {
      this.articles = result as IArticleExtended[];
      this.articles.forEach((_ml) => {
        _ml.startDate = this.dateTimeService.EnsureUTC(_ml.startDate);
        _ml.endDate = this.dateTimeService.EnsureUTC(_ml.endDate);
      });

      this.articlesDataSource = new MatTableDataSource(this.articles);

    }, error => console.error(error));
  };

  public editArticle(_article: IArticleExtended) {
    this.id = _article?.id;

    if (_article === null) {
      this.router.navigate(['pwp', 'articles', 'new']);
    }
    else {
      this.router.navigate(['pwp', 'articles', _article.id]);
    }
    //this.dialogEditEventItem = this.dialog.open(EventItemSingleComponent, {
    //  autoFocus: true,
    //  height: '450px',
    //  width: '850px'
    //});
    //this.dialogEditEventItem.componentInstance.eventItemID = _eventItem.itemID;
    //this.dialogEditEventItem.componentInstance.initEventItem();
    //this.dialogEditEventItem.componentInstance.onClose.subscribe(() =>
    //{
    //  this.dialogEditEventItem.close();
    //  this.loadArticles();
    //});
  };

}
