import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LocaleService
{
  constructor() { }

  getLanguageForEditor()
  {
    let tmp = this.getLanguage().toLowerCase();
    if (tmp.startsWith('de'))
      return 'de';
    else if (tmp.startsWith('es'))
      return 'es_ES';
    else if (tmp.startsWith('fr'))
      return 'fr_FR';
    else if (tmp.startsWith('it'))
      return 'it_IT';
    else
      return 'en_US'
  };

  getLanguage(): string
  {
    return navigator.language; // || navigator.userLanguage;
    //return 'fr-FR';
  };
}
