<div *ngIf="pathIsWidget===true">

  <h2>Widget configuration</h2>

  <p *ngIf="!(widgetDefinition&&widgetDefinition.parameters&&widgetDefinition.parameters.length>0)">This widget contains no parameters.</p>

  <div class="widgets-parameter" *ngIf="widgetDefinition&&widgetDefinition.parameters&&widgetDefinition.parameters.length>0">

    <div *ngFor="let parameter of widgetDefinition.parameters">

      <mat-form-field *ngIf="parameter.valueType==='text'">
        <input matInput
               type="text"
               [placeholder]="parameter.placeholder"
               [(ngModel)]="parameterValues[parameter.urlAttribute]"
               (ngModelChange)="onValueChanged()">
      </mat-form-field>

      <mat-form-field *ngIf="parameter.valueType==='color'">
        <input matInput
               type="color"
               [placeholder]="parameter.name"
               [(ngModel)]="parameterValues[parameter.urlAttribute]"
               (ngModelChange)="onValueChanged()">
      </mat-form-field>

      <mat-form-field *ngIf="parameter.valueType==='number'">
        <input matInput
               type="number"
               [placeholder]="parameter.name"
               [(ngModel)]="parameterValues[parameter.urlAttribute]"
               (ngModelChange)="onValueChanged()">
      </mat-form-field>

      <mat-form-field *ngIf="parameter.valueType==='select'">
        <mat-select [placeholder]="parameter.name"
                    [(ngModel)]="parameterValues[parameter.urlAttribute]"
                    (ngModelChange)="onValueChanged()">
          <mat-option *ngFor="let value of parameter.values" [value]="value">{{ value }}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </div>
</div>
