import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FileExplorerComponent } from '../../fileexplorer/fileexplorer.component';
import File from '../../core/models/File';
import { IMediaSettingsCollection } from '../../core/interfaces/IMediaSettingsCollection';
import MediaSettingsCollection from '../../core/models/MediaSettingsCollection';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../../app-pwp/src/app/dialog/dialog.component';
import { KeyHandler, UiKeyHandleService } from '../../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../../app-pwp/src/app/core/services/common/security.service';
import { SettingsService } from '../../core/services/data/settings.service';
import { FileExplorerService } from '../../core/services/data/fileexplorer.service';
import { IReturnState } from '../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { application } from '../../../../../app-pwp/src/app/core/globals';
import { evision5 } from '../../core/globals';
import { UriBuilderService } from '../../../../../app-pwp/src/app/core/services/common/uribuilder.service';
import { ICropModel } from '../../core/interfaces/ICropModel';

@Component({
  selector: 'app-imagecropeditor',
  templateUrl: './imagecropeditor.component.html',
  styleUrls: ['./imagecropeditor.component.css']
})

export class ImageCropEditorComponent
{
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public content: string;

  public element: File;
  public path: string;
  public name: string;

  public croppedImage: any;

  public onClose = new EventEmitter();

  private settings: IMediaSettingsCollection = new MediaSettingsCollection();

  private initiated: boolean = false;

  private dialogGeneric: MatDialogRef<DialogComponent>;

  public keyHandler: KeyHandler;

  public ratio: string = 'original';
  public aspectRatio: number = null;

  public textRatio = application.getRawText('mediadevice.cropping.toolbar.ratio.title');

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private fileExplorerService: FileExplorerService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private uriBuilderService: UriBuilderService)
  {
    //this.security.checkForRolesByName('devices.summary');
    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.EnableCancel(false);
    this.keyHandler.OnSave.subscribe(() => this.save());

    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.fileExplorerService);
    this.busyIndicator.Register(this.settingsService);
  };

  ngOnInit()
  {
    if (this.initiated)
    {
      this.settingsService.GetSettings().subscribe((_settings: IMediaSettingsCollection) =>
      {
        this.settings = _settings;
      });

      return;
    }

    this.init('/', null);
  };

  init(_path: string, _element: File)
  {
    this.path = _path;
    this.element = _element;
    this.initiated = true;

    this.getContent();
  };

  public getFullPath()
  {
    if (this.element === null ||
      this.settings === null ||
      this.settings.webpresenterMediaDirectoryHttpValue === null ||
      this.settings.webpresenterMediaDirectoryHttpValue === undefined)
    {
      return '';
    }

    let uribuilder = this.uriBuilderService.Get();
    uribuilder.Parse(this.settings.webpresenterMediaDirectoryHttpValue).AddSegment(this.element.path);
    let uri = uribuilder.Build();

    return uri;
  };

  private isImage(_file: File)
  {
    return this.settings.imageFormatsValue.indexOf(_file.extension) > -1;
  }

  private isVideo(_file: File)
  {
    return this.settings.videoFormatsValue.indexOf(_file.extension) > -1;
  }

  private showInIframe(_file: File)
  {
    return _file.extension == ".pdf" || _file.extension == ".html";
  }

  private getContent()
  {
    this.fileExplorerService.GetFileContent(this.element.path).subscribe((_result: IReturnState) =>
    {
      this.content = _result.data as unknown as string;
    });
  };

  public save()
  {
    let data: ICropModel = {
      x: this.croppedImage.x,
      y: this.croppedImage.y,
      width: this.croppedImage.width,
      height: this.croppedImage.height
    };

    this.fileExplorerService.CropImage(this.element.path, data)
      .subscribe((_result: IReturnState) =>
      {
        if (_result.success)
        {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
          this.close();
        }
        else
        {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
        }
      });
  };

  public onRatioChangedHandler()
  {
    switch (this.ratio)
    {
      case 'original':
        this.aspectRatio = -1;
        break;
      case 'custom':
        this.aspectRatio = null;
        break;
      case 'ratio1_1':
        this.aspectRatio = 1;
        break;
      case 'ratio3_2':
        this.aspectRatio = 3 / 2;
        break;
      case 'ratio4_3':
        this.aspectRatio = 4 / 3;
        break;
      case 'ratio16_9':
        this.aspectRatio = 16 / 9;
        break;
      case 'ratio16_10':
        this.aspectRatio = 16 / 10;
        break;
    }
  };

  public onImageCroppedHandler(_event)
  {
    this.croppedImage = _event;
  };

  public close()
  {
    this.onClose.emit();
  };
}
