import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { FileSystemFileEntry, FileSystemDirectoryEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { application } from './../core/globals';
import { ActivatedRoute, Router } from '@angular/router';

import { UiKeyHandleService, KeyHandler } from './../core/services/common/uikeyhandle.service';
import { SecurityService } from './../core/services/common/security.service';
import { DateTimeService } from './../core/services/common/datetime.service';
import { ChunkedUploadService } from './../core/services/data/chunkedupload.service';
import { HttpWorkerFactoryService, HttpWorker } from './../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { ModulesService } from './../core/services/data/modules.service';
import EventItem from './../core/models/EventItem';
import { IImageItem } from '../core/interfaces/IImageItem';
import { IModule } from '../core/interfaces/IModule';
import { IEventItem } from '../core/interfaces/IEventItem';
import { IReturnState } from '../core/interfaces/IReturnState';
import { QueryString } from '../core/services/common/uribuilder.service';

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.css']
})

export class ImageSelectorComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  public OnClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  //private dialogGeneric: MatDialogRef<DialogComponent>;

  public ImageItem: IImageItem = null;
  public ThumbItem: IImageItem = null;

  public ModuleID: number = null;

  private initiated: boolean = false;

  private uploadRunning: boolean = false;

  public textDragnDrop: string = application.getRawText('pwp.imageselector.dropfiles.lbl');
  public statusDragnDrop: string = this.textDragnDrop;

  public keyHandler: KeyHandler;

  private selectedFile: NgxFileDropEntry = null;
  public selectedFileData: object = null;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private dateTimeService: DateTimeService,
    private modulesService: ModulesService,
    private uploadService: ChunkedUploadService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
    this.busyIndicator.Register(this.uploadService);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
  };

  private ngOnInit() {
    if (this.initiated) {
      return;
    }

    this.Init();
  };

  public Init() {
    if (this.initiated) {
      return;
    }

    this.initiated = true;
  };

  public save() {
    this.doUpload();
  };

  public close() {
    this.OnClose.emit();
  };

  public dropped(_event: NgxFileDropEntry[]) {
    if (_event.length > 0) {
      this.selectedFile = _event[0];
      var tmp = this.selectedFile.fileEntry as FileSystemFileEntry;
      tmp.file(_file => {
        var reader = new FileReader();
        reader.onload = function (_e) {
          this.selectedFileData = _e.target.result;
        }.bind(this);
        reader.readAsDataURL(_file);
      });
    }
  };

  public doUpload() {
    var droppedFile = this.selectedFile;

    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      //// Check if the extension is allowed.
      //var fileFormatIsValid = this.isValidFileFormat(droppedFile.fileEntry.name);
      //if (fileFormatIsValid === false)
      //{
      //  this.snackBarRef = this.snackBar.open(application.getRawText('mediaactivity.single.upload.wrongext.msg'), application.getRawText('common.close.msg'));
      //  return;
      //}

      // Create parameter for module.
      var queryString: QueryString[] = [];
      queryString.push(new QueryString('moduleid', this.ModuleID));

      // Upload file
      this.uploadRunning = true;
      var file = this.uploadService.UploadFileToImage(droppedFile.fileEntry as FileSystemFileEntry, '', queryString);
      file.OnStatus.subscribe((_result: number) => {
        this.statusDragnDrop = (Math.round(_result)).toString() + ' %';
      });
      file.OnDone.subscribe((_result: IReturnState) => {
        this.uploadRunning = false;
        this.statusDragnDrop = this.textDragnDrop;

        if (_result.success) {
          this.ImageItem = (_result.data as any).image as IImageItem;
          this.ThumbItem = (_result.data as any).thumb as IImageItem;

          this.snackBarRef = this.snackBar.open(application.getRawText('pwp.imageselector.upload.success.msg'), application.getRawText('common.close.msg'));
          this.OnClose.emit();
        }
        else if (_result.stateID == 96) {
          this.snackBarRef = this.snackBar.open(application.getRawText('pwp.imageselector.upload.wrongext.msg'), application.getRawText('common.close.msg'));
        }
        else if (_result.stateID == 98) {
          this.snackBarRef = this.snackBar.open(application.getRawText('pwp.imageselector.upload.error.msg'), application.getRawText('common.close.msg'));
        }
        else if (_result.stateID == 99) {
          this.snackBarRef = this.snackBar.open(application.getRawText('pwp.imageselector.upload.nowriteaccess.msg'), application.getRawText('common.close.msg'));
        }
      });
    }
    else {
      // It was a directory (empty directories are added, otherwise only files)
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      console.log(droppedFile.relativePath, fileEntry);
    }

  };
}
