<div class="component component-container" fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 mat-dialog-title><span localize="mediadevices.summary.toolbar.setstartuptime.title"></span></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button [mat-dialog-close]="true" localize="title:common.cancel.msg">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container wrapper">

      <p>
        <span localize="mediadevices.setstartuptime.weekdays.title"></span>
      </p>

      <ul *ngIf="days">
        <li *ngFor="let d of days" class="weekdays"><mat-checkbox [(ngModel)]="d.selected">{{ d.text }}</mat-checkbox></li>
      </ul>

      <div fxLayout="row">
        <div class="b-form-container" fxFlex="45%" localize="$:mediadevices.setstartuptime.from.title"></div>
        <div class="b-form-container" fxFlex="45%" localize="$:mediadevices.setstartuptime.to.title"></div>
      </div>

      <div class="b-form-container" fxLayout="row">

        <div class="b-form-container nopadding form-field" fxFlex="45%">
          <ngx-timepicker-field [format]="24" [(ngModel)]="startTime"></ngx-timepicker-field>
        </div>

        <div class="b-form-container nopadding form-field" fxFlex="45%">
          <ngx-timepicker-field [format]="24" [(ngModel)]="endTime"></ngx-timepicker-field>
        </div>

      </div>

    </div>

  </div>

</div>
