<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1><span localize="$:fileeditor.header"></span>&nbsp;{{element.path}}</h1>

  <mat-toolbar>
    <mat-form-field>
      <mat-select [placeholder]="textRatio" [(ngModel)]="ratio" (selectionChange)="onRatioChangedHandler()">
        <mat-option value="original" localize="$:mediadevice.cropping.toolbar.ratio.original.title"></mat-option>
        <mat-option value="custom" localize="$:mediadevice.cropping.toolbar.ratio.custom.title"></mat-option>
        <mat-option value="ratio1_1" localize="$:mediadevice.cropping.toolbar.ratio.1_1.title"></mat-option>
        <mat-option value="ratio3_2" localize="$:mediadevice.cropping.toolbar.ratio.3_2.title"></mat-option>
        <mat-option value="ratio4_3" localize="$:mediadevice.cropping.toolbar.ratio.4_3.title"></mat-option>
        <mat-option value="ratio16_9" localize="$:mediadevice.cropping.toolbar.ratio.16_9.title"></mat-option>
        <mat-option value="ratio16_10" localize="$:mediadevice.cropping.toolbar.ratio.16_10.title"></mat-option>
      </mat-select>
    </mat-form-field>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.cancel.title" (click)="close()">
      <icon icon="Times"></icon>
    </button>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container no-scroll">

    <imageCropWrapper [url]="getFullPath()" [ratio]="aspectRatio" (onImageCropped)="onImageCroppedHandler($event)"></imageCropWrapper>

  </div>

</div>
