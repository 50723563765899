import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { IGlobal } from '../../../../app-pwp/src/app/core/interfaces/IGlobal';
import { IGlobalEvision5 } from '../core/interfaces/IGlobalEvision5';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';
import { ISetStartEndOfAll } from '../core/interfaces/ISetStartEndOfAll';
import { application } from '../../../../app-pwp/src/app/core/globals';
import SetStartEndOfAll from '../core/models/SetStartEndOfAll';
import { IWeekDayItem } from '../../../../app-pwp/src/app/core/interfaces/IWeekDayItem';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { WeekDaysService } from '../../../../app-pwp/src/app/core/services/common/weekdays.service';
import { DateTimeService } from '../../../../app-pwp/src/app/core/services/common/datetime.service';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';

@Component({
  selector: 'app-mediadevices-startuptime',
  templateUrl: './mediadevices-startuptime.component.html',
  styleUrls: ['./mediadevices-startuptime.component.css']
})

export class MediaDevicesStartuptimeComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  public onSaved = new EventEmitter();

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  dialogGeneric: MatDialogRef<DialogComponent>;

  item: ISetStartEndOfAll = new SetStartEndOfAll();

  textPlaceholderDuration: string = application.getRawText('mediaactivities.duration.form.duration.placeholder');

  public startTime: string = '00:00 am';
  public endTime: string = '00:00 am';

  days: IWeekDayItem[] = this.weekDaysService.GetWeekDays();

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private route: ActivatedRoute,
    private weekDaysService: WeekDaysService,
    private dateTimeService: DateTimeService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
  };

  private ngOnInit() {
  };

  public save() {
    this.item.weekDays = this.weekDaysService.ConvertStartupDaysToValue(this.days);

    let now: string = this.dateTimeService.Now();
    this.item.startTime = this.dateTimeService.SetTimeToDate(this.startTime, now);
    this.item.endTime = this.dateTimeService.SetTimeToDate(this.endTime, now);

    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevices/schedule'), this.item)
      .subscribe(
        result => {
          if (result.success) {
            this.onSaved.emit();
            this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
          }
          else {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        },
        error => console.error(error));
  };
}
