import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { IMediaActivityRule } from '../core/interfaces/IMediaActivityRule';
import MediaActivityRule from '../core/models/MediaActivityRule';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { KeyHandler, UiKeyHandleService } from '../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { RolesService } from '../../../../app-pwp/src/app/core/services/data/roles.service';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IGlobal } from '../../../../app-pwp/src/app/core/interfaces/IGlobal';
import { IModule } from '../../../../app-pwp/src/app/core/interfaces/IModule';
import { ModulesService } from '../../../../app-pwp/src/app/core/services/data/modules.service';


@Component({
  selector: 'app-mediaactivity-rule',
  templateUrl: './mediaactivity-rule.component.html',
  styleUrls: ['./mediaactivity-rule.component.css']
})

export class MediaActivityRuleComponent {
  private application: IGlobal = application;

  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public mediaActivityID: string = null;
  public mediaActivityRuleID: string = null;
  public mediaActivityRule: IMediaActivityRule = new MediaActivityRule();
  public mediaActivityRuleInUse: boolean = false;
  public modules: IModule[] = [];

  private initiated: boolean = false;

  public textRuleDuration: string = application.getRawText('mediaactivity.rule.form.duration.title');
  public textModule: string = application.getRawText('mediaactivity.rule.form.module.title');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  public keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private rolesService: RolesService,
    private modulesService: ModulesService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
  };

  private ngOnInit() {
    if (this.initiated) {
      return;
    }

    this.mediaActivityID = this.route.snapshot.paramMap.get('id');
    this.initMediaActivityRule();
  };

  public initMediaActivityRule() {
    if (this.initiated) {
      return;
    }
    this.loadModules();

    this.loadMediaActivityRule();

    this.initiated = true;
  };

  private loadMediaActivityRule() {
    this.mediaActivityRuleInUse = false;

    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediaactivityrule/byactivity/' + this.mediaActivityID))
      .subscribe(result => {
        if (!(result.data === null)) {
          this.mediaActivityRule = result.data as IMediaActivityRule;
          this.mediaActivityRuleID = this.mediaActivityRule.mediaActivityRuleID;
          this.mediaActivityRuleInUse = true;
        }
      }, error => console.error(error));
  }

  private loadModules() {
    this.modulesService.GetEventItems().subscribe((_modules) => {
      this.modules = _modules;
      //this.loadMediaActivityRule();
      //if (!(this.mediaActivityRule.moduleID > 0) && this.modules.length > 0) {
      //  this.mediaActivityRule.moduleID = this.modules[0].moduleID;
      //}
    });
  };

  public save() {
    this.mediaActivityRule.mediaActivityID = this.mediaActivityID;

    if (this.mediaActivityRule.moduleID === null || this.mediaActivityRule.moduleID <= 0) {
      this.snackBarRef = this.snackBar.open(application.getRawText('mediaactivity.rule.form.error.nomoduleselected'), application.getRawText('common.close.msg'));
      return;
    }

    var url = this.mediaActivityRuleID === null
      ? evision5.buildApi(this.baseUrl, 'mediaactivityrule')
      : evision5.buildApi(this.baseUrl, 'mediaactivityrule/' + this.mediaActivityRuleID);

    if (this.mediaActivityRuleInUse) {
      this.http
        .post<IReturnState>(url, this.mediaActivityRule)
        .subscribe(
          result => {
            if (result.success) {
              this.mediaActivityRule = result.data as IMediaActivityRule;
              this.mediaActivityRuleID = this.mediaActivityRule.mediaActivityRuleID;


              this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
              this.onClose.emit();

            }
            else {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
            }
          },
          error => console.error(error));
    }
    else {
      if (this.mediaActivityRuleID != null) {
        this.http.delete<IReturnState>(url, {})
          .subscribe(result => {
            if (result.success) {
              var data = result as IReturnState;

              this.snackBarRef = this.snackBar.open(application.getRawText('common.deleted.msg'), application.getRawText('common.close.msg'));
              this.onClose.emit();
            }
            else {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
            }
          }, error => console.error(error));
      }
    }

  };

  public close() {
    this.onClose.emit(this.mediaActivityRule.mediaActivityRuleID);
  };

}
