<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="usersettings.header.edit"></h1>

  <mat-toolbar>
    <button mat-button localize="title:usersettings.changepassword" (click)="openChangePasswordDialog()">
      <icon icon="Lock"></icon>
    </button>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!userSettings"><em localize="common.loadingdata.msg"></em></p>

    <div fxFlex="100%" class="b-form-container wrapper">

      <div class="b-form-container wrapper">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title><span localize="$:usersettings.form.group.primary.header"></span></mat-panel-title>
          </mat-expansion-panel-header>

          <div class="b-form-container">
            <mat-form-field>
              <mat-label localize="$:usersettings.single.form.userlanguage.title"></mat-label>
              <mat-select matInput [(ngModel)]="userSettings.userLanguage">

                <mat-option *ngFor="let l of languages" [value]="l.culture">{{ l.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </mat-expansion-panel>
      </div>

    </div>

  </div>

</div>
