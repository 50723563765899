import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { HttpWorkerFactoryService, HttpWorker } from './../http/httpworkerfactory.service';
import { ICanBeBusy, IsBusy } from './../busyindicator/busyindicatorfactory.service';
import { IUserApiKey } from '../../interfaces/IUserApiKey';
import { IReturnState } from '../../interfaces/IReturnState';
import { IApiKey } from '../../interfaces/IApiKey';
import { IGlobal } from '../../interfaces/IGlobal';
import { IGlobalSettings } from '../../interfaces/IGlobalSettings';
import { application } from '../../globals';
import { IApiAccessSlack } from '../../interfaces/IApiAccessSlack';

@Injectable({
  providedIn: 'root',
})

export class LoggerService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();
  private http: HttpWorker;

  private name: string = '__default__';

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
  }

  /**
   * Writes an info-log.
   */
  public Info(_message: string)
  {
    this.http.post<IReturnState>(application.buildApi(this.baseUrl, `system/log/${this.name}/info`), { Text: _message })
      .subscribe(result => { }, error => console.error(error));
  };

  /**
   * Writes an debug-log.
   */
  public Debug(_message: string)
  {
    this.http.post<IReturnState>(application.buildApi(this.baseUrl, `system/log/${this.name}/debug`), { Text: _message })
      .subscribe(result => { }, error => console.error(error));
  };

  /**
   * Writes an error-log.
   */
  public Error(_message: string)
  {
    this.http.post<IReturnState>(application.buildApi(this.baseUrl, `system/log/${this.name}/error`), { Text: _message })
      .subscribe(result => { }, error => console.error(error));
  };

  /**
   * Writes an fatal-log.
   */
  public Fatal(_message: string)
  {
    this.http.post<IReturnState>(application.buildApi(this.baseUrl, `system/log/${this.name}/fatal`), { Text: _message })
      .subscribe(result => { }, error => console.error(error));
  };

  /**
   * Writes an trace-log.
   */
  public Trace(_message: string)
  {
    this.http.post<IReturnState>(application.buildApi(this.baseUrl, `system/log/${this.name}/trace`), { Text: _message })
      .subscribe(result => { }, error => console.error(error));
  };

  /**
   * Writes an warn-log.
   */
  public Warn(_message: string)
  {
    this.http.post<IReturnState>(application.buildApi(this.baseUrl, `system/log/${this.name}/warn`), { Text: _message })
      .subscribe(result => { }, error => console.error(error));
  };
}
