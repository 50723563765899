import { ISettings } from '../interfaces/ISettings';

export default class Settings implements ISettings
{
  fallbackCulture: string;
  standardUrl: string;
  browserLanguageDetectionEnabled: boolean;
  crossFadingDurationMs: number;
  defaultActivityDurationSec: number;
  silentVideoPlayback: boolean;
  imageUploadResizeOption: string;
  sessionStayAlive: boolean;
}
