<div class="component-container" fxLayout="column">

  <h1>{{options.title}}</h1>

  <div class="component-content-container">

    <div fxLayout="row" fxLayoutAlign="space-around none">

      <div fxFlex="auto" class="b-form-container">

        <p>{{options.message}}</p>

      </div>
      <div fxFlex></div>

    </div>

  </div>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button *ngIf="options.actionYes" (click)="handleAction('yes')">Yes</button>
    <button mat-button *ngIf="options.actionNo" (click)="handleAction('no')">No</button>
    <button mat-button *ngIf="options.actionOk" (click)="handleAction('ok')">Ok</button>
    <button mat-button *ngIf="options.actionCancel" (click)="handleAction('cancel')">Cancel</button>
  </mat-toolbar>

</div>
