import { IApiKey } from '../interfaces/IApiKey';

export default class ApiKey implements IApiKey
{
  id: number;
  name: string;
  key: string;
  disabled: boolean;
  expirationDate: string;
  creationDate: string;
  userID: string;
}
