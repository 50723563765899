import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogComponent } from './../dialog/dialog.component';
import { UserSingleComponent } from '../user-single/user-single.component';

import { application } from './../core/globals';
import { SecurityService } from './../core/services/common/security.service';
import { DateTimeService } from './../core/services/common/datetime.service';
import { HttpWorkerFactoryService, HttpWorker } from './../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { UsersService } from './../core/services/data/users.service';
import { IUserToEdit } from '../core/interfaces/IUserToEdit';
import { IReturnState } from '../core/interfaces/IReturnState';

@Component({
  selector: 'app-users-summary',
  templateUrl: './users-summary.component.html',
  styleUrls: ['./users-summary.component.css']
})

export class UsersSummaryComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogEditUser: MatDialogRef<UserSingleComponent>;

  private displayColumnsAll: string[] = ['name', 'email', 'edit'];
  public displayedColumns: string[] = this.displayColumnsAll;

  private userID: number = null;
  public users: IUserToEdit[] = [];
  public usersDataSource = new MatTableDataSource(this.users);

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private usersService: UsersService) {
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
    this.busyIndicator.Register(this.usersService);
  };

  private ngOnInit() {
    if (!this.security.CheckForRoles(true, [])) {
      return;
    }

    this.loadUsers();
  };

  private loadUsers() {
    this.usersService.GetUsers().subscribe((_users) => {
      this.users = _users;
      this.usersDataSource = new MatTableDataSource(this.users);
    });
  };

  public deleteUser(_user: IUserToEdit) {
    this.userID = _user.userID;
    this.usersService.CanDelete(_user).subscribe(_state => {
      if (_state === 1) {
        this.snackBarRef = this.snackBar.open(application.getRawText('pwp.users.summary.delete.error.isadmin.msg'), application.getRawText('common.close.msg'));
        return;
      }
      else if (_state === 2) {
        this.snackBarRef = this.snackBar.open(application.getRawText('pwp.users.summary.delete.error.hasapikeys.msg'), application.getRawText('common.close.msg'));
        return;
      }

      this.dialogGeneric = this.dialog.open(DialogComponent, {
        autoFocus: true,
        height: '250px',
        width: '550px'
      });
      this.dialogGeneric.componentInstance.options.actionYes = true;
      this.dialogGeneric.componentInstance.options.actionNo = true;
      this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
      this.dialogGeneric.componentInstance.options.message = application.getRawText('pwp.users.summary.delete.msg');
      this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
        if (_action.action !== 'yes') {
          return;
        }

        this.usersService.DeleteUser(_user.userID).subscribe(_returnState => {
          var result = _returnState as IReturnState;
          if (result.success) {
            this.loadUsers();
            this.snackBarRef = this.snackBar.open(application.getRawText('pwp.users.summary.deleted.msg'), application.getRawText('common.close.msg'));
          }
          else {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        });

      });

    });
  };

  public editUser(_user: IUserToEdit) {
    this.userID = _user?.userID;
    this.dialogEditUser = this.dialog.open(UserSingleComponent, {
      autoFocus: true,
      height: '600px',
      width: '850px'
    });
    this.dialogEditUser.componentInstance.userID = _user?.userID;
    this.dialogEditUser.componentInstance.initUser();
    this.dialogEditUser.componentInstance.onClose.subscribe(() => {
      this.dialogEditUser.close();
      this.loadUsers();
    });
  };

}
