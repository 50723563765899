<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="apiKeyID" localize="pwp.apikey.single.header.title.edit"></h1>
  <h1 *ngIf="!apiKeyID||apiKeyID===0" localize="pwp.apikey.single.header.title.new"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.cancel.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container wrapper">

      <mat-form-field>
        <input matInput [placeholder]="textName" [(ngModel)]="apiKey.name">
      </mat-form-field>

      <mat-checkbox [(ngModel)]="apiKey.disabled">
        <span localize="$:pwp.apikey.single.form.disabled.title"></span>
      </mat-checkbox>

      <mat-form-field>
        <input matInput
               [matDatepicker]="expirationDate"
               [placeholder]="textExpirationDate"
               [(ngModel)]="apiKey.expirationDate">
        <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
        <mat-datepicker #expirationDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-select [placeholder]="textUser" [(ngModel)]="apiKey.userID">
          <mat-option *ngFor="let u of users" [value]="u.userID">{{u.name}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </div>
