<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="slackId" localize="pwp.slack.single.header.edit"></h1>
  <h1 *ngIf="!slackId||slackId===0" localize="pwp.slack.single.header.new"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:pwp.slack.single.toolbar.configure.label" (click)="configureSlack()">
      <icon icon="Cog"></icon>
    </button>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.cancel.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container wrapper">

      <mat-form-field>
        <input matInput [placeholder]="textName" [(ngModel)]="item.name">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textAppID" [(ngModel)]="item.appID">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textClientID" [(ngModel)]="item.clientID">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textClientSecret" [(ngModel)]="item.clientSecret">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textSigningSecret" [(ngModel)]="item.signingSecret">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textScope" [(ngModel)]="item.scope">
      </mat-form-field>

      <!--  -->

      <mat-form-field>
        <input matInput [placeholder]="textUserAccessToken" disabled [(ngModel)]="item.userAccessToken">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textAccessToken" disabled [(ngModel)]="item.accessToken">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textResponseDate" disabled value="{{ item.responseDate | date:'short':'UTC' }}">
      </mat-form-field>

      <mat-checkbox disabled [(ngModel)]="item.success">
        <label localize="$:pwp.slack.single.form.success.label"></label>
      </mat-checkbox>

    </div>

  </div>
