//
// ===== File globals.ts    
//
'use strict';

import { IGlobal } from './interfaces/IGlobal';
import { ISystemTextExtended } from './interfaces/ISystemTextExtended';

export let application: IGlobal = {
  ADMIN: 'A',
  DEV: 'D',
  TASK_CREATOR: 'TC',// TECHNICAL_WRITER
  TRANSLATOR: 'T',

  version: '0.0.0.0',
  user: '<no user>',
  isAdmin: false,

  apiUrl: 'api/pwp/',

  currentCulture: '',

  texts: [],

  globalSettings: null,

  //roleAccessObjects: [],

  technicalRoleNames: null,

  //setRoleAccessObjects(_rao: RoleAccessObjects[])
  //{
  //    this.roleAccessObjects = _rao;
  //},

  hasRole(_techRoleName: string): boolean
  {
    var has = this.technicalRoleNames !== null && this.technicalRoleNames.indexOf(_techRoleName) >= 0;
    return has;
  },

  missingRole(_techRoleName: string): boolean
  {
    var missing = !this.hasRole(_techRoleName);
    return missing;
  },

  setTexts(_texts: ISystemTextExtended[])
  {
    this.texts = _texts;
  },

  getRawText(_textName: string): string
  {
    var culture = this.currentCulture;

    for (var i = 0; i < this.texts.length; i++)
    {
      if (this.texts[i].requestedCulture === culture && this.texts[i].name === _textName)
      {
        return this.texts[i].text;
      }
    }

    return '<TEXT MISSING>';
  },

  setCulture(_culture: string)
  {
    this.currentCulture = _culture;
  },

  /**
   * Builds the url for pwp-api-calls.
   * @param _basePath
   * @param _api
   */
  buildApi(_basePath: string, _api: string): string
  {
    return _basePath + this.apiUrl + _api;
  },

  /**
   * Gets whether the named feature is enabled.
   * @param _featureName
   */
  featureEnabled(_featureName: string): boolean
  {
    if (this.globalSettings === null)
      return false;

    var enabled = false;

    for (var i = 0; i < this.globalSettings.features.length; i++)
    {
      var feature = this.globalSettings.features[i];
      if (feature.name !== null && feature.name.toLowerCase() === _featureName.toLowerCase())
      {
        enabled = feature.enabled;
        break;
      }
    }

    return enabled;
  }

};
