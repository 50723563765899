import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IGlobal } from '../../../../../../app-pwp/src/app/core/interfaces/IGlobal';
import { IGlobalEvision5 } from '../../interfaces/IGlobalEvision5';
import { IGlobalSettings } from '../../../../../../app-pwp/src/app/core/interfaces/IGlobalSettings';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { IsBusy, ICanBeBusy } from '../../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { GlobalSettingsService } from '../../../../../../app-pwp/src/app/core/services/data/globalsettings.service';
import { ApiKeysService } from '../../../../../../app-pwp/src/app/core/services/data/apikeys.service';
import { IMediaJobWithGrouping } from '../../interfaces/IMediaJobWithGrouping';
import { IReturnState } from '../../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IMediaJob } from '../../interfaces/IMediaJob';
import { IMediaJobSelectable } from '../../interfaces/IMediaJobSelectable';
import { evision5 } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class MediaJobsService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();

  private http: HttpWorker;
  private globalSettings: IGlobalSettings;

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private globalSettingsService: GlobalSettingsService,
    private apiKeyService: ApiKeysService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
    this.IsBusy.PassThrough(this.globalSettingsService);
    this.IsBusy.PassThrough(this.apiKeyService);

    this.globalSettingsService.GetSettings().subscribe(_state =>
    {
      this.globalSettings = _state;
    });
  }

  /**
   * Gets all scenes.
   */
  public GetScenes(_archived: boolean): EventEmitter<IMediaJobWithGrouping[]>
  {
    return this.getMediaJobs(true, _archived);
  };

  /**
   * Gets all scenes.
   */
  public GetJobs(_archived: boolean): EventEmitter<IMediaJobWithGrouping[]>
  {
    return this.getMediaJobs(false, _archived);
  };

  private getMediaJobs(_scenes: boolean, _archived: boolean)
  {
    let onDone = new EventEmitter<IMediaJobWithGrouping[]>();

    var url = _scenes
      ? 'mediajobs/scenes'
      : 'mediajobs';
    url = _archived
      ? url + '/archived'
      : url;


    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, url))
      .subscribe(result =>
      {
        var tmp = result.data as IMediaJobWithGrouping[];
        onDone.emit(tmp);

      }, error => console.error(error));

    return onDone;
  };

  /**
   * Gets a mediaJob.
   * @param _mediaJobID
   */
  public GetJob(_mediaJobID: string): EventEmitter<IMediaJob>
  {
    return this.getMediaJob(_mediaJobID, false);
  };

  /**
 * Gets a mediaJob.
 * @param _mediaJobID
 */
  public GetScene(_mediaJobID: string): EventEmitter<IMediaJob>
  {
    return this.getMediaJob(_mediaJobID, true);
  };

  private getMediaJob(_mediaJobID: string, _scene: boolean): EventEmitter<IMediaJob>
  {
    var onDone = new EventEmitter<IMediaJob>();

    var controller = _scene
      ? 'scene'
      : 'mediajob';

    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, controller + '/' + _mediaJobID))
      .subscribe(result =>
      {
        var tmp = result.data as IMediaJob;
        onDone.emit(tmp);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Saves a mediaJob.
   * @param _mediaJob
   */
  public SaveJob(_mediaJob: IMediaJob, _mediaJobID: string): EventEmitter<IReturnState>
  {
    return this.save(_mediaJob, _mediaJobID, false);
  };

  /**
   * Saves a scene.
   * @param _mediaJob
   */
  public SaveScene(_mediaJob: IMediaJob, _mediaJobID: string): EventEmitter<IReturnState>
  {
    return this.save(_mediaJob, _mediaJobID, true);
  };

  private save(_mediaJob: IMediaJob, _mediaJobID: string, _scene: boolean): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var action = _scene
      ? 'scene'
      : 'mediajob';

    var url = _mediaJobID !== null
      ? evision5.buildApi(this.baseUrl, action + '/' + _mediaJob.mediaJobID)
      : evision5.buildApi(this.baseUrl, action + '');

    this.http.post<IReturnState>(url, _mediaJob)
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };


  /**
   * Deletes a mediaJob.
   * @param _mediaJob
   */
  public DeleteJob(_mediaJobID: string): EventEmitter<IReturnState>
  {
    return this.delete(_mediaJobID, false);
  };

  /**
   * Deletes a scene.
   * @param _mediaJob
   */
  public DeleteScene(_mediaJobID: string): EventEmitter<IReturnState>
  {
    return this.delete(_mediaJobID, true);
  };

  private delete(_mediaJobID: string, _scene: boolean): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var action = _scene
      ? 'scene'
      : 'mediajob';

    var url = evision5.buildApi(this.baseUrl, action + '/' + _mediaJobID);

    this.http.delete<IReturnState>(url, {})
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Gets all jobs of a scene.
   * @param _sceneID
   */
  public GetJobsOfScene(_sceneID: string): EventEmitter<IMediaJob[]>
  {
    var onDone = new EventEmitter<IMediaJob[]>();

    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'scenes/' + _sceneID + '/mediajobs'))
      .subscribe(result =>
      {
        var tmp = result.data as IMediaJob[];

        if (tmp !== null)
        {
          tmp.sort((_a, _b) => _a.jobName.localeCompare(_b.jobName));
        }

        onDone.emit(tmp);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Saves selected mediaJobs to a scene.
   * @param _sceneID
   * @param _jobs
   */
  public SaveJobsToScene(_sceneID: string, _jobs: IMediaJobSelectable[]): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    var ids = [];
    for (var i = 0; i < _jobs.length; i++)
    {
      if (_jobs[i].selected)
      {
        ids.push(_jobs[i].mediaJobID);
      }
    }

    this.http.post<IReturnState>(evision5.buildApi(this.baseUrl, 'scenes/' + _sceneID + '/mediajobs'), ids)
      .subscribe(result =>
      {
        onDone.emit(result);
      }, error => console.error(error));

    return onDone;
  };


  /**
   * Saves selected mediaJobs to a scene.
   * @param _sceneID
   * @param _jobs
   */
  public RemoveJobFromScene(_sceneID: string, _jobID: string): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    this.http.delete<IReturnState>(evision5.buildApi(this.baseUrl, 'scenes/' + _sceneID + '/mediajob/' + _jobID), {})
      .subscribe(result =>
      {
        onDone.emit(result);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Starts a job/scene.
   * @param _mediaJobID
   */
  public Start(_mediaJobID: string): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediajob/' + _mediaJobID + '/start'), {})
      .subscribe(
        result =>
        {
          onDone.emit(result);
        },
        error => console.error(error));

    return onDone;
  };

  /**
   * Stops a job/scene.
   * @param _mediaJobID
   */
  public Stop(_mediaJobID: string): EventEmitter<IReturnState>
  {
    var onDone = new EventEmitter<IReturnState>();

    this.http
      .post<IReturnState>(evision5.buildApi(this.baseUrl, 'mediajob/' + _mediaJobID + '/stop'), {})
      .subscribe(
        result =>
        {
          onDone.emit(result);
        },
        error => console.error(error));

    return onDone;
  };
}
