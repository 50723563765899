<div class="component component-container" fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="settings.header.edit"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button *ngIf="activeTabIndex===0" mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button *ngIf="activeTabIndex===2" mat-button localize="title:settings.panel.reloadlogs.title" (click)="loadLogs()">
      <icon icon="Sync"></icon>
    </button>
  </mat-toolbar>

  <div class="component-container">

    <p *ngIf="!settings"><em localize="common.loadingdata.msg"></em></p>

    <div fxFlex="100%" class="b-form-container wrapper">

      <mat-tab-group (selectedTabChange)="selectedTabChangeHandler($event)">
        <mat-tab [label]="textTabSettings">

          <div class="component-content-container"
               (keydown)="keyHandlerSettings.TriggerOnKeyDown($event)"
               tabindex="0">
            <div class="b-form-container" fxFlex="400px">

              <mat-form-field>
                <input matInput
                       [placeholder]="textStandardUrl"
                       [(ngModel)]="settings.standardUrl">
              </mat-form-field>

              <mat-form-field>
                <mat-select matInput
                            [placeholder]="textFallbackCulture"
                            [(ngModel)]="settings.fallbackCulture">
                  <mat-option *ngFor="let l of languages" [value]="l.culture">{{ l.name }}</mat-option>
                </mat-select>
              </mat-form-field>

              <div class="b-form-container">
                <mat-checkbox [(ngModel)]="settings.browserLanguageDetectionEnabled">
                  <span localize="usersettings.single.form.use-browser-language-detection.msg"></span>
                </mat-checkbox>
              </div>

              <div class="b-form-container">
                <mat-checkbox [(ngModel)]="settings.sessionStayAlive">
                  <span localize="usersettings.single.form.sessionStayAlive.msg"></span>
                </mat-checkbox>
              </div>

              <mat-form-field>
                <input matInput
                       [placeholder]="textActivityDuration"
                       [(ngModel)]="settings.defaultActivityDurationSec">
              </mat-form-field>

              <mat-form-field>
                <input matInput
                       [placeholder]="textCrossFading"
                       [(ngModel)]="settings.crossFadingDurationMs">
              </mat-form-field>

              <div class="b-form-container">
                <mat-checkbox [(ngModel)]="settings.silentVideoPlayback">
                  <span localize="usersettings.single.form.silentVideoPlayback.msg"></span>
                </mat-checkbox>
              </div>

              <mat-form-field>
                <mat-select matInput
                            [placeholder]="textResizeUploadedImage"
                            [(ngModel)]="settings.imageUploadResizeOption">
                  <mat-option *ngFor="let r of imageUploadResizeOptions" [value]="r.systemname">{{ r.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

        </mat-tab>
        <mat-tab [label]="textTabApiKeys">

          <app-apikeys-summary></app-apikeys-summary>

        </mat-tab>
        <mat-tab [label]="textTabLog">

          <div class="component-content-container abs logcontainer">

            <table mat-table [dataSource]="logs">

              <!--<ng-container matColumnDef="userDisplayName">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let element">{{ element.userDisplayName }}</td>
              </ng-container>-->
              


              <!--ne-->

              <ng-container matColumnDef="level">
                <th mat-header-cell *matHeaderCellDef class="column-level">Level</th>
                <td mat-cell *matCellDef="let element" class="column-level">{{ element.level }}</td>
              </ng-container>

              <ng-container matColumnDef="createDate">
                <th mat-header-cell *matHeaderCellDef class="column-create">Datetime</th>
                <td mat-cell *matCellDef="let element" class="column-create">{{ element._ErzeugtAm | date:'short' }}</td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="column-name">Name</th>
                <td mat-cell *matCellDef="let element" class="column-name">{{ element.name }}</td>
              </ng-container>

              <ng-container matColumnDef="text">
                <th mat-header-cell *matHeaderCellDef class="column-text">Text</th>
                <td mat-cell *matCellDef="let element" class="column-text">{{ element.text }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="logsDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: logsDisplayedColumns;" [class]="row.level"></tr>

            </table>

          </div>

        </mat-tab>
        <mat-tab [label]="textTabSlack">

          <app-slack-summary></app-slack-summary>

        </mat-tab>
      </mat-tab-group>

    </div>

  </div>

</div>
