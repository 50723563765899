<div class="component-container component" fxLayout="column" (keydown)="keyHandler.TriggerOnKeyDown($event)"
  tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="!mediaActivityID" localize="mediaactivity.single.header.new"></h1>
  <h1 *ngIf="mediaActivityID" localize="mediaactivity.single.header.edit"></h1>

  <mat-toolbar>
    <button mat-button localize="title:mediaactivity.single.toolbar.filexplorer.edit" (click)="openFileExplorer()">
      <icon icon="FolderOpen"></icon>
    </button>
    <button mat-button localize="title:mediaactivity.single.toolbar.rule.edit" (click)="openRuleDialog()">
      <icon icon="Cog"></icon>
    </button>
    <span class="toolbar-spacer"></span>
    <a mat-button *ngIf="mediaActivity.mediaUrl!=null&&mediaActivity.mediaUrl!=''"
      localize="title:mediaactivity.single.toolbar.preview.title" [href]="mediaActivity.mediaUrl" target="_blank">
      <icon icon="PlayCircle"></icon>
    </a>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save(false)">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.back.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!mediaActivity"><em localize="common.loadingdata.msg"></em></p>

    <div fxFlex="500px" class="b-form-container wrapper">

      <mat-form-field>
        <input matInput [placeholder]="textTitle" [(ngModel)]="mediaActivity.title">
      </mat-form-field>

      <mat-form-field>
        <mat-select [placeholder]="textMediaLayout" [(ngModel)]="mediaActivity.mediaLayoutID">
          <mat-option *ngFor="let ml of mediaLayouts" [value]="ml.mediaLayoutID">{{ml.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input #inputurl matInput [placeholder]="textUrl" [(ngModel)]="mediaActivity.mediaUrl"
          (focusout)="onUrlChange()">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textDuration" [(ngModel)]="mediaActivity.duration">
      </mat-form-field>

      <mat-form-field *ngIf="inputurl.value.toLowerCase().endsWith('.pdf')"
        localize="title:mediaactivity.single.form.secondsperpage.title.tt">
        <input matInput [placeholder]="textSecondsPerPage" [(ngModel)]="mediaActivity.secondsPerPage">
      </mat-form-field>

      <!--      <img [src]="screenCaptureData" height="200" width="200" />-->

      <div class="b-form-container">
        <!--<websiteWrapper class="preview-web"
                        (onCaptureDone)="onCaptureDoneHandler($event)"
                        [url]="mediaActivity.mediaUrl">
        </websiteWrapper>-->
        <videoWrapper *ngIf="isVideo()" class="preview" (onDurationChanged)="onDurationChangedHandler($event)"
          (onCaptureDone)="onCaptureDoneHandler($event)" [videoFormats]="settings.videoFormatsValue"
          [webpresenterMediaDirectoryHttp]="settings.webpresenterMediaDirectoryHttpValue"
          [url]="mediaActivity.mediaUrl">
        </videoWrapper>
      </div>

      <div class="b-form-container wrapper">
        <app-widget-single [WidgetPath]="mediaActivity.mediaUrl" (onUrlChanged)="onWidgetUrlChanged($event)">
        </app-widget-single>
      </div>

    </div>

  </div>

</div>