<table *ngIf="widgets&&widgets.length>0" mat-table [dataSource]="widgets">

  <ng-container matColumnDef="icon">
    <th mat-header-cell *matHeaderCellDef class="column-icon"></th>
    <td mat-cell *matCellDef="let element" class="column-icon">
      <span class="preview" [ngStyle]="{'background-image': 'url(\'' + getIconUrl(element) + '\')'}"></span>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef class="column-date"><span localize="widgets.summary.grid.header.name.lbl"></span></th>
    <td mat-cell *matCellDef="let element" class="column-date">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef class="cell-buttons one-button"></th>
    <td mat-cell *matCellDef="let element" class="cell-buttons one-button">
      <button mat-button
              *ngIf="showApplyButton"
              (click)="applyWidget(element)"
              localize="title:widgets.summary.grid.header.apply.lbl">
        <icon icon="Check"></icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedFilesColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedFilesColumns;"></tr>

</table>
