import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { HttpWorkerFactoryService, HttpWorker } from './../http/httpworkerfactory.service';
import { ICanBeBusy, IsBusy } from './../busyindicator/busyindicatorfactory.service';
import { IGlobalSettings } from '../../interfaces/IGlobalSettings';
import { IReturnState } from '../../interfaces/IReturnState';
import { application } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class GlobalSettingsService implements ICanBeBusy
{
  private http: HttpWorker;

  public IsBusy: IsBusy = new IsBusy();

  constructor(private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private httpWorkerFactory: HttpWorkerFactoryService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
  }

  public GetSettings(): EventEmitter<IGlobalSettings>
  {
    let onDone = new EventEmitter<IGlobalSettings>();

    this.http
      .get<IReturnState>(application.buildApi(this.baseUrl, 'globalsettings'))
      .subscribe(result =>
      {
        var settings = result.data as IGlobalSettings;
        onDone.emit(settings);
      }, error => console.error(error));

    return onDone;
  };

}
