<div class="component component-container" fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1>
    <span localize="mediaactivities.summary.header.01" *ngIf="mediaList?.linkedList===false"></span>
    <span localize="mediaactivities.summary.header.01.linkedlist" *ngIf="mediaList?.linkedList===true"></span>
    <span>{{mediaList?.listName}}</span>
    <span localize="mediaactivities.summary.header.02"></span>&nbsp;
    <span localize="mediaactivities.summary.header.linkedlist" *ngIf="mediaList?.linkedList"></span>
  </h1>

  <mat-toolbar>
    <button mat-button *ngIf="mediaList?.linkedList===false"
      localize="title:mediaactivities.summary.toolbar.setduration.title" (click)="setDuration()">
      <icon icon="Clock"></icon>
    </button>
    <button mat-button localize="title:mediaactivities.summary.toolbar.edit.title" (click)="editMediaList()">
      <icon icon="Info"></icon>
    </button>
    <span class="toolbar-spacer"></span>
    <span class="timelinetoggle" localize="$:mediaactivities.summary.toolbar.timeline.toggle.grid.title"
      *ngIf="mediaList?.linkedList===false"></span>
    &nbsp;
    <mat-slide-toggle class="timelinetoggle" [(ngModel)]="isTimeline" (change)="onTimelineViewChangeHandler()"
      *ngIf="mediaList?.linkedList===false">
    </mat-slide-toggle>
    &nbsp;
    <span class="timelinetoggle" localize="$:mediaactivities.summary.toolbar.timeline.toggle.timeline.title"
      *ngIf="mediaList?.linkedList===false"></span>
    <span class="toolbar-spacer"></span>
    <button mat-button *ngIf="mediaList?.linkedList===false"
      localize="title:mediaactivities.summary.toolbar.newmediaactivity.title" (click)="editMediaActivity(null)">
      <icon icon="Plus"></icon>
    </button>
    <button mat-button *ngIf="mediaList?.linkedList===true"
      localize="title:mediaactivities.summary.toolbar.newmedialist.title" (click)="setLinkedLists()">
      <icon icon="Pen"></icon>
    </button>
    <button mat-button localize="title:common.back.msg" routerLink="/medialists">
      <icon icon="ArrowLeft"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!data"><em localize="common.loadingdata.msg"></em></p>

    <table *ngIf="isTimeline===false||mediaList?.linkedList===true" mat-table [dataSource]="data" cdkDropList
      (cdkDropListDropped)="rowDroppedHandler($event)">

      <ng-container matColumnDef="rank">
        <th mat-header-cell *matHeaderCellDef class="column-rank"></th>
        <td mat-cell *matCellDef="let element" class="column-rank">{{ element.rank }}.</td>
      </ng-container>

      <ng-container matColumnDef="sorthandle">
        <th mat-header-cell *matHeaderCellDef class="cell-sorthandle"></th>
        <td mat-cell *matCellDef="let element" class="cell-sorthandle">
          <span cdkDragHandle>
            <icon icon="EllipsesVertical"></icon>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="preview">
        <th mat-header-cell *matHeaderCellDef class="cell-preview"></th>
        <td mat-cell *matCellDef="let element" class="cell-preview">
          <span class="preview"
            [ngStyle]="{'background-image': 'url(\'' + getMediaUrlPathFromActivity(element) + '\')'}"></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="previewlist">
        <th mat-header-cell *matHeaderCellDef class="cell-preview"></th>
        <td mat-cell *matCellDef="let element" class="cell-preview">
          <span class="preview"
            [ngStyle]="{'background-image': 'url(\'' + getMediaUrlPath(element.firstMediaUrl) + '\')'}"></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="down">
        <th mat-header-cell *matHeaderCellDef class="cell-buttons one-button"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons one-button">
          <button mat-button *ngIf="element.rank<maxRank" (click)="moveDown(element)"
            localize="title:mediaactivities.summary.grid.movedown.btn.title">
            <icon icon="ArrowDown"></icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="up">
        <th mat-header-cell *matHeaderCellDef class="cell-buttons one-button"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons one-button">
          <button mat-button *ngIf="element.rank>minRank" (click)="moveUp(element)"
            localize="title:mediaactivities.summary.grid.moveup.btn.title">
            <icon icon="ArrowUp"></icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef localize="mediaactivities.summary.grid.title.header.msg"></th>
        <td mat-cell *matCellDef="let element">
          <a *ngIf="mediaList.linkedList" [routerLink]="['/medialists', element.mediaListID, 'mediaactivities']"
            target="_blank">
            {{ element.title }}
          </a>
          <a *ngIf="mediaList.linkedList===false" localize="title:mediaactivities.summary.grid.title.header.link.title"
            [href]="getPreviewLink(element)" target="_blank">
            {{ element.title }}
          </a>
        </td>
      </ng-container>



      <ng-container matColumnDef="durationFormatted">
        <th mat-header-cell *matHeaderCellDef class="column-duration"
          localize="mediaactivities.summary.grid.duration.header.msg"></th>
        <td mat-cell *matCellDef="let element" class="column-duration">{{ element.durationFormatted }}</td>
      </ng-container>

      <ng-container matColumnDef="durationLinkedListFormatted">
        <th mat-header-cell *matHeaderCellDef class="column-duration"
          localize="mediaactivities.summary.grid.duration.header.msg"></th>
        <td mat-cell *matCellDef="let element" class="column-duration">{{ element.durationSumFormatted }}</td>
      </ng-container>


      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="cell-buttons two-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons two-buttons">
          <button mat-button (click)="deleteItem(element)"
            localize="title:mediaactivities.summary.grid.delete.btn.title">
            <icon icon="Trash"></icon>
          </button>
          <button mat-button *ngIf="mediaList?.linkedList===false" (click)="editMediaActivity(element.mediaActivityID)"
            localize="title:mediaactivities.summary.grid.edit.btn.title">
            <icon icon="Pen"></icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag
        [ngClass]="{'selected':mediaActivityID===row.mediaActivityID}"></tr>

    </table>

    <div *ngIf="isTimeline===true&&mediaList?.linkedList===false">
      <icon (click)="factorDown()" icon="ZoomMinus">
      </icon>
      <mat-slider min="1" max="25" step="1" [(ngModel)]="timelineFactor"></mat-slider>
      <icon (click)="factorUp()" icon="ZoomPlus">
      </icon>
      <!--input matInput placeholder="Timeline-factor" [(ngModel)]="timelineFactor"-->
      <!--<mat-form-field>

      </mat-form-field>-->

      <div class="timeline" cdkDropList cdkDropListOrientation="horizontal">
        <div class="mediaactivity" *ngFor="let ma of data" [ngStyle]="{'width': (ma.duration * timelineFactor) + 'px'}"
          title="{{ma.title}} - {{ma.duration}} {{textUnitSeconds}}" cdkDrag>
          <span class="drag" localize="title:mediaactivities.summary.toolbar.timeline.drag.title">
            <icon cdkDragHandle icon="EllipsesVertical"></icon>
          </span>
          <span class="title">{{ma.title}}</span>
          <span class="duration"><span
              localize="mediaactivities.summary.toolbar.timeline.duration.title"></span>&nbsp;{{ma.duration}}
            {{textUnitSeconds}}</span>
          <span class="preview"
            [ngStyle]="{'background-image': 'url(\'' + getMediaUrlPathFromActivity(ma) + '\')'}"></span>
        </div>
      </div>
      <ul class="timeline-scale">
        <li class="step" *ngFor="let step of timelineActivity"
          [ngStyle]="{'width': (step.value * timelineFactor) + 'px'}">
          <span class="icon">
            <icon icon="Play"></icon>
          </span>
          <span class="value">{{step.total}} {{textUnitSeconds}}</span>
        </li>
      </ul>
      <ul class="timeline-scale obj">
        <li class="step" *ngFor="let step of timelineSteps" [ngStyle]="{'width': (step.value * timelineFactor) + 'px'}">
          <span class="value">{{step.total}} {{textUnitSeconds}}</span>
        </li>
      </ul>
    </div>

  </div>

</div>