import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { DateTimeService } from '../../../../app-pwp/src/app/core/services/common/datetime.service';
import { MediaDevicesService } from '../core/services/data/mediadevices.service';

@Component({
  selector: 'app-mediadevice-activation',
  templateUrl: './mediadevice-activation.component.html',
  styleUrls: ['./mediadevice-activation.component.css']
})

export class MediaDeviceActivationComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  public activationTimeout: number = 10;
  public createNewDeviceSecret: boolean = false;
  public mediaDeviceID: string;

  public activationCode: string;

  public onSaved = new EventEmitter();

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  dialogGeneric: MatDialogRef<DialogComponent>;

  textPlaceholderActivationTimeout: string = application.getRawText('mediadevice.activationdialog.activationtimeout');
  textStep1: string = application.getRawText('mediadevice.activationdialog.step1');
  textStep2: string = application.getRawText('mediadevice.activationdialog.step2');
  textStep3: string = application.getRawText('mediadevice.activationdialog.step3');

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private route: ActivatedRoute,
    private mediaDevicesService: MediaDevicesService,
    private dateTimeService: DateTimeService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
  };

  private ngOnInit() {
  };

  public createActivationCode(stepper: MatStepper) {
    this.mediaDevicesService.CreateActivationKey(this.mediaDeviceID, this.createNewDeviceSecret).subscribe(
      (result: any) => {
        this.activationCode = result.data;
        this.next(stepper)
      },
      error => console.error(error));
  };

  public next(stepper: MatStepper) {
    stepper.next();
  }
}
