<div class="component component-container" fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="scenes.summary.header"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:scenes.summary.toolbar.new.title" (click)="edit(null)">
      <icon icon="Plus"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!mediaJobs"><em localize="common.loadingdata.msg"></em></p>

    <div fxLayout="row">

      <div fxFlex="250px" class="b-form-container in-one-row">

        <mat-checkbox [(ngModel)]="filterArchived" (change)="loadScenes()"><span
            localize="scenes.summary.filter.archived.msg"></span></mat-checkbox>

      </div>

    </div>

    <table mat-table [dataSource]="mediaJobs">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef localize="scenes.summary.grid.name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.jobName }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="column-status" localize="scenes.summary.grid.status.title"></th>
        <td mat-cell *matCellDef="let element" class="column-status">
          <span class="status-wrapper">
            <span class="centered">
              <span *ngIf="element.status===1" localize="title:scenes.summary.grid.status.running.title">
                <icon icon="Play"></icon>
              </span>
              <span *ngIf="element.status===2" localize="title:scenes.summary.grid.status.stopped.title">
                <icon icon="Stop"></icon>
              </span>
              <span *ngIf="element.status===4" localize="title:scenes.summary.grid.status.paused.title">
                <icon icon="Pause"></icon>
              </span>
              <span *ngIf="element.status!==4&&element.status!==2&&element.status!==1"
                localize="title:scenes.summary.grid.status.other.title">
                <icon icon="Clock"></icon>
              </span>
              <!--&nbsp;
              <span *ngIf="element.looped===true&&element.status!==1" localize="title:scenes.summary.grid.status.repeat.title" class="glyphicon glyphicon-repeat"></span>-->
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="startStopAction">
        <th mat-header-cell *matHeaderCellDef class="column-elementcount cell-buttons one-button"></th>
        <td mat-cell *matCellDef="let element" class="column-elementcount cell-buttons one-button">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button *ngIf="element.status===2" localize="title:scenes.summary.grid.action.start.title"
                (click)="start(element.mediaJobID)">
                <icon icon="Play"></icon>
              </button>
              <button mat-button *ngIf="element.status===4" localize="title:scenes.summary.grid.action.start.title"
                (click)="start(element.mediaJobID)">
                <icon icon="Play"></icon>
              </button>
              <button mat-button *ngIf="element.status===1" localize="title:scenes.summary.grid.action.stop.title"
                (click)="stop(element.mediaJobID)">
                <icon icon="Stop"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef class="column-start" localize="scenes.summary.grid.start.title"></th>
        <td mat-cell *matCellDef="let element" class="column-start">{{ element.startTime | date:'short':'UTC' }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons three-buttons">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button (click)="edit(element.mediaJobID)"
                localize="title:scenes.summary.grid.edit.info.title">
                <icon icon="Pen"></icon>
              </button>
              <button mat-button (click)="delete(element.mediaJobID)"
                localize="title:scenes.summary.grid.edit.delete.title">
                <icon icon="Trash"></icon>
              </button>
              <button mat-button [routerLink]="['/scenes',element.mediaJobID]"
                localize="title:scenes.summary.grid.edit.assignments.title">
                <icon icon="PlayCircle"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected':mediaJobID===row.mediaJobID}">
      </tr>

    </table>
  </div>

</div>