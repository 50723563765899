import { Directive, ElementRef, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { IGlobal } from '../../../app-pwp/src/app/core/interfaces/IGlobal';
import { SecurityService } from '../../../app-pwp/src/app/core/services/common/security.service';
import { UriBuilder, UriBuilderService } from '../../../app-pwp/src/app/core/services/common/uribuilder.service';

@Directive({
  selector: 'videoWrapper',
  inputs: ['url', 'videoFormats', 'showControls']
})

export class VideoWrapperDirective {
  private sourceUrl: string = null;

  private elVideo: HTMLVideoElement = null;
  private elSource: HTMLSourceElement = null;
  private canvas: HTMLCanvasElement = null;

  constructor(private el: ElementRef,
    private security: SecurityService,
    private uriBuilderService: UriBuilderService) {
    this.canvas = document.createElement('canvas');

    this.elVideo = document.createElement('video');
    this.elVideo.crossOrigin = 'anonymous';
    this.elVideo.style.height = '100%';
    this.elVideo.style.width = '100%';

    this.elVideo.muted = true;
    this.elVideo.autoplay = true; // Play is required to draw a screen capture.
    //this.elVideo.controls = true;


    this.elVideo.ondurationchange = () => {
      this.triggerDuration();
    };
    this.elVideo.oncanplaythrough = () => {
      this.triggerScreenshot();
    };

    this.el.nativeElement.append(this.elVideo);
  }

  triggerDuration() {
    let duration = this.elVideo.duration;
    if (duration !== duration)
      duration = null;
    this.onDurationChanged.emit(duration);
  };

  triggerScreenshot() {
    if (this.sourceUrl === null || this.sourceUrl === undefined || this.sourceUrl === '') {
      return;
    }

    let scale = 1;
    this.canvas.width = 640;
    this.canvas.height = this.canvas.width * this.elVideo.clientHeight / this.elVideo.clientWidth;
    this.canvas.getContext('2d').drawImage(this.elVideo, 0, 0, this.canvas.width, this.canvas.height);

    var imageData = this.canvas.toDataURL('image/jpeg', 0.7);
    this.onCaptureDone.emit(imageData);
  };

  check() {
    if (this._url === null || this._url === undefined || this._url === '') {
      return;
    }

    var isVideo = false,
      formats = this.videoFormats !== null && this.videoFormats !== undefined
        ? this.videoFormats
        : [];
    for (var i = 0; i < formats.length; i++) {
      if (this._url.endsWith(formats[i])) {
        isVideo = true;
        break;
      }
    }

    if (!isVideo) {
      return;
    }

    if (!(this.showControls === null) && this.showControls === true) {
      this.elVideo.controls = true;
    }

    let isFullUrl = this.uriBuilderService.Get().StartsWithProtocol(this._url);
    let tmpUrl = isFullUrl
      ? this.uriBuilderService.Get().Parse(this._url).Build()
      : this.uriBuilderService.Get().Parse(this._webpresenterMediaDirectoryHttp).AddSegment(this._url).Build();
    this.sourceUrl = tmpUrl;
    this.elVideo.src = tmpUrl;

    this.triggerDuration();
    this.triggerScreenshot();
  };

  // --------------------------------------------------------------------------


  _showControls: boolean = null;

  @Input('showControls')
  set showControls(_value: boolean) { this._showControls = _value; this.check(); }
  get showControls() {
    return this._showControls;
  }

  // --------------------------------------------------------------------------

  _url: string;

  get url(): string { return this._url; }

  @Input('url')
  set url(_value: string) { this._url = _value; this.check(); }

  // --------------------------------------------------------------------------

  _webpresenterMediaDirectoryHttp: string = '';

  get webpresenterMediaDirectoryHttp(): string { return this._webpresenterMediaDirectoryHttp; }

  @Input('webpresenterMediaDirectoryHttp')
  set webpresenterMediaDirectoryHttp(_value: string) { this._webpresenterMediaDirectoryHttp = _value; this.check(); }

  // --------------------------------------------------------------------------

  _videoFormats: string[];

  get videoFormats(): string[] { return this._videoFormats; }

  @Input('videoFormats')
  set videoFormats(_value: string[]) { this._videoFormats = _value; this.check(); }

  // --------------------------------------------------------------------------

  @Output('onDurationChanged')
  onDurationChanged: EventEmitter<number> = new EventEmitter<number>();

  // --------------------------------------------------------------------------

  @Output('onCaptureDone')
  onCaptureDone: EventEmitter<string> = new EventEmitter<string>();
}
