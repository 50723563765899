import { IMediaActivityRule } from '../interfaces/IMediaActivityRule';

export default class MediaActivityRule implements IMediaActivityRule {
  mediaActivityRuleID: string;
  mediaActivityID: string;
  ruleDuration: number;
  moduleID: number;
  yearlyRecurrent: boolean;
  wholeDay: boolean;
}
