<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="articleID>0" localize="pwp.article.single.header.edit"></h1>
  <h1 *ngIf="articleID<=0" localize="pwp.article.single.header.new"></h1>

  <mat-toolbar>
    <button mat-button *ngIf="article.released===false" (click)="release(true)"><span localize="$:pwp.article.single.toolbar.publish.title"></span></button>
    <button mat-button *ngIf="article.released===true" (click)="release(false)"><span localize="$:pwp.article.single.toolbar.publish-reset.title"></span></button>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:pwp.article.single.toolbar.selectimage.title" (click)="openSelectImage()">
      <icon icon="FileImage"></icon>
    </button>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.cancel.msg" (click)="close()">
      <icon icon="ArrowLeft"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container">

      <mat-form-field>
        <input matInput [placeholder]="textTitle" [(ngModel)]="article.title">
      </mat-form-field>

      <mat-form-field>
        <mat-select [placeholder]="textModule" [(ngModel)]="article.moduleID">
          <mat-option *ngFor="let module of modules" [value]="module.moduleID">{{module.moduleTitle}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textShortDescription" [(ngModel)]="article.shortDescription">
      </mat-form-field>

      <mat-form-field>
        <mat-select [placeholder]="textRedirectType" [(ngModel)]="article.redirectType">
          <mat-option [value]="0"><span localize="$:pwp.article.single.form.redirecttypes.none.title"></span></mat-option>
          <mat-option [value]="1"><span localize="$:pwp.article.single.form.redirecttypes.permanent.title"></span></mat-option>
          <mat-option [value]="2"><span localize="$:pwp.article.single.form.redirecttypes.temporary.title"></span></mat-option>
          <mat-option [value]="3"><span localize="$:pwp.article.single.form.redirecttypes.directlink.title"></span></mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput [disabled]="article.redirectType===0" [placeholder]="textRedirectUrl" [(ngModel)]="article.redirectUrl">
      </mat-form-field>

      <mat-checkbox [ngModel]="article.startDate!==null"  (change)="onStartDateEnabledChanged()">
        <span localize="$:pwp.article.single.form.startdate.enabled.title"></span>
      </mat-checkbox>

      <div class="b-form-container" fxLayout="row">

        <mat-form-field>
          <input matInput [matDatepicker]="startDatePicker"
                 [placeholder]="textStartDate"
                 [(ngModel)]="article.startDate"
                 [disabled]="article.startDate===null">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="b-form-container nopadding" fxFlex="40%">
          <ngx-timepicker-field [format]="24"
                                [(ngModel)]="startTime"
                                [disabled]="article.startDate===null"></ngx-timepicker-field>
        </div>

        <div class="b-form-container" fxFlex="15%">
          <button mat-button
                  localize="title:pwp.article.single.form.startdate.fromnow.title,$:pwp.article.single.form.startdate.fromnow.title"
                  (click)="startFromNow()"
                  [disabled]="article.startDate===null"></button>
        </div>

      </div>

      <mat-checkbox [ngModel]="article.endDate!==null" (change)="onEndDateEnabledChanged()">
        <span localize="$:pwp.article.single.form.enddate.enabled.title"></span>
      </mat-checkbox>

      <div class="b-form-container" fxLayout="row">

        <mat-form-field>
          <input matInput [matDatepicker]="endDatePicker"
                 [placeholder]="textEndDate"
                 [(ngModel)]="article.endDate"
                 [disabled]="article.endDate===null">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="b-form-container nopadding" fxFlex="40%">
          <ngx-timepicker-field [format]="24"
                                [(ngModel)]="endTime"
                                [disabled]="article.endDate===null"></ngx-timepicker-field>
        </div>

        <div class="b-form-container" fxFlex="15%">
          <button mat-button
                  localize="title:pwp.article.single.form.enddate.tonow.title,$:pwp.article.single.form.enddate.tonow.title"
                  (click)="endToNow()"
                  [disabled]="article.endDate===null"></button>
        </div>

      </div>

    </div>
    <div fxFlex="800px" class="b-form-container">

      <div class="b-form-container">
        <span localize="$:pwp.article.single.form.text.title"></span>

        <!-- plugins: image media -->
        <editor apiKey="r6otx01d5e9090z66libgivrhq0d1t1zno8zb2brn7mjp3ak"
                [(ngModel)]="htmlText"
                [init]="{
     height: 500,
     menubar: true,
     plugins: [
       'advlist autolink lists link charmap print preview anchor',
       'searchreplace visualblocks code fullscreen',
       'insertdatetime table paste code help wordcount image-pwp'
     ],
     toolbar:
       'undo redo | formatselect | bold italic backcolor | \
       alignleft aligncenter alignright alignjustify | \
       bullist numlist outdent indent | removeformat | image-pwp | help'
   }"></editor>

      </div>

    </div>

  </div>

</div>
