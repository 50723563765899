<div class="component component-container" fxLayout="column" (keydown)="keyHandler.TriggerOnKeyDown($event)"
  tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="mediaListID" localize="medialist.single.header.edit"></h1>
  <h1 *ngIf="!mediaListID" localize="medialist.single.header.new"></h1>

  <mat-toolbar>
    <!--<button *ngIf="mediaList" mat-button 
      localize="title:medialist.single.toolbar.edit.title" [routerLink]="['/medialists', mediaList.mediaListID, 'mediaactivities']">
    <icon icon="Film"></icon"
    </button>-->
    <span class="toolbar-spacer"></span>
    <button mat-button
      *ngIf="(application.hasRole('contentmanager')||application.isAdmin)||((!application.hasRole('contentmanager')||!application.isAdmin)&&mediaListID)"
      localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.cancel.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container">

      <mat-form-field>
        <input matInput [placeholder]="textName" [(ngModel)]="mediaList.listName">
      </mat-form-field>

      <div class="b-form-container">
        <mat-checkbox [(ngModel)]="mediaList.linkedList" [disabled]="mediaListID"><span
            localize="medialist.single.form.linkedlist.title"></span></mat-checkbox>
      </div>

      <mat-form-field>
        <input matInput [placeholder]="textTotalDuration" title="Gesamtdauer aller Medien"
          [ngModel]="mediaList.durationSumFormatted" disabled>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textMediatypes" [ngModel]="mediaList.mediaTypes" disabled>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textElementCount" [ngModel]="mediaList.elementCount" disabled>
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textCreateDate" [ngModel]="mediaList.createDate | date: 'medium'" disabled>
      </mat-form-field>

    </div>
    <div fxFlex="250px" class="b-form-container" *ngIf="application.isAdmin">

      <h2 localize="$:medialist.single.permissions.title"></h2>

      <ul class="roles">
        <li class="role" *ngFor="let r of roles">
          <mat-checkbox [(ngModel)]="r.selected">{{ r.roleName }}</mat-checkbox>
        </li>
      </ul>

    </div>

  </div>

</div>