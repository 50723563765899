<div class="component component-container">
  <div class="component-content-container frame y-only">
    <mat-grid-list cols="6" rowHeight="90px">

      <mat-grid-tile [colspan]="cards?.welcome?.cols"
                     [rowspan]="cards?.welcome?.rows">
        <mat-card class="dashboard-card">
          <mat-card-header>
            <mat-card-title>
              <span class="title-frame">
                <span localize="$:dashboard.card.welcome.title"></span>
              </span>
              <!--<button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item>Expand</button>
            <button mat-menu-item>Remove</button>
          </mat-menu>-->
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="cards?.time?.cols"
                     [rowspan]="cards?.time?.rows">
        <mat-card class="dashboard-card datetime">
          <mat-card-header>
            <mat-card-title>
              <span class="title-frame">
                <span>{{time}}</span>
              </span>
              <!--<button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item>Expand</button>
            <button mat-menu-item>Remove</button>
          </mat-menu>-->
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="cards?.medialists?.cols"
                     [rowspan]="cards?.medialists?.rows">
        <mat-card class="dashboard-card">
          <mat-card-header>
            <mat-card-title>
              <span class="title-frame">
                <span class="title"
                      [routerLink]="['/medialists']"
                      localize="$:dashboard.card.medialists.title,title:dashboard.card.common.click-title-to-nav.title"></span>
              </span>
              <!--<button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item>Expand</button>
            <button mat-menu-item>Remove</button>
          </mat-menu>-->
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
            <app-medialists-summary runningMode="dashboard"></app-medialists-summary>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile *ngIf="application.hasRole('contentmanager')||application.isAdmin"
                     [colspan]="cards?.mediadevices?.cols"
                     [rowspan]="cards?.mediadevices?.rows">
        <mat-card class="dashboard-card">
          <mat-card-header>
            <mat-card-title>
              <span class="title-frame">
                <span class="title"
                      [routerLink]="['/mediadevices']"
                      localize="$:dashboard.card.devices-with-errors.title,title:dashboard.card.common.click-title-to-nav.title"></span>
              </span>
              <!--<button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item>Expand</button>
            <button mat-menu-item>Remove</button>
          </mat-menu>-->
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
            <app-mediadevices-summary runningMode="dashboard"></app-mediadevices-summary>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="cards?.mediajobs?.cols" [rowspan]="cards?.mediajobs?.rows">
        <mat-card class="dashboard-card">
          <mat-card-header>
            <mat-card-title>
              <span class="title-frame">
                <span class="title"
                      [routerLink]="['/mediajobs']"
                      localize="$:dashboard.card.jobs-running.title,title:dashboard.card.common.click-title-to-nav.title"></span>
                <!--  -->
              </span>
              <!--<button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item>Expand</button>
            <button mat-menu-item>Remove</button>
          </mat-menu>-->
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
            <app-mediajobs-summary runningMode="dashboard"></app-mediajobs-summary>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <!--<mat-grid-tile *ngFor="let card of cards | async" [colspan]="card?.cols" [rowspan]="card?.rows">
    <mat-card class="dashboard-card">
      <mat-card-header>
        <mat-card-title>
          {{card.title}}
          <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item>Expand</button>
            <button mat-menu-item>Remove</button>
          </mat-menu>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="dashboard-card-content">
        <div>Card Content Here</div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>-->
    </mat-grid-list>

  </div>
</div>
