<div class="component component-container" fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="pwp.articles.summary.header"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:pwp.articles.summary.toolbar.new.title" (click)="editArticle(null)">
      <icon icon="Plus"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxLayout="row">

      <div fxFlex="250px" class="b-form-container">

        <mat-form-field>
          <mat-select [placeholder]="textFilterModule" [(ngModel)]="filterModuleID"
            (selectionChange)="onModuleFilterChanged()">
            <mat-option *ngFor="let module of modules" [value]="module.moduleID">{{module.moduleTitle}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </div>

    <table mat-table [dataSource]="articles">

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef localize="pwp.articles.summary.grid.col.name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.title }}</td>
      </ng-container>

      <ng-container matColumnDef="module">
        <th mat-header-cell *matHeaderCellDef localize="pwp.articles.summary.grid.col.module.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.moduleDisplayName }}</td>
      </ng-container>

      <ng-container matColumnDef="shortDescription">
        <th mat-header-cell *matHeaderCellDef localize="pwp.articles.summary.grid.col.description.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef localize="pwp.articles.summary.grid.col.startdate.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.startDate | date:'short':'UTC' }}</td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef localize="pwp.articles.summary.grid.col.enddate.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.endDate | date:'short':'UTC' }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons two-buttons">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button (click)="delete(element)"
                localize="title:pwp.articles.summary.grid.col.edit.delete.title">
                <icon icon="Trash"></icon>
              </button>
              <button mat-button (click)="editArticle(element)"
                localize="title:pwp.articles.summary.grid.col.edit.edit.title">
                <icon icon="Pen"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected':id===row.id}"></tr>

    </table>
  </div>

</div>
