<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1><span localize="$:fileeditor.header"></span>&nbsp;{{element.path}}</h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.cancel.title" (click)="close()">
      <icon icon="Times"></icon>
    </button>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container no-scroll">

    <codeEditorWrapper (onCodeChanged)="onCodeChangedHandler($event)"
                       [code]="content"
                       [mode]="options.mode"></codeEditorWrapper>

    <!--<ngx-codemirror [(ngModel)]="content"
                    [options]="options">
    </ngx-codemirror>-->

  </div>

</div>
