<div class="component component-container"
     fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="scene-jobs-edit.summary.header"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:scene-jobs-edit.summary.toolbar.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.back.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!mediaJobsAssigned&&!mediaJobsAll"><em localize="common.loadingdata.msg"></em></p>

    <mat-form-field>
      <input matInput
             (change)="search()"
             [placeholder]="textSearchFilter"
             [(ngModel)]="filterSearch">
    </mat-form-field>

    <ul class="mediajobs">
      <li class="mediajob" *ngFor="let x of selectedJobsView">
        <mat-checkbox [(ngModel)]="x.selected">
          <span class="preview" [ngStyle]="{'background-image': 'url(\'' + getMediaUrlPath(x.mediaListFirstMediaUrl) + '\')'}"></span>
          <span class="mediajob-name" title="{{x.mediaJobName}}">{{ x.mediaJobName }}</span>
          <span class="device-name" title="{{x.deviceName}}">{{ x.deviceName }}</span>
          <span class="medialist-name" title="{{x.mediaListName}}">{{ x.mediaListName}}</span>
        </mat-checkbox>
      </li>
    </ul>
  </div>
</div>
