import { IRole } from '../interfaces/IRole';

export default class Role implements IRole
{
  roleID: number;
  portalID: number;
  roleName: string;
  startTab: number;
  isAuthor: boolean;
  isEditor: boolean;
  isContentManager: boolean;
}
