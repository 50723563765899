import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IGlobal } from '../../interfaces/IGlobal';
import { IWeekDayItem } from '../../interfaces/IWeekDayItem';
import { application } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class WeekDaysService
{
  private application: IGlobal = application;

  constructor(private router: Router) { }

  public GetWeekDays(): IWeekDayItem[]
  {
    let days: IWeekDayItem[] = [
      { value: 1, selected: false, text: application.getRawText('common.culture.days.monday.msg') },
      { value: 2, selected: false, text: application.getRawText('common.culture.days.tuesday.msg') },
      { value: 4, selected: false, text: application.getRawText('common.culture.days.wednesday.msg') },
      { value: 8, selected: false, text: application.getRawText('common.culture.days.thursday.msg') },
      { value: 16, selected: false, text: application.getRawText('common.culture.days.friday.msg') },
      { value: 32, selected: false, text: application.getRawText('common.culture.days.saturday.msg') },
      { value: 64, selected: false, text: application.getRawText('common.culture.days.sunday.msg') }
    ];

    return days;
  };

  public ConvertStartupDaysToValue(_days: IWeekDayItem[]): number
  {
    var val = 0;
    for (var i = 0; i < _days.length; i++)
    {
      if (_days[i].selected === true)
      {
        val = val + _days[i].value;
      }
    }

    return val;
  };

  public ConvertStartupDaysFromValue(_days: IWeekDayItem[], _value: number)
  {
    for (var i = 0; i < _days.length; i++)
    {
      _days[i].selected = false;

      if ((_days[i].value & _value) === _days[i].value)
      {
        _days[i].selected = true;
      }
    }
  };
}
