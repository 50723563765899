import { IUserToEdit } from '../interfaces/IUserToEdit';

export default class UserToEdit implements IUserToEdit
{
  userID: number;
  name: string;
  email: string;
  creationDate: string;
  profileID: number;

  userNeverExpires: boolean;
  password: string;
  expirationDate: string;
  passwordChangeDate: string;
  lastLogIn: string;
}
