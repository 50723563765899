import { Component, Inject, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetMediaService } from '../_services/getmedia.service';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { IState } from '../../interfaces/IState';
import { IReturnState } from '../../interfaces/IReturnState';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})

export class AuthenticationComponent {
  @Output() stateChange = new EventEmitter<IState>();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public activationCode: string = null;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private getMediaService: GetMediaService,
    private snackBar: MatSnackBar) {
  };

  public ngOnInit() {
    let apiKey = localStorage.getItem('pwp-api-key');
    if (apiKey === null) {

    }
    else {
      let worker = this.getMediaService.Get();
      worker.Login(apiKey).subscribe((_state: IState) => {
        if (_state.value === true) {
          localStorage.setItem('pwp-api-key', apiKey);
          this.stateChange.emit(_state);
        }
      });
    }
  };

  public handleAction() {
    // First start activation. Then authentication.
    let worker = this.getMediaService.Get();
    worker.Activate(this.activationCode).subscribe((_result: IReturnState) => {
      let tmp = _result.data as any;
      if (tmp === null) {
        this.snackBarRef = this.snackBar.open('Activation failed. Please check the code.', 'Close', { duration: 20000 });
        return;
      }

      worker.Login(tmp.pwpApiKey).subscribe((_state: IState) => {
        if (_state.value === true) {
          localStorage.setItem('pwp-api-key', tmp.pwpApiKey);
          localStorage.setItem('media-device-id', tmp.mediaDeviceId);
          this.stateChange.emit(_state);
        }
        else {
          this.snackBarRef = this.snackBar.open('Activation failed. Please check the code.', 'Close', { duration: 20000 });
          return;
        }
      });
    });

  };
}
