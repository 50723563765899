import { Directive, ElementRef, HostListener, Input, ViewContainerRef, ComponentRef, ComponentFactoryResolver, EmbeddedViewRef, EventEmitter, Output } from '@angular/core';
import { SecurityService } from './security.service';
import { application } from '../globals';
import { DomSanitizer } from '@angular/platform-browser';
//import { PdfViewerModule, PdfViewerComponent, PDFDocumentProxy } from 'ng2-pdf-viewer';
import { PdfJsViewerModule, PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { IGlobal } from '../interfaces/IGlobal';

@Directive({
  selector: 'presenter',
  inputs: ['url', 'fullscreen', 'avoidFullscreenInWindowsMedia', 'directImage', 'muted'],
  host: {
    '[class]': 'img'
  }
})

/*
 * 
 **/
export class PresenterDirective
{
  private application: IGlobal = application;

  private _directImage: boolean = true;
  private _fullscreen: boolean = true;
  private _avoidFullscreenInWindowsMedia: boolean = true;
  private _crossFadingDurationMs: number = 2000;
  private _secondsPerPage: number = 10;

  private elImage: HTMLDivElement = null;
  private elVideo: HTMLVideoElement = null;
  private elDynSource: HTMLDivElement = null;
  private elEmbed: HTMLObjectElement = null;
  private elEmbedParam: HTMLParamElement = null;
  private elEmbedEmbed: HTMLEmbedElement = null;
  private elWindowsMedia: HTMLObjectElement = null;
  private elWindowsMediaParamUrl: HTMLParamElement = null;
  private elIframe: HTMLIFrameElement = null;
  private elPdf: HTMLElement = null;

  private elStyle: HTMLStyleElement = null;

  private all: HTMLElement[] = [];

  private ref: ComponentRef<PdfJsViewerComponent>;

  constructor(private el: ElementRef,
    private security: SecurityService,
    private sanitizer: DomSanitizer,
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver)
  {
    this.el.nativeElement.className = 'presenter';

    // Create elements
    this.createPdf();
    this.createImage();
    this.createVideo();
    this.createDynSource();
    this.createEmbedMedia();
    this.createWindowsMediaContainer();
    this.createIframe();

    this.setStyleElement();
  }

  private setStyleElement()
  {
    if (this.elStyle === null)
    {
      this.elStyle = document.createElement('style');
      this.el.nativeElement.append(this.elStyle);
    }

    var d = this._crossFadingDurationMs / 1000;
    this.elStyle.innerHTML = '.fade.show { animation: ' + d.toString() + 's ani-show; } .fade.hide { animation: ' + d.toString() + 's ani-hide; }';
  };

  private createPdf()
  {
    if (this.ref === null || this.ref === undefined)
    {
      this.elPdf = document.createElement('pdf-viewer');
      this.elPdf.className = 'pdf';

      var currentPage = 1;
      var pageCount = 1;

      const factory = this.resolver.resolveComponentFactory(PdfJsViewerComponent);
      this.ref = this.vcr.createComponent(factory, 0);
      var instance = this.ref.instance;
      this.ref.instance.onDocumentLoad.subscribe((_event) =>
      {
        pageCount = _event;

        var pdfjs = instance.PDFViewerApplication;
        if (pdfjs !== null)
        {
          pdfjs.toolbar.items.container.parentElement.className = 'hidden';
          pdfjs.pdfViewer.container.style.top = '0';
        }
      });
      this.ref.instance.viewerId = 'viewer';
      this.ref.instance.pdfSrc = this._url;
      this.ref.instance.fullScreen = false;
      this.ref.instance.viewBookmark = false;
      this.ref.instance.openFile = false;
      this.ref.instance.pagemode = 'none';
      this.ref.instance.print = false;
      this.ref.instance.download = false;
      this.ref.instance.find = false;

      var setTimeoutForNextPage = () =>
      {
        setTimeout(() =>
        {
          // Set next page
          var nextPage = currentPage + 1 > pageCount ? 1 : currentPage + 1;
          this.ref.instance.page = nextPage;
          currentPage = nextPage;

          // Change - still pdf? Set next timeout.
          if (this.isPdf())
          {
            setTimeoutForNextPage();
          }

        }, this._secondsPerPage * 1000);
      };
      setTimeoutForNextPage();

      let e = (this.ref.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      this.elPdf.appendChild(e);

      this.setStyle(this.elPdf);
      this.el.nativeElement.append(this.elPdf);
    }
  };

  private createIframe()
  {
    this.elIframe = document.createElement('iframe');
    this.elIframe.className = 'website';
    this.elIframe.title = 'website-content';

    this.setStyle(this.elIframe);
    this.el.nativeElement.append(this.elIframe);
  };

  private createImage()
  {
    this.elImage = document.createElement('div');
    this.elImage.className = 'img';
    this.elImage.style.backgroundRepeat = 'no-repeat';
    this.elImage.style.backgroundSize = 'contain';
    this.elImage.style.backgroundPosition = 'center center';

    this.setStyle(this.elImage);
    this.el.nativeElement.append(this.elImage);
  };

  private createVideo()
  {
    this.elVideo = document.createElement('video');
    this.elVideo.className = 'video';
    this.elVideo.autoplay = true;
    this.elVideo.muted = this._muted;
    this.elVideo.loop = true;

    this.setStyle(this.elVideo);
    this.el.nativeElement.append(this.elVideo);
  };

  private createDynSource()
  {
    this.elDynSource = document.createElement('div');
    this.elDynSource.className = 'img';
    this.elDynSource.style.backgroundRepeat = 'no-repeat';
    this.elDynSource.style.backgroundSize = 'contain';
    this.elDynSource.style.backgroundPosition = 'center center';

    this.setStyle(this.elDynSource);
    this.el.nativeElement.append(this.elDynSource);
  };

  private createEmbedMedia()
  {
    this.elEmbed = document.createElement('object');
    this.elEmbed.className = 'img';
    this.elEmbed.title = 'embedded-content';

    this.elEmbedParam = document.createElement('param');
    this.elEmbedParam.name = 'movie';

    this.elEmbedEmbed = document.createElement('embed');
    this.elEmbedEmbed.width = '100%';
    this.elEmbedEmbed.height = '100%';

    this.elEmbedParam.append(this.elEmbedEmbed);
    this.elEmbed.append(this.elEmbedParam);

    this.setStyle(this.elEmbed);
    this.el.nativeElement.append(this.elEmbed);
  };

  private createWindowsMediaContainer()
  {
    this.elWindowsMedia = document.createElement('object');
    this.elWindowsMedia.id = 'VIDEO';
    this.elWindowsMedia.width = '100%';
    this.elWindowsMedia.height = '100%';
    this.elWindowsMedia.setAttribute('CLASSID', 'CLSID:6BF52A52-394A-11d3-B153-00C04F79FAA6');
    this.elWindowsMedia.type = 'application/x-oleobject';

    this.elWindowsMediaParamUrl = document.createElement('param');
    this.elWindowsMediaParamUrl.name = 'URL';
    this.elWindowsMedia.appendChild(this.elWindowsMediaParamUrl);

    var paramEvents = document.createElement('param');
    paramEvents.name = 'SendPlayStateChangeEvents';
    paramEvents.value = 'True';
    this.elWindowsMedia.append(paramEvents);

    var paramAutoStart = document.createElement('param');
    paramAutoStart.name = 'AutoStart';
    paramAutoStart.value = 'True';
    this.elWindowsMedia.append(paramAutoStart);

    var paramUiMode = document.createElement('param');
    paramUiMode.name = 'uiMode';
    paramUiMode.value = 'none';
    this.elWindowsMedia.append(paramUiMode);

    var paramfullScreen = document.createElement('param');
    paramfullScreen.name = 'uiMode';
    this.elWindowsMedia.append(paramfullScreen);

    if (this._avoidFullscreenInWindowsMedia && this._fullscreen)
    {
      paramfullScreen.value = 'false';

      var paramStretchToFit = document.createElement('param');
      paramStretchToFit.name = 'stretchToFit';
      paramStretchToFit.value = 'true';
      this.elWindowsMedia.append(paramStretchToFit);
    }
    else
    {
      paramfullScreen.value = this._fullscreen.toString();
    }

    var paramLoop = document.createElement('param');
    paramLoop.name = 'loop';
    paramLoop.value = 'true';
    this.elWindowsMedia.append(paramLoop);

    var paramPlayCount = document.createElement('param');
    paramPlayCount.name = 'PlayCount';
    paramPlayCount.value = '9999';
    this.elWindowsMedia.append(paramPlayCount);

    var paramEnableContextMenu = document.createElement('param');
    paramEnableContextMenu.name = 'enableContextMenu';
    paramEnableContextMenu.value = 'false';
    this.elWindowsMedia.append(paramEnableContextMenu);

    this.setStyle(this.elWindowsMedia);
    this.el.nativeElement.append(this.elWindowsMedia);
  };

  private setStyle(_element: HTMLElement)
  {
    this.all.push(_element);

    _element.style.width = '100%';
    _element.style.height = '100%';
    _element.style.position = 'absolute';
    _element.style.top = '0';
    _element.style.left = '0';
    _element.style.right = '0';
    _element.style.bottom = '0';
    _element.style.display = 'none';
  };

  private ngOnInit()
  {
    //this.ref.instance.ngOnInit();

    this.setSource();
  };

  // --------------------------------------------------------------------------

  setSource()
  {
    // Toggle depending on media
    if (this.isPdf())
    {
      if (!this.ref)
      {
        this.createPdf();
      }
      else
      {
        this.ref.instance.pdfSrc = this._url;
      }

      this.toggle(this.elPdf);
    }
    else if (this.isWindowsMedia())
    {
      this.elVideo.src = this._url;
      this.toggle(this.elVideo);
    }
    else if (this.isEmbedMedia())
    {
      this.elEmbedParam.value = this._url;
      this.elEmbedEmbed.src = this._url;
      this.toggle(this.elEmbed);
    }
    else if (this.isImage())
    {
      if (this._directImage)
      {
        this.elImage.style.backgroundImage = 'url("' + this._url + '")';
        this.toggle(this.elImage);
      }
      else
      {
        this.elWindowsMediaParamUrl.value = this._url;
        this.toggle(this.elWindowsMedia);
      }
    }
    else if (this.isDynSourceMedia())
    {
      this.elDynSource.style.backgroundImage = 'url("' + this._url + '")';
      this.toggle(this.elDynSource);
    }
    else
    {
      this.elIframe.style.border = 'none';
      this.elIframe.src = this._url;
      this.toggle(this.elIframe);
    }
  };

  isPdf(): boolean
  {
    var types = ['pdf'];
    var contains = this.containsType(this._url, types);
    return contains;
  }

  isImage(): boolean
  {
    var types = ['png', 'jpg', 'jpeg'];
    var contains = this.containsType(this._url, types);
    return contains;
  }

  isWindowsMedia(): boolean
  {
    var types = ['mpg', 'mpeg', 'wpl', 'asx', 'avi', 'flv', 'wmv', 'mp4'];
    var contains = this.containsType(this._url, types);
    return contains;
  }

  isDynSourceMedia(): boolean
  {
    var types = ['mpg', 'mpeg', 'vob', 'mp4', 'avi'];
    var contains = this.containsType(this._url, types);
    return contains;
  }

  isEmbedMedia(): boolean
  {
    var types = ['swf', 'pps'];
    var contains = this.containsType(this._url, types);
    return contains;
  }

  containsType(_url: string, _types: string[]): boolean
  {
    _url = _url.toLowerCase();
    for (var i = 0; i < _types.length; i++)
    {
      if (_url.indexOf(_types[i]) >= 0)
      {
        return true;
      }
    }

    return false;
  }

  toggle(_elementToShow: HTMLElement)
  {
    for (var i = 0; i < this.all.length; i++)
    {
      if (this.all[i] === _elementToShow)
      {
        this.all[i].style.display = 'block';
      }
      else
      {
        this.all[i].style.display = 'none';
      }
    }
  }

  // --------------------------------------------------------------------------

  _url: string;

  get url(): string { return this._url; }

  @Input('url')
  set url(_value: string)
  {
    this._url = _value;
  }

  // --------------------------------------------------------------------------

  _muted: boolean = true;

  get muted(): boolean { return this._muted; }

  @Input('muted')
  set muted(_value: boolean)
  {
    this._muted = _value;
  }

  // --------------------------------------------------------------------------

  get directImage(): boolean { return this._directImage; }

  @Input('directImage')
  set directImage(_value: boolean)
  {
    this._directImage = _value;
  }

  // --------------------------------------------------------------------------

  get fullscreen(): boolean { return this._fullscreen; }

  @Input('fullscreen')
  set fullscreen(_value: boolean)
  {
    this._fullscreen = _value;
  }

  // --------------------------------------------------------------------------

  get avoidFullscreenInWindowsMedia(): boolean { return this._avoidFullscreenInWindowsMedia; }

  @Input('avoidFullscreenInWindowsMedia')
  set avoidFullscreenInWindowsMedia(_value: boolean)
  {
    this._avoidFullscreenInWindowsMedia = _value;
  }

  // --------------------------------------------------------------------------

  get crossFadingDurationMs(): number { return this._crossFadingDurationMs; }

  @Input('crossFadingDurationMs')
  set crossFadingDurationMs(_value: number)
  {
    this._crossFadingDurationMs = _value;
    this.setStyleElement();
  }

  // --------------------------------------------------------------------------

  get secondsPerPage(): number { return this._secondsPerPage; }

  @Input('secondsPerPage')
  set secondsPerPage(_value: number)
  {
    this._secondsPerPage = _value;
    this.setStyleElement();
  }

  // --------------------------------------------------------------------------

}
