import { Injectable, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FileSystemFileEntry } from 'ngx-file-drop';

import { ICanBeBusy, IsBusy } from './../busyindicator/busyindicatorfactory.service';
import { HttpWorkerFactoryService, HttpWorker } from './../http/httpworkerfactory.service';
import { UtilsService } from './../common/utils.service';
import { IGlobal } from '../../interfaces/IGlobal';
import { IApiUriBuilder } from '../../interfaces/IApiUriBuilder';
import { IReturnState } from '../../interfaces/IReturnState';
import { application } from '../../globals';
import { QueryString, UriBuilder } from '../common/uribuilder.service';

@Injectable({
  providedIn: 'root',
})

export class ChunkedUploadService implements ICanBeBusy
{
  private application: IGlobal = application;

  private http: HttpWorker;

  public IsBusy: IsBusy = new IsBusy();

  private list: UploadingFile[] = [];

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router,
    public utilsService: UtilsService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
  }

  /**
   * Validates if the passed file has one of the allowed extensions.
   * @param _fileName
   * @param _fileExtensions
   */
  public HasValidExtension(_fileName: string, _fileExtensions: string[])
  {
    if (_fileExtensions === null)
    {
      return false;
    }

    // Check if the extension is allowed.
    var name = _fileName;
    var idxExt = name.lastIndexOf('.');
    if (idxExt >= 0)
    {
      var formats = _fileExtensions.map((_item, _index, _array) => _item === null || _item === undefined ? _item : _item.toLowerCase());
      var ext = name.substr(idxExt).toLowerCase();
      if (formats.indexOf(ext) < 0)
      {
        return false;
      }
    }
    else
    {
      return false;
    }

    return true;
  };

  /**
   * Performs an upload to images.
   * @param _file
   * @param _path
   * @param _parameters
   */
  public UploadFileToImage(_file: FileSystemFileEntry, _path: string, _parameters: QueryString[] = null): UploadStatus
  {
    return this.UploadFile(_file, _path, 'images/upload', this.application, _parameters);
  };

  /**
   * Performs an upload to files.
   * @param _file
   * @param _path
   * @param _apiUriBuilder
   */
  public UploadFileToFiles(_file: FileSystemFileEntry, _path: string, _apiUriBuilder: IApiUriBuilder): UploadStatus
  {
    return this.UploadFile(_file, _path, 'files/upload', _apiUriBuilder);// this.evision5);
  };

  /**
   * Uploads a file.
   * @param _file
   * @param _path
   * @param _targetUrl
   * @param _apiUriBuilder
   * @param _parameters
   */
  public UploadFile(_file: FileSystemFileEntry,
    _path: string,
    _targetUrl: string,
    _apiUriBuilder: IApiUriBuilder,
    _parameters: QueryString[] = null): UploadStatus
  {
    var f = new UploadStatus();

    var uf = new UploadingFile();
    uf.Parameters = _parameters;
    uf.ApiUriBuilder = _apiUriBuilder;
    uf.UploadingUri = _targetUrl;
    uf.Http = this.http;
    uf.BaseUrl = this.baseUrl;
    uf.ID = this.utilsService.EmptyGuid();
    uf.OnStatus.subscribe((_result: number) =>
    {
      this.IsBusy.Busy();
      f.OnStatus.emit(_result);
    });
    uf.OnDone.subscribe((_result: IReturnState) =>
    {
      this.IsBusy.Relaxed();
      f.OnDone.emit(_result);
    });
    uf.Upload(_file, _path);
    this.list.push(uf);

    return f;
  };

}

export class UploadStatus
{
  public Status: number = 0;
  public OnStatus: EventEmitter<number> = new EventEmitter<number>();
  public OnDone: EventEmitter<IReturnState> = new EventEmitter<IReturnState>();
}

class UploadingFile
{
  /*
   * States:
   * New = 0,
   * Progress = 1,
   * Done = 2
   */
  private state: number = 0;
  private chunks: Chunk[] = [];
  private path: string;
  private fileName: string;

  public Parameters: QueryString[] = [];
  public ApiUriBuilder: IApiUriBuilder = null;
  public Http: HttpWorker
  public BaseUrl: string;
  public ID: string = null;
  public OnDone: EventEmitter<IReturnState> = new EventEmitter<IReturnState>();
  public OnStatus: EventEmitter<number> = new EventEmitter<number>();

  public UploadingUri: string = null;

  /*
   * Uploads a file as chunks.
   */
  public Upload(_file: FileSystemFileEntry, _path: string)
  {
    this.path = _path;
    this.fileName = _file.name;

    _file.file((_f) =>
    {
      let size = 1024 * 1024;
      let from = 0;

      while (from < _f.size)
      {
        var chunk = new Chunk();
        chunk.From = from;
        chunk.To = from + size;

        if (chunk.To < _f.size)
        {
          // Just next chunk
          chunk.Data = _f.slice(from, chunk.To);
          chunk.State = chunk.From === 0 ? 0 : 1;
        }
        else
        {
          // This is the end.
          chunk.Data = _f.slice(from);
          chunk.State = 2;
        }

        this.chunks.push(chunk);

        from = from + size;
      }

      this.uploadChunks(0);

    });
  };

  /*
   * Uploads all chunks.
   */
  private uploadChunks(_index: number)
  {
    this.OnStatus.emit(100 / this.chunks.length * _index);

    var tmp = this.chunks[_index];
    this.uploadChunk(tmp.Data, tmp.State, _index)
      .subscribe((_result: IReturnState) =>
      {
        if (_result.success === false)
        {
          this.OnDone.emit(_result);
          return;
        }

        if (tmp.State !== 2)
        {
          this.uploadChunks(_index + 1);
        }
        else
        {
          this.OnDone.emit(_result);
        }
      });
  };

  /*
   * Uploads the chunk of a file.
   */
  private uploadChunk(_data: Blob, _state: number, _index: number): EventEmitter<IReturnState>
  {
    this.state = _state;

    let onChunkDone = new EventEmitter<IReturnState>();
    let data = _data;

    let builder = new UriBuilder()
      .Parse(this.ApiUriBuilder.buildApi(this.BaseUrl, this.UploadingUri + '/' + this.ID + '/' + _state))
      .AddQueryString('path', this.path)
      .AddQueryString('filename', this.fileName)
      .AddQueryString('index', _index.toString());

    if (this.Parameters !== null)
    {
      for (var p = 0; p < this.Parameters.length; p++)
      {
        builder.AddQueryString(this.Parameters[p].Key, this.Parameters[p].Value);
      }
    }

    let url = builder.Build();

    let formData = new FormData();
    formData.append('_file', _data, this.fileName);

    this.Http.post<IReturnState>(url, formData)
      .subscribe((_result: IReturnState) =>
      {
        if (this.state === 0)
        {
          this.state = 1;
        }

        if (this.state !== 2)
        {
          this.ID = _result.data as unknown as string;
        }

        onChunkDone.emit(_result);
      });

    return onChunkDone;
  };
}

class Chunk
{
  public From: number;
  public To: number;
  public Data: Blob;
  public State: number;
};
