<div class="component component-container" [ngClass]="{'mode-dashboard':runningMode===MODE_DASHBOARD}"
  fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="runningMode===MODE_NORMAL" localize="medialists.summary.header"></h1>

  <mat-toolbar *ngIf="runningMode===MODE_NORMAL">
    <span class="toolbar-spacer"></span>
    <button mat-button *ngIf="application.hasRole('contentmanager')||application.isAdmin"
      localize="title:medialists.summary.toolbar.new.title" (click)="editMediaList(null)">
      <icon icon="Plus"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!mediaLists"><em localize="common.loadingdata.msg"></em></p>

    <div fxLayout="row" *ngIf="runningMode===MODE_NORMAL">

      <div fxFlex="250px" class="b-form-container searchcontainer">

        <mat-form-field>
          <input matInput [placeholder]="textSearchTitle" [(ngModel)]="search" (change)="onSearchHandler()">
        </mat-form-field>

      </div>
      <div fxFlex="250px" class="b-form-container sortcontainer">

        <mat-form-field>
          <mat-select [placeholder]="textSortSelection" [(ngModel)]="sortField" (selectionChange)="sortChangeHandler()">
            <mat-option [value]="SORT_NAME" localize="medialists.summary.top.sort.byname.title"></mat-option>
            <mat-option [value]="SORT_CREATIONDATE" localize="medialists.summary.top.sort.bycreationdate.title">
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </div>

    <table mat-table [dataSource]="mediaListDataSource" matSort matTableResponsive>

      <ng-container matColumnDef="linkedList">
        <th mat-header-cell *matHeaderCellDef class="column-type"></th>
        <td mat-cell *matCellDef="let element" class="column-type">
          <a [routerLink]="['/medialists', element.mediaListID, 'mediaactivities']">
            <icon *ngIf="!element.linkedList" matBadge="{{ element.elementCount }}"
              localize="title:medialists.summary.grid.type.mediaavticivities.title" icon="Film"></icon>
            <icon *ngIf="element.linkedList" localize="title:medialists.summary.grid.type.linkedlist.title" icon="List">
            </icon>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="listName">
        <th mat-header-cell *matHeaderCellDef localize="medialists.summary.grid.listname.title" mat-sort-header></th>
        <td mat-cell *matCellDef="let element"><a
            [routerLink]="['/medialists', element.mediaListID, 'mediaactivities']">{{ element.listName }}</a></td>
      </ng-container>

      <ng-container matColumnDef="preview">
        <th mat-header-cell *matHeaderCellDef class="cell-preview"></th>
        <td mat-cell *matCellDef="let element" class="cell-preview">
          <span class="preview"
            [ngStyle]="{'background-image': 'url(\'' + getMediaUrlPath(element.firstMediaUrl) + '\')'}"></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="durationSumFormatted">
        <th mat-header-cell *matHeaderCellDef class="column-duration" localize="medialists.summary.grid.duration.title"
          mat-sort-header></th>
        <td mat-cell *matCellDef="let element" class="column-duration">{{ element.durationSumFormatted }}</td>
      </ng-container>

      <!--
      <ng-container matColumnDef="elementCount">
        <th mat-header-cell *matHeaderCellDef class="column-elementcount cell-right" localize="medialists.summary.grid.elementcount.title"></th>
        <td mat-cell *matCellDef="let element" class="column-elementcount cell-right">
          <span *ngIf="element.linkedList===false">
            {{ element.elementCount }}
          </span>
        </td>
      </ng-container>
        -->

      <ng-container matColumnDef="mediaTypes">
        <th mat-header-cell *matHeaderCellDef>
          <span class="header-mediatypes" localize="medialists.summary.grid.mediatypes.title"></span>
        </th>
        <td mat-cell *matCellDef="let element" class="cell-200">{{ element.mediaTypes }}</td>
      </ng-container>

      <ng-container matColumnDef="createDate">
        <th mat-header-cell *matHeaderCellDef class="column-created" localize="medialists.summary.grid.createdon.title"
          mat-sort-header></th>
        <td mat-cell *matCellDef="let element" class="column-created">{{ element.createDate | date:'short':'UTC' }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons three-buttons">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button (click)="editMediaList(element.mediaListID)"
                localize="title:medialists.summary.grid.edit.btn.title">
                <icon icon="Pen"></icon>
              </button>
              <button mat-button *ngIf="application.hasRole('contentmanager')||application.isAdmin"
                (click)="delete(element.mediaListID)" localize="title:medialists.summary.grid.delete.btn.title">
                <icon icon="Trash"></icon>
              </button>
              <button mat-button [routerLink]="['/medialists', element.mediaListID, 'mediaactivities']"
                localize="title:medialists.summary.grid.mediaactivities.btn.title">
                <icon icon="PhotoVideo"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{'selected':mediaListID===row.mediaListID}"></tr>

    </table>
  </div>

</div>
