import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import Module from './../../models/Module';
import { IGlobal } from '../../interfaces/IGlobal';
import { IModule } from '../../interfaces/IModule';
import { IReturnState } from '../../interfaces/IReturnState';
import { application } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class ModulesService
{
  private application: IGlobal = application;

  constructor(private http: HttpClient,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string)
  {
  }

  /**
 * Returns the modules of type "eventItem".
 * @param _type
 */
  public GetEventItems(): EventEmitter<IModule[]>
  {
    return this.GetModules('eventitems');
  };

  /**
   * Returns the modules of type "htmlText";
   * @param _type
   */
  public GetHtmlTexts(): EventEmitter<IModule[]>
  {
    return this.GetModules('htmltexts');
  };

  /**
   * Returns the modules of type "article";
   * @param _type
   */
  public GetArticles(): EventEmitter<IModule[]>
  {
    return this.GetModules('articles');
  };

  /**
   * Gets the modules of a certain type.
   * @param _type
   */
  public GetModules(_type: string): EventEmitter<IModule[]>
  {
    let onDone = new EventEmitter<IModule[]>();

    this.http
    this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'modules/of/' + _type))
      .subscribe(result =>
      {
        var modules = result.data as IModule[];
        onDone.emit(modules);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Returns the modules of type "article".
   * @param _type
   */
  public GetArticlessForSelection(): EventEmitter<IModule[]>
  {
    return this.GetModulesForSelection('articles');
  };

  /**
   * Returns the modules of type "eventItem".
   * @param _type
   */
  public GetEventItemsForSelection(): EventEmitter<IModule[]>
  {
    return this.GetModulesForSelection('eventitems');
  };

  /**
   * Returns the modules of type "htmlText";
   * @param _type
   */
  public GetHtmlTextsForSelection(): EventEmitter<IModule[]>
  {
    return this.GetModulesForSelection('htmltexts');
  };

  /**
   * Gets the modules of a certain type for selection in a dropdown.
   * @param _type
   */
  public GetModulesForSelection(_type: string): EventEmitter<IModule[]>
  {
    let onDone = new EventEmitter<IModule[]>();

    this.GetModules(_type).subscribe((_result: IModule[]) =>
    {
      var tmp: IModule[] = [];

      var empty = new Module();
      empty.moduleTitle = this.application.getRawText('common.noselection.msg');
      empty.moduleID = null;
      tmp.push(empty);

      for (var i = 0; i < _result.length; i++)
      {
        tmp.push(_result[i]);
      }

      onDone.emit(tmp);
    });

    return onDone;
  };

}
