import { IModule } from '../interfaces/IModule';

export default class Module implements IModule
{
  moduleID: number;
  tabID: number;
  moduleDefID: number;
  moduleOrder: number;
  paneName: string;
  moduleTitle: string;
  authorizedEditRoles: string;
  cacheTime: number;
  showMobile: boolean;
  moduleActiveTitle: string;
  changedTime: string;
  deleted: boolean;
  itemId: string;
}
