<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1></h1>

  <mat-toolbar>
    <button mat-button localize="title:common.back.msg" *ngIf="currentPreviewIndex>0" (click)="prev()">
      <icon icon="ArrowLeft"></icon>
    </button>
    <span class="toolbar-spacer"></span>
    <a mat-button
       localize="title:filexplorer.preview.toolbar.download.title"
       [href]="getMediaDownloadUrlPath(element)"
       target="_blank">
      <icon icon="Download"></icon>
    </a>
    <button mat-button localize="title:common.edit.msg" *ngIf="isPlainText(element)||isImage(element)" (click)="openEditor()">
      <icon icon="Pen"></icon>
    </button>
    <button mat-button localize="title:common.close.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.next.msg" *ngIf="currentPreviewIndex<this.files.length-1" (click)="next()">
      <icon icon="ArrowRight"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div class="bigpreview" *ngIf="element!==null" [ngStyle]="{'background-image': 'url(\'' + getMediaUrlPath(element) + '\')'}">
      <videoWrapper class="previewvideo"
                    *ngIf="isVideo(element)"
                    [videoFormats]="settings.videoFormatsValue"
                    [url]="element.url"
                    [showControls]="true">
      </videoWrapper>
      <websiteWrapper *ngIf="showInIframe(element)"
                      class="iframecontent"
                      [url]="element.url">
      </websiteWrapper>
    </div>

  </div>

</div>
