import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { application } from '../../core/globals';
import { IApiAccessSlack } from '../../core/interfaces/IApiAccessSlack';
import { IGlobal } from '../../core/interfaces/IGlobal';
import { IGlobalSettings } from '../../core/interfaces/IGlobalSettings';
import { IReturnState } from '../../core/interfaces/IReturnState';
import ApiAccessSlack from '../../core/models/ApiAccessSlack';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from '../../core/services/busyindicator/busyindicatorfactory.service';
import { SecurityService } from '../../core/services/common/security.service';
import { KeyHandler, UiKeyHandleService } from '../../core/services/common/uikeyhandle.service';
import { UriBuilderService } from '../../core/services/common/uribuilder.service';
import { UtilsService } from '../../core/services/common/utils.service';
import { GlobalSettingsService } from '../../core/services/data/globalsettings.service';
import { LoggerService } from '../../core/services/data/logger.service.ts';
import { SlackService } from '../../core/services/data/slack.service';
import { HttpWorker, HttpWorkerFactoryService } from '../../core/services/http/httpworkerfactory.service';

@Component({
  selector: 'app-slack-single',
  templateUrl: './slack-single.component.html',
  styleUrls: ['./slack-single.component.css']
})

export class SlackSingleComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private application: IGlobal = application;

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  private globalSettings: IGlobalSettings;

  public slackId: number = null;
  public item: IApiAccessSlack = new ApiAccessSlack();

  private initiated: boolean = false;

  public textName: string = application.getRawText('pwp.slack.single.form.name.label');
  public textAppID: string = application.getRawText('pwp.slack.single.form.appid.label');
  public textClientID: string = application.getRawText('pwp.slack.single.form.clientid.label');
  public textClientSecret: string = application.getRawText('pwp.slack.single.form.clientsecret.label');
  public textSigningSecret: string = application.getRawText('pwp.slack.single.form.signingsecret.label');
  public textScope: string = application.getRawText('pwp.slack.single.form.scope.label');
  public textUserAccessToken: string = application.getRawText('pwp.slack.single.form.useraccesstoken.label');
  public textAccessToken: string = application.getRawText('pwp.slack.single.form.accesstoken.label');
  public textResponseDate: string = application.getRawText('pwp.slack.single.form.responsedate.label');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  public keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private uriBuilderService: UriBuilderService,
    private slackService: SlackService,
    private globalSettingsService: GlobalSettingsService,
    private loggerService: LoggerService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());

    this.globalSettingsService.GetSettings().subscribe((_settings) => {
      this.globalSettings = _settings;
    });
  };

  public configureSlack() {
    this.slackService.SaveApiKey(this.item).subscribe(_returnState => {
      if (_returnState.success) {
        this.item = _returnState.data as IApiAccessSlack;

        this.loggerService.Info(`Start slack-oauth-configuration using [${this.globalSettings.apiAccessSlackAuthorize}] for account [${this.item.id}].`);

        let appUrl = this.utilsService.GetAppUrl();

        var uriBuilderSlackHandler = this.uriBuilderService.Get()
          .Parse(appUrl)
          .AddSegment('system')
          .AddSegment('slackhandler')
          .AddSegment(this.item.id.toString());
        let redirectUri = uriBuilderSlackHandler.Build();

        this.loggerService.Trace(`App-url [${appUrl}] redirect-uri [${redirectUri}].`);

        var uriBuilder = this.uriBuilderService.Get()
          .Parse(this.globalSettings.apiAccessSlackAuthorize)
          .AddQueryString('scope', this.item.scope)
          .AddQueryString('client_id', this.item.clientID)
          .AddQueryString('state', this.item.state)
          .AddQueryString('redirect_uri', redirectUri);

        let url = uriBuilder.Build();
        window.open(url);

        this.close();
      }
      else {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  private ngOnInit() {
    if (this.initiated) {
      return;
    }

    var tmpID = parseInt(this.route.snapshot.paramMap.get('id'));
    this.slackId = tmpID > 0 ? tmpID : null;
    this.initSlack();
  };

  public initSlack() {
    if (this.initiated) {
      return;
    }

    if (!(this.slackId > 0)) {
      this.slackId = null;
      this.item = new ApiAccessSlack();
      this.item.scope = 'channels:read,channels:history,users.profile:read';
    }
    else {
      this.loadSlack();
    }

    this.initiated = true;
  };

  private loadSlack() {
    this.slackService.GetApiKey(this.slackId).subscribe((_slack: IReturnState) => {
      this.item = _slack.data as IApiAccessSlack;
    });
  };

  public save() {
    this.slackService.SaveApiKey(this.item).subscribe(_returnState => {
      if (_returnState.success) {
        this.item = _returnState.data as IApiAccessSlack;
        this.slackId = this.item.id;
        this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
        this.close();
      }
      else {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  public close() {
    this.onClose.emit();
  };
}
