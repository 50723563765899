import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
//import { PdfViewerModule, PdfViewerComponent } from 'ng2-pdf-viewer';
import { PdfJsViewerModule, PdfJsViewerComponent } from 'ng2-pdfjs-viewer';

import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);

import { AppWebPlayerComponent } from './app-webplayer.component';

import { EncodeUriPipe } from './pipes/encode-uri.pipe';
import { RoleFunctionDirective } from './rolefunction.directive';
import { LocalizeDirective } from './localize.directive';
import { PresenterDirective } from './presenter.directive';
import { LocaleService } from './locale.service';
import { DialogComponent } from './dialog/dialog.component';
import { AuthInterceptor } from './authinterceptor';
import { AuthenticationComponent } from './authentication/authentication.component';
import { WebPresenterComponent } from './webpresenter/webpresenter.component';
import { LayoutComponent } from './layout/layout.component';
import { UriBuilderService } from './_services/uribuilder.service';
import { GetMediaService } from './_services/getmedia.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';

// There are two modules. One for usage in webpresenter and the other
// one in case of usage of webpresenter as part of e5-app. Last usecase
// requires an empty routing.

// Module for internal usage.
@NgModule({
  declarations: [
    AppWebPlayerComponent,
    RoleFunctionDirective,
    LocalizeDirective,
    PresenterDirective,
    DialogComponent,
    LayoutComponent,
    WebPresenterComponent,
    AuthenticationComponent,
    EncodeUriPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    CommonModule,
    RouterModule.forRoot([
      { path: '', component: LayoutComponent, pathMatch: 'full' },
      { path: 'webpresenter/:deviceID', component: LayoutComponent }
    ]),
    BrowserAnimationsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatCheckboxModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDialogModule,
    MatListModule,
    NgxMaterialTimepickerModule,
    DragDropModule,
    PdfJsViewerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService) =>
      {

        var language = localeService.getLanguage();
        console.log('evision5 webplayer', language);
        console.log('locale ID', language);
        return language;
      },
      deps: [LocaleService]
    }
  ],
  bootstrap: [AppWebPlayerComponent],
  entryComponents: [
    PdfJsViewerComponent,
    DialogComponent
  ]
})
export class AppModule { }

// Module for export without routes.
@NgModule({
  declarations: [
    // AppWebPlayerComponent,
    // RoleFunctionDirective,
    // LocalizeDirective,
    // PresenterDirective,
    // DialogComponent,
    // LayoutComponent,
    // WebPresenterComponent,
    // AuthenticationComponent
    // EncodeUriPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    CommonModule,
    RouterModule.forRoot([]),
    BrowserAnimationsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatCheckboxModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDialogModule,
    MatListModule,
    NgxMaterialTimepickerModule,
    DragDropModule,
    PdfJsViewerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService) =>
      {

        var language = localeService.getLanguage();
        console.log('evision5 webplayer', language);
        console.log('locale ID', language);
        return language;
      },
      deps: [LocaleService]
    }
  ],
  bootstrap: [AppWebPlayerComponent],
  entryComponents: [
    PdfJsViewerComponent,
    DialogComponent
  ]
})
export class AppWebPlayerModule { }

@NgModule({
  id: 'app-evision5-webplayer'
})
export class AppWebPlayer
{
  static forRoot(): ModuleWithProviders<AppWebPlayerModule>
  {
    return {
      ngModule: AppWebPlayerModule,
      providers: []
    }
  }
}
