import { Directive, ElementRef, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { IGlobal } from '../../../app-pwp/src/app/core/interfaces/IGlobal';
import { SecurityService } from '../../../app-pwp/src/app/core/services/common/security.service';
import * as codemirror from 'codemirror';

@Directive({
  selector: 'codeEditorWrapper',
  inputs: ['code', 'mode']
})

export class CodeEditorWrapperDirective
{
  private elementContainer: HTMLTextAreaElement;

  private editor: codemirror.EditorFromTextArea = null;

  constructor(private el: ElementRef,
    private security: SecurityService)
  {
    this.createStructure();
  }

  private createStructure()
  {
    this.elementContainer = document.createElement('textarea');
    this.elementContainer.style.height = '100%';

    this.el.nativeElement.append(this.elementContainer);
  };

  private createEditor()
  {
    if (this.editor !== null)
    {
      return;
    }

    this.editor = codemirror.fromTextArea(this.elementContainer, {
      lineNumbers: true,
      theme: 'material'
    });
    this.editor.on('change', (_editor, _change) =>
    {
      let tmp = this.editor.getValue();
      this.onCodeChanged.emit(tmp);
    });

    this.editor.setValue(this.code);
    this.editor.setOption('mode', this.mode);
  };

  private check()
  {
    if (this._code === null || this._code === undefined || this._code === '')
    {
      return;
    }

    if (this.editor !== null)
    {
      this.editor.setValue(this.code);
      this.editor.setOption('mode', this.mode);
    }
    else
    {
      // Workaround: Surrounding layout needs to completed so that codemirror can calculate correct sizes.
      // https://github.com/codemirror/CodeMirror/issues/5876
      setTimeout(() =>
      {
        this.createEditor();
      }, 500);
    }
  };

  // --------------------------------------------------------------------------

  _code: string;

  get code(): string { return this._code; }

  @Input('code')
  set code(_value: string) { this._code = _value; this.check(); }

  // --------------------------------------------------------------------------

  _mode: string = 'javascript';

  get mode(): string { return this._mode; }

  @Input('mode')
  set mode(_value: string) { this._mode = _value; this.check(); }

  // --------------------------------------------------------------------------

  @Output('onCodeChanged')
  onCodeChanged: EventEmitter<string> = new EventEmitter<string>();
}
