//
// ===== File globals.ts    
//
'use strict';

import { IGlobalEvision5 } from './interfaces/IGlobalEvision5';

export let evision5: IGlobalEvision5 = {
  apiUrl: 'api/evision5/',

  buildApi(_basePath: string, _api: string): string
  {
    return _basePath + this.apiUrl + _api;
  }
};
