import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { HttpWorkerFactoryService, HttpWorker } from './../http/httpworkerfactory.service';
import { ICanBeBusy, IsBusy } from './../busyindicator/busyindicatorfactory.service';
import { GlobalSettingsService } from './../data/globalsettings.service';
import { ApiKeysService } from './../data/apikeys.service';
import UserToEdit from './../../models/UserToEdit';
import { IGlobal } from '../../interfaces/IGlobal';
import { IGlobalSettings } from '../../interfaces/IGlobalSettings';
import { IUserToEdit } from '../../interfaces/IUserToEdit';
import { IUserApiKey } from '../../interfaces/IUserApiKey';
import { IReturnState } from '../../interfaces/IReturnState';
import { application } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class UsersService implements ICanBeBusy
{
  public IsBusy: IsBusy = new IsBusy();

  private globalSettings: IGlobalSettings = null;

  private http: HttpWorker;

  constructor(private httpWorkerFactory: HttpWorkerFactoryService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private globalSettingsService: GlobalSettingsService,
    private apiKeyService: ApiKeysService)
  {
    this.http = this.httpWorkerFactory.GetWorker();
    this.IsBusy.PassThrough(this.http);
    this.IsBusy.PassThrough(this.globalSettingsService);
    this.IsBusy.PassThrough(this.apiKeyService);

    this.globalSettingsService.GetSettings().subscribe(_state =>
    {
      this.globalSettings = _state;
    });
  }

  /**
 * Checks if the user can be deleted.
 * 0 = yes
 * 1 = no, is admin.
 * @param _user
 */
  public IsAdmin(_user: IUserToEdit): boolean
  {
    if (_user !== null &&
      _user !== undefined &&
      _user.name !== null &&
      _user.name !== undefined &&
      this.globalSettings.adminUser.toUpperCase() === _user.name.toUpperCase())
    {
      return true;
    }

    return false;
  };

  /**
   * Checks if the user can be deleted.
   * 0 = yes
   * 1 = no, is admin.
   * 2 = no, has still apiKeys
   * @param _user
   */
  public CanDelete(_user: IUserToEdit): EventEmitter<number>
  {
    let onDone = new EventEmitter<number>();

    setTimeout(() =>
    {
      if (this.IsAdmin(_user))
      {
        onDone.emit(1);
      }
      else
      {
        this.apiKeyService.GetApiKeysByUser(_user.userID).subscribe((_apiKeys: IUserApiKey[]) =>
        {
          if (_apiKeys.length > 0)
          {
            onDone.emit(2);
          }
          else
          {
            onDone.emit(0);
          }
        });
      }
    });

    return onDone;
  };

  /**
   * Gets all available users for a selection-usecase.
   */
  public GetUsersForSelection(): EventEmitter<IUserToEdit[]>
  {
    let onDone = new EventEmitter<IUserToEdit[]>();

    this.GetUsers().subscribe((_user: IUserToEdit[]) =>
    {
      var tmp = [];

      var empty = new UserToEdit();
      empty.userID = null;
      empty.name = application.getRawText('common.noselection.msg');
      tmp.push(empty);

      for (var i = 0; i < _user.length; i++)
      {
        tmp.push(_user[i]);
      }

      onDone.emit(tmp);
    });

    return onDone;
  };

  /**
   * Gets all available users.
   */
  public GetUsers(): EventEmitter<IUserToEdit[]>
  {
    let onDone = new EventEmitter<IUserToEdit[]>();

    this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'users'))
      .subscribe(result =>
      {
        var data = result.data as IUserToEdit[];
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Deletes a user.
   * @param _userId
   */
  public DeleteUser(_userId: number): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    this.http
      .delete<IReturnState>(application.buildApi(this.baseUrl, 'user/' + _userId), {})
      .subscribe(
        _result =>
        {
          onDone.emit(_result as IReturnState);
        });

    return onDone;
  };

  /**
   * Gets a user.
   * @param _userId
   */
  public LoadUser(_userId: number): EventEmitter<IUserToEdit>
  {
    let onDone = new EventEmitter<IUserToEdit>();

    this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'user/' + _userId))
      .subscribe(result =>
      {
        var data = result.data as IUserToEdit;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Saves a user.
   * @param _user
   */
  public SaveUser(_user: IUserToEdit): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    var url = _user.userID > 0
      ? application.buildApi(this.baseUrl, 'user/' + _user.userID)
      : application.buildApi(this.baseUrl, 'user');

    this.http.post<IReturnState>(url, _user)
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Sends an email to the user to reset the password.
   * @param _user
   */
  public SendResetPasswordLink(_userID: number): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    this.http.post<IReturnState>(application.buildApi(this.baseUrl, 'security/' + _userID + '/sendpasswordreset'), null)
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };

  /**
   * Sends an email to the user with an invitation.
   * @param _user
   */
  public SendInvitationLink(_userID: number): EventEmitter<IReturnState>
  {
    let onDone = new EventEmitter<IReturnState>();

    this.http.post<IReturnState>(application.buildApi(this.baseUrl, 'security/' + _userID + '/sendinvitation'), null)
      .subscribe(result =>
      {
        var data = result as IReturnState;
        onDone.emit(data);
      }, error => console.error(error));

    return onDone;
  };
}
