<div class="component component-container" fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 mat-dialog-title><span localize="mediadevice.activationdialog.title"></span></h1>

  <mat-toolbar>

    <span class="toolbar-spacer"></span>

    <button mat-button [mat-dialog-close]="true" localize="title:common.cancel.msg">
      <icon icon="Times"></icon>
    </button>

  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container wrapper">
      <mat-horizontal-stepper #stepper linear>

        <mat-step [label]="textStep1">

          <p>
            <span localize="mediadevice.activationdialog.remark"></span>
          </p>

          <p>&nbsp;</p>

          <button mat-button localize="title:common.next.msg" (click)="next(stepper)">
            <icon icon="ArrowRight"></icon>
          </button>

        </mat-step>

        <mat-step [label]="textStep2">

          <mat-form-field>
            <mat-select [placeholder]="textPlaceholderActivationTimeout" [(ngModel)]="activationTimeout">
              <mat-option [value]="10" localize="$:mediadevice.activationdialog.activationtimeout.10min"></mat-option>
              <mat-option [value]="60" localize="$:mediadevice.activationdialog.activationtimeout.1hour"></mat-option>
              <mat-option [value]="1440" localize="$:mediadevice.activationdialog.activationtimeout.24hours"></mat-option>
              <mat-option [value]="5760" localize="$:mediadevice.activationdialog.activationtimeout.96hours"></mat-option>
              <mat-option [value]="9999" localize="$:mediadevice.activationdialog.activationtimeout.notimeout"></mat-option>
            </mat-select>
          </mat-form-field>

          <mat-checkbox [(ngModel)]="createNewDeviceSecret"><span localize="$:mediadevice.activationdialog.createnewdevicesecret,title:mediadevice.activationdialog.createnewdevicesecret.title"></span></mat-checkbox>

          <p>&nbsp;</p>

          <button mat-button (click)="createActivationCode(stepper)" localize="$:mediadevice.activationdialog.createactivationkey"></button>

        </mat-step>

        <mat-step [label]="textStep3">

          <p>
            <span localize="mediadevice.activationdialog.activationkey"></span>
          </p>
          <p>
            <span>
              <b>
                {{ activationCode }}
              </b>
            </span>
          </p>
        </mat-step>
      </mat-horizontal-stepper>
      <div class="b-form-container" fxLayout="row">
      </div>
    </div>
  </div>
</div>
