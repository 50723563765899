<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="usersettings.setpassword.header.title"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button  localize="title:common.back.msg" (click)="triggerOnClose()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container">

      <mat-form-field>
        <input matInput [placeholder]="textCurrent" type="password" [(ngModel)]="item.oldPassword">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textNew" type="password" [(ngModel)]="item.newPassword">
      </mat-form-field>

      <mat-form-field>
        <input matInput [placeholder]="textRetyped" type="password" [(ngModel)]="item.retypedPassword">
      </mat-form-field>

    </div>

  </div>

</div>
