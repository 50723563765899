import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IGlobal } from '../../interfaces/IGlobal';
import { application } from '../../globals';

@Injectable({
  providedIn: 'root',
})

export class UtilsService
{
  application: IGlobal = application;

  constructor(private router: Router) { }

  /**
   * Gets the appUrl.
   */
  public GetAppUrl()
  {
    let path = window.location.pathname;
    let urlRaw = this.router.url.indexOf('?') >= 0
      ? this.router.url.substr(0, this.router.url.indexOf('?'))
      : this.router.url;
    if (path.endsWith(urlRaw))
    {
      path = path.substr(0, path.length - this.router.url.length);
    }

    let url = window.location.origin + path;
    return url;
  };

  /**
   * Checks if the passed url is a valid url.
   * @param _url
   */
  public IsUrl(_url: string): boolean
  {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(_url);
  };

  /**
   * Creates an empty GUID.
   */
  public EmptyGuid(): string
  {
    return '00000000-0000-0000-0000-000000000000';
  };

  /**
   * Escapes the passed html.
   * <        &lt;
   * >        &gt;
   * "        &quot;
   * &        &amp;
   * @param _html
   */
  public EscapeHtml(_html: string): string
  {
    let tmp = _html
      .replace(/&/gi, '&amp;')
      .replace(/</gi, '&lt;')
      .replace(/>/gi, '&gt;')
      .replace(/"/gi, '&quot;');
    return tmp;
  };

  /**
   * Unescapes the passed html.
   * &amp;    &
   * &lt;     <
   * &gt;     >
   * &quot;   "
   * @param _html
   */
  public UnescapeHtml(_html: string): string
  {
    let tmp = _html
      .replace(/&amp;/gi, '&')
      .replace(/&lt;/gi, '<')
      .replace(/&gt;/gi, '>')
      .replace(/&quot;/gi, '"');
    return tmp;
  };
}
