<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="roleID" localize="pwp.role.single.header.edit"></h1>
  <h1 *ngIf="!roleID||roleID===0" localize="pwp.role.single.header.new"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.cancel.msg" (click)="close()">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <div fxFlex="500px" class="b-form-container wrapper">

      <mat-form-field>
        <input matInput [placeholder]="textName" [(ngModel)]="role.roleName">
      </mat-form-field>

      <p>
        <span localize="$:pwp.role.single.form.permission.title"></span>
      </p>

      <div class="b-form-container">
        <mat-checkbox [(ngModel)]="role.isAuthor"><span localize="$:pwp.role.single.form.isauthor.title"></span></mat-checkbox>
      </div>

      <div class="b-form-container">
        <mat-checkbox [(ngModel)]="role.isEditor"><span localize="$:pwp.role.single.form.iseditor.title"></span></mat-checkbox>
      </div>

      <div class="b-form-container">
        <mat-checkbox [(ngModel)]="role.isContentManager"><span localize="$:pwp.role.single.form.iscontentmanager.title"></span></mat-checkbox>
      </div>

    </div>

  </div>

</div>
