
<div fxLayout="column" fxLayoutAlign="space-around center" class="login">
  <div fxFlex class="container-brand">
    <!--<h1>EVISION5</h1>-->
    <span>
      <img src="./assets/images/evision5-white.svg" class="d-inline-block align-top">
    </span>
  </div>

  <div fxFlex="300px" class="b-form-container" *ngIf="mode==='login'">

    <mat-form-field>
      <input matInput (keyup.enter)="inputpassword.focus()" #inputusername autofocus placeholder="User" [(ngModel)]="login.username">
    </mat-form-field>

    <mat-form-field>
      <input matInput (keyup.enter)="doLogin()" #inputpassword placeholder="Password" [(ngModel)]="login.password" type="password">
    </mat-form-field>

    <mat-toolbar>
      <button mat-button (click)="doLogin()">Login</button>
      <button mat-button (click)="toRequestResetPassword()">Reset password</button>
      <button mat-button (click)="doInitUser()" *ngIf="isInit">Init user</button>
    </mat-toolbar>

  </div>

  <div fxFlex="300px" class="b-form-container" *ngIf="mode==='requestresetpassword'">

    <p>Please enter your username. A link will be sent to your email-address to reset your password.</p>

    <mat-form-field>
      <input matInput (keyup.enter)="doRequestResetPassord()" placeholder="Username" [(ngModel)]="login.username">
    </mat-form-field>

    <mat-toolbar>
      <button mat-button (click)="toLogin()">Back to login</button>
      <button mat-button (click)="doRequestResetPassord()">Reset</button>
    </mat-toolbar>

  </div>

  <div fxFlex="300px" class="b-form-container" *ngIf="mode==='requestresetpassworddone'">

    <p>An email has been sent to you. Please check your email-account.</p>

    <mat-toolbar>
      <button mat-button (click)="toLogin()">Back to login</button>
    </mat-toolbar>

  </div>

  <div fxFlex="300px" class="b-form-container" *ngIf="mode==='resetpassword'">

    <p>Please enter a new password.</p>

    <mat-form-field>
      <input matInput placeholder="Username" [(ngModel)]="resetPasswordModel.userName">
    </mat-form-field>

    <mat-form-field>
      <input matInput type="password" placeholder="New password" [(ngModel)]="resetPasswordModel.newPassword">
    </mat-form-field>

    <mat-form-field>
      <input matInput type="password" (keyup.enter)="doResetPassword()" placeholder="Retype password" [(ngModel)]="resetPasswordModel.retypedPassword">
    </mat-form-field>

    <mat-toolbar>
      <button mat-button (click)="toLogin()">Back to login</button>
      <button mat-button (click)="doResetPassword()">Set new password</button>

    </mat-toolbar>

  </div>

  <div fxFlex></div>

</div>
