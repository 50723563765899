<div class="component component-container"
     fxLayout="column"
     (keydown)="keyHandler.TriggerOnKeyDown($event)"
     tabindex="0">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 *ngIf="mediaJobID" localize="scene.single.header.edit"></h1>
  <h1 *ngIf="!mediaJobID" localize="scene.single.header.new"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:common.save.title" (click)="save()">
      <icon icon="Save"></icon>
    </button>
    <button mat-button localize="title:common.back.msg" (click)="triggerOnClose()" [routerLink]="['/scenes']">
      <icon icon="Times"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!scene"><em localize="common.loadingdata.msg"></em></p>

    <div fxFlex="500px" class="b-form-container wrapper">

      <mat-form-field>
        <input matInput [placeholder]="textName" [(ngModel)]="scene.jobName">
      </mat-form-field>

      <mat-form-field>
        <mat-select [placeholder]="textLooped" [(ngModel)]="scene.looped" (selectionChange)="loopedChangeHandler()">
          <mat-option [value]="false" localize="scene.single.form.looped.startend.title"></mat-option>
          <mat-option [value]="true" localize="scene.single.form.looped.loop.title"></mat-option>
        </mat-select>
      </mat-form-field>

      <!-- STARTTIME -->

      <div class="b-form-container dateTimeArea" fxLayout="row">

        <mat-form-field>
          <input matInput [matDatepicker]="startDatePicker" [placeholder]="textStart" [(ngModel)]="scene.startTime">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="b-form-container nopadding" fxFlex="40%">
          <ngx-timepicker-field [format]="24" [(ngModel)]="startTime"></ngx-timepicker-field>
        </div>

        <div class="b-form-container" fxFlex="15%">
          <button mat-button localize="title:scene.single.form.looped.fromnow.title,$:scene.single.form.looped.fromnow.title" (click)="startFromNow()"></button>
        </div>

      </div>

      <!-- ENDTIME -->

      <div class="b-form-container dateTimeArea" fxLayout="row">

        <mat-form-field *ngIf="scene.looped===false">
          <input matInput [matDatepicker]="endDatePicker" [placeholder]="textEnd" [(ngModel)]="scene.endTime">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="b-form-container nopadding" fxFlex="40%" *ngIf="scene.looped===false">
          <ngx-timepicker-field [format]="24" [(ngModel)]="endTime"></ngx-timepicker-field>
        </div>

        <div class="b-form-container" fxFlex="15%" *ngIf="scene.looped===false">
          <button mat-button localize="title:scene.single.form.looped.tonow.title,$:scene.single.form.looped.tonow.title" (click)="endToNow()"></button>
        </div>

      </div>

      <!-- ... -->

      <mat-form-field *ngIf="scene.looped===true">
        <input matInput [disabled]="scene.looped===false" [placeholder]="textLoopCount" [(ngModel)]="scene.loopCount">
      </mat-form-field>

      <p>
        <span localize="$:scene.single.form.weekdays.title"></span>
      </p>

      <ul *ngIf="days">
        <li *ngFor="let d of days" class="weekdays"><mat-checkbox [(ngModel)]="d.selected">{{ d.text }}</mat-checkbox></li>
      </ul>

      <div class="b-form-container">
        <mat-checkbox [(ngModel)]="scene.archive"><span localize="scene.single.form.archive.title"></span></mat-checkbox>
      </div>

    </div>

  </div>

</div>
