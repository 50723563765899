import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Directive, ElementRef, HostListener } from '@angular/core';

/*
 * Stops propagation on click on element.
 **/
@Directive({
  selector: '[stop-propagation]'
})
export class StopPropagationDirective {
  @HostListener("mousedown") //, ["$event"])
  public onMousedown(event: any): void {
    //event.stopPropagation();
    //event.stopImmediatePropagation();
    //event.preventDefault();
  };
}

@NgModule({
  declarations: [
    StopPropagationDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    StopPropagationDirective
  ]
})
export class StopPropagationModule { }
