<div class="component component-container" fxLayout="column">

  <busyIndicator [canBeBusyWorker]="busyIndicator"></busyIndicator>

  <h1 localize="mediadevicestartupexceptions.summary.header"></h1>

  <mat-toolbar>
    <span class="toolbar-spacer"></span>
    <button mat-button localize="title:mediadevicestartupexceptions.summary.toolbar.new.title" (click)="editException(null)">
      <icon icon="Plus"></icon>
    </button>
    <button mat-button localize="title:common.back.msg" routerLink="/mediadevices">
      <icon icon="ArrowLeft"></icon>
    </button>
  </mat-toolbar>

  <div class="component-content-container">

    <p *ngIf="!mediaExceptions"><em localize="common.loadingdata.msg"></em></p>

    <table mat-table [dataSource]="mediaExceptions">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef localize="mediadevicestartupexceptions.summary.grid.name.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.caption }}</td>
      </ng-container>

      <ng-container matColumnDef="from">
        <th mat-header-cell *matHeaderCellDef class="column-duration" localize="mediadevicestartupexceptions.summary.grid.from.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.startDate | date:'shortDate':'UTC' }}</td>
      </ng-container>

      <ng-container matColumnDef="to">
        <th mat-header-cell *matHeaderCellDef class="column-elementcount cell-right" localize="mediadevicestartupexceptions.summary.grid.to.title"></th>
        <td mat-cell *matCellDef="let element">{{ element.endDate | date:'shortDate':'UTC' }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="column-buttons"></th>
        <td mat-cell *matCellDef="let element" class="cell-buttons two-buttons">
          <span class="button-wrapper">
            <span class="centered">
              <button mat-button (click)="deleteException(element.id)" localize="title:mediadevicestartupexceptions.summary.grid.delete.btn.title">
                <icon icon="Trash"></icon>
              </button>
              <button mat-button (click)="editException(element.id)" localize="title:mediadevicestartupexceptions.summary.grid.edit.btn.title">
                <icon icon="Pen"></icon>
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected':id===row.id}"></tr>

    </table>
  </div>

</div>
