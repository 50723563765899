import { Component, Inject, ViewChild, ElementRef, EventEmitter, Output, Input, LOCALE_ID } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { IState } from '../../../../app-pwp/src/app/core/interfaces/IState';
import { ILogin } from '../core/interfaces/ILogin';
import { IResetPassword } from '../core/interfaces/IResetPassword';
import ResetPassword from '../core/models/ResetPassword';
import { application } from '../../../../app-pwp/src/app/core/globals';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {
  @Output() stateChanged = new EventEmitter<IState>();
  @Input() isLocal: boolean = false;
  @Input() isInit: boolean = false;
  @ViewChild("inputusername") inputusername: ElementRef;

  public login: ILogin = {
    username: '',
    password: ''
  };
  public stateLogin: IState;

  private resetPasswordModel: IResetPassword = new ResetPassword();

  //{
  //  newPassword: '',
  //  retypedPassword: '',
  //  code: '',
  //  userName: ''
  //};

  mode: string = 'login';

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  code: string = null;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar) {
    this.baseUrl = baseUrl;
    this.http = http;
  };

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
      if (this.code !== undefined && this.code !== null) {
        this.toResetPassword();
      }
    });
  };

  ngAfterViewInit() {
    this.inputusername.nativeElement.focus();
  }

  toRequestResetPassword() {
    this.mode = 'requestresetpassword';
  };

  doRequestResetPassord() {
    this.http
      .post<IState>(this.baseUrl + 'api/pwp/security/requestresetpassword', this.login)
      .subscribe(
        result => {
          if (result.value === false) {
            this.snackBarRef = this.snackBar.open('Request for resetting password failed. Please check username.', 'Close');
          }
          else {
            this.login.username = '';
            this.login.password = '';
            this.toRequestResetPasswordDone();
          }
        },
        error => console.error(error));
  };

  public toRequestResetPasswordDone() {
    this.mode = 'requestresetpassworddone';
  };

  public toResetPassword() {
    this.mode = 'resetpassword';
  };

  public doResetPassword() {
    if (this.resetPasswordModel.newPassword !== this.resetPasswordModel.retypedPassword) {
      this.snackBarRef = this.snackBar.open('Reset password failed. Password and retyped password are different.', 'Close');
      return;
    }

    if (this.resetPasswordModel.newPassword === null || this.resetPasswordModel.newPassword === '') {
      this.snackBarRef = this.snackBar.open('The new password is empty.', 'Close');
      return;
    }

    if (this.resetPasswordModel.userName === null || this.resetPasswordModel.userName === '') {
      this.snackBarRef = this.snackBar.open('The username is empty.', 'Close');
      return;
    }

    this.resetPasswordModel.code = this.code;

    this.http
      .post<IState>(this.baseUrl + 'api/pwp/security/resetpassword', this.resetPasswordModel)
      .subscribe(
        result => {
          if (result.value === false) {
            this.snackBarRef = this.snackBar.open('Reset password failed. Please contact your administrator.', 'Close');
          }
          else {
            this.snackBarRef = this.snackBar.open('New password set. Please login now!', 'Close');

            this.resetPasswordModel = new ResetPassword();
            //{
            //  newPassword: '',
            //  retypedPassword: '',
            //  code: '',
            //  userName: ''
            //};

            this.login.username = '';
            this.login.password = '';
            this.toLogin();
          }
        },
        error => console.error(error));
  };

  public toLogin() {
    //this.mode = 'login';
    window.location.href = window.location.href.split('?')[0];
  };

  public doLogin() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
    }

    this.http
      .post<IState>(this.baseUrl + 'api/pwp/security/login', this.login)
      .subscribe(
        result => {
          if (result.value === false) {
            this.snackBarRef = this.snackBar.open('Login failed. Please check username and password.', 'Close');
            application.technicalRoleNames = null;
          }
          else {
            application.technicalRoleNames = result.technicalRoleNames;
            application.user = this.login.username;
            application.isAdmin = result.isAdmin;

            this.stateChanged.emit(result);
          }

        },
        error => console.error(error));

    return false;
  };

  public doInitUser() {
    this.http
      .post<IState>(this.baseUrl + 'api/pwp/security/inituser', null)
      .subscribe(
        result => {
          if (result.value === false) {
            this.snackBarRef = this.snackBar.open('Init user done.', 'Close');
          }

          this.stateChanged.emit(result);
        },
        error => console.error(error));
  };
}
