import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SceneSingleComponent } from '../scene-single/scene-single.component';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../../app-pwp/src/app/dialog/dialog.component';
import { IMediaDevice } from '../../core/interfaces/IMediaDevice';
import { IMediaJobWithGrouping } from '../../core/interfaces/IMediaJobWithGrouping';
import { application } from '../../../../../app-pwp/src/app/core/globals';
import { SecurityService } from '../../../../../app-pwp/src/app/core/services/common/security.service';
import { DateTimeService } from '../../../../../app-pwp/src/app/core/services/common/datetime.service';
import { UtilsService } from '../../../../../app-pwp/src/app/core/services/common/utils.service';
import { MediaJobsService } from '../../core/services/data/mediajobs.service';
import { IReturnState } from '../../../../../app-pwp/src/app/core/interfaces/IReturnState';

@Component({
  selector: 'app-scenes-summary',
  templateUrl: './scenes-summary.component.html',
  styleUrls: ['./scenes-summary.component.css']
})

export class ScenesSummaryComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogEditMediaJob: MatDialogRef<SceneSingleComponent>;

  private emptyGuid: string = null;

  private displayedColumnsAll: string[] = ['name', 'status', 'startStopAction', 'start', 'edit'];
  public displayedColumns: string[] = this.displayedColumnsAll;

  public filterArchived: boolean = false;

  private mediaJobID: string = null;
  private devicesDic: object = {};
  private devices: IMediaDevice[] = null;
  private groups: IMediaDevice[] = null;

  // Array with jobs received from server.
  public mediaJobs: IMediaJobWithGrouping[];

  public textDeviceFilter: string = application.getRawText('mediajobs.summary.top.devices.title');
  public textDeviceDevice: string = application.getRawText('mediajobs.summary.top.devices.device.title');
  public textDeviceGroup: string = application.getRawText('mediajobs.summary.top.devices.group.title');

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private utilsService: UtilsService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private mediaJobsService: MediaJobsService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.emptyGuid = this.utilsService.EmptyGuid();
  };

  private ngOnInit() {
    this.loadScenes();
  };

  public loadScenes() {
    this.mediaJobsService.GetScenes(this.filterArchived).subscribe((_data: IMediaJobWithGrouping[]) => {
      this.mediaJobs = _data;
    });
  };

  public edit(_mediaJobID: string) {
    this.mediaJobID = _mediaJobID;
    this.dialogEditMediaJob = this.dialog.open(SceneSingleComponent, {
      autoFocus: true,
      height: '600px',
      width: '550px'
    });
    this.dialogEditMediaJob.componentInstance.init(_mediaJobID);
    this.dialogEditMediaJob.componentInstance.onClose.subscribe(() => {
      this.dialogEditMediaJob.close();
      this.loadScenes();
    });
  };

  public start(_mediaJobID: string) {
    this.mediaJobsService.Start(_mediaJobID).subscribe((result: IReturnState) => {
      this.loadScenes();
      if (result.success) {
        this.snackBarRef = this.snackBar.open(application.getRawText('scenes.summary.action.started.msg'), application.getRawText('common.close.msg'));
      }
      else {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  public stop(_mediaJobID: string) {
    this.mediaJobsService.Stop(_mediaJobID).subscribe((result: IReturnState) => {
      this.loadScenes();
      if (result.success) {
        this.snackBarRef = this.snackBar.open(application.getRawText('scenes.summary.action.stopped.msg'), application.getRawText('common.close.msg'));
      }
      else {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  public delete(_mediaJobID: string) {
    this.mediaJobID = _mediaJobID;
    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('scenes.summary.delete.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      this.mediaJobsService.DeleteScene(_mediaJobID).subscribe(_result => {
        var result = _result as IReturnState;
        if (result.success) {
          this.loadScenes();
          this.snackBarRef = this.snackBar.open(application.getRawText('scenes.summary.deleted.msg'), application.getRawText('common.close.msg'));
        }
        else {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
        }
      });

    });
  };

}
